import { Component, HostListener, OnInit } from '@angular/core';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css']
})
export class RestaurantComponent implements OnInit {
  isFav : false;
  constructor(private favoriteService: FavoriteService) {
  }
  @HostListener('window:scroll', ['$event']) onClick(event) {
    var fixedColumn = document.querySelector<HTMLElement>('.fixed');
    let top = fixedColumn.getBoundingClientRect();
    var footer = document.querySelector<HTMLElement>('.footer-top');
    // if (window.scrollY == 0)
    //   fixedColumn.style.top = top.y.toString();
    // else
    //   fixedColumn.style.top = '400px';
    var fixedColumnHeight = fixedColumn.offsetHeight;
    var footerOffset = footer.offsetTop;
    var scrollTop = window.scrollY || window.pageYOffset;

    if (scrollTop + window.innerHeight > footerOffset) {
      fixedColumn.style.transform = 'translateY(-' + (scrollTop + window.innerHeight - footerOffset) + 'px)';
    } else {
      fixedColumn.style.transform = 'none';
    }
  }
  ngOnInit(): void {
  }

  addToFavorite() {
    this.favoriteService.addProductToFavorites(1,this.isFav).subscribe(res=>{

    })
  }

}
