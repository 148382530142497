import { Component, OnInit } from '@angular/core';
import { InfoService } from 'src/app/services/info/info.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
  comment = '';
  getAbout() {
    this.infoService.getAbout().subscribe((res) => {
      this.comment = res.data;
    });
  }
  constructor(private infoService: InfoService) {}

  ngOnInit(): void {
    this.getAbout()
  }
}
