<app-navbar></app-navbar>

<!-- <section class="about-us-slider swiper-container p-relative">
        <div class="swiper-wrapper">
            <div class="swiper-slide slide-item">
                <img src="assets/img/about/blog/1920x700/banner-1.jpg" class="img-fluid full-width" alt="Banner">
                <div class="transform-center">
                    <div class="container">
                        <div class="row justify-content-start">
                            <div class="col-lg-7 align-self-center">
                                <div class="right-side-content">
                                    <h1 class="text-custom-white fw-600">Increase takeout sales by 50%</h1>
                                    <h3 class="text-custom-white fw-400">with the largest delivery platform in the U.S. and Canada</h3>
                                    <a routerLink="/restaurant" class="btn-second btn-submit">Learn More.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay overlay-bg"></div>
            </div>
            <div class="swiper-slide slide-item">
                <img src="assets/img/about/blog/1920x700/banner-2.jpg" class="img-fluid full-width" alt="Banner">
                <div class="transform-center">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 align-self-center">
                                <div class="right-side-content text-center">
                                    <h1 class="text-custom-white fw-600">Increase takeout sales by 50%</h1>
                                    <h3 class="text-custom-white fw-400">with the largest delivery platform in the U.S. and Canada</h3>
                                    <a routerLink="/restaurant" class="btn-second btn-submit">Learn More.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay overlay-bg"></div>
            </div>
            <div class="swiper-slide slide-item">
                <img src="assets/img/about/blog/1920x700/banner-3.jpg" class="img-fluid full-width" alt="Banner">
                <div class="transform-center">
                    <div class="container">
                        <div class="row justify-content-end">
                            <div class="col-lg-7 align-self-center">
                                <div class="right-side-content text-right">
                                    <h1 class="text-custom-white fw-600">Increase takeout sales by 50%</h1>
                                    <h3 class="text-custom-white fw-400">with the largest delivery platform in the U.S. and Canada</h3>
                                    <a routerLink="/restaurant" class="btn-second btn-submit">Learn More.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay overlay-bg"></div>
            </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </section>
    
    <section class="aboutus section-padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="history-title mb-md-40">
                        <h2 class="text-light-black">A History Has Written For Quickmunch Explore <span class="text-light-green">more Our Story</span></h2>
                        <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p> <a class="btn-second btn-submit" routerLink="/about">
              Our History
            </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-6 col-md-6">
                            <div class="histry-img mb-xs-20">
                                <img src="assets/img/about/blog/255x200/about-section-3.jpg" class="img-fluid full-width" alt="Histry">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6">
                            <div class="histry-img mb-xl-20">
                                <img src="assets/img/about/blog/255x200/about-section-1.jpg" class="img-fluid full-width" alt="Histry">
                            </div>
                            <div class="histry-img">
                                <img src="assets/img/about/blog/255x200/about-section-2.jpg" class="img-fluid full-width" alt="Histry">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="section-padding how-it-works bg-light-theme">
        <div class="container">
            <div class="section-header-style-2">
                <h6 class="text-light-green sub-title">Our Process</h6>
                <h3 class="text-light-black header-title">How Does It Work</h3>
            </div>
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="how-it-works-box arrow-1">
                        <div class="how-it-works-box-inner"> <span class="icon-box">
                <img src="assets/img/001-search.png" alt="icon">
                <span class="number-box">01</span>
                            </span>
                            <h6>Search</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="how-it-works-box arrow-2">
                        <div class="how-it-works-box-inner"> <span class="icon-box">
                <img src="assets/img/004-shopping-bag.png" alt="icon">
                <span class="number-box">02</span>
                            </span>
                            <h6>Select</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="how-it-works-box arrow-1">
                        <div class="how-it-works-box-inner"> <span class="icon-box">
                <img src="assets/img/002-stopwatch.png" alt="icon">
                <span class="number-box">03</span>
                            </span>
                            <h6>Order</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="how-it-works-box">
                        <div class="how-it-works-box-inner"> <span class="icon-box">
                <img src="assets/img/003-placeholder.png" alt="icon">
                <span class="number-box">04</span>
                            </span>
                            <h6>Enjoy</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="section-padding our-blog restaurent-ad">
        <div class="container">
            <div class="section-header-style-2">
                <h6 class="text-light-green sub-title">Our Blog</h6>
                <h3 class="text-light-black header-title">Latest News</h3>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-box mb-xl-20">
                        <article class="post">
                            <div class="blog-img p-relative">
                                <a href="#">
                                    <img src="assets/img/about/blog/740x442/blog-1.jpg" alt="blog-img">
                                </a>
                                <div class="overlay padding-20">
                                    <div class="blog-meta">
                                        <h4 class="text-custom-white mb-2 fw-600">New locations where you can now get your Quickmunch® delivered</h4>
                                        <a routerLink="/blog-details" class="text-custom-white fw-700 fs-18">Read More</a>
                                    </div>
                                </div>
                                <div class="overlay overlay-bg"></div>
                            </div>
                        </article>
                        <div class="side-blog">
                            <div class="blog-list padding-tb-10">
                                <article class="side-post padding-20 u-line">
                                    <div class="content-wrap">
                                        <div class="entry-meta-content">
                                            <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                            </div>
                                        </div>
                                        <h6 class="entry-title no-margin">
                                            <a routerLink="/blog-details" class="text-light-black">How We designed It: pizza delivery from the ground up only for you.</a>
                                        </h6>
                                    </div>
                                    <div class="thumb-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/100x100/popularpost-1.jpg" alt="thumb-img">
                                        </a>
                                    </div>
                                </article>
                                <article class="side-post padding-20 u-line">
                                    <div class="content-wrap">
                                        <div class="entry-meta-content">
                                            <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                            </div>
                                        </div>
                                        <h6 class="entry-title no-margin">
                                            <a routerLink="/blog-details" class="text-light-black">How We Made It: Grimaldi's traditional New York-style pizza italian style</a>
                                        </h6>
                                    </div>
                                    <div class="thumb-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/100x100/popularpost-2.jpg" alt="thumb-img">
                                        </a>
                                    </div>
                                </article>
                                <article class="side-post padding-20">
                                    <div class="content-wrap">
                                        <div class="entry-meta-content">
                                            <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                            </div>
                                        </div>
                                        <h6 class="entry-title no-margin">
                                            <a routerLink="/blog-details" class="text-light-black">How We Made It: Grimaldi's traditional New York-style pizza</a>
                                        </h6>
                                    </div>
                                    <div class="thumb-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/100x100/popularpost-3.jpg" alt="thumb-img">
                                        </a>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div class="ad-img p-relative">
                        <img src="assets/img/about/blog/1110x350/hbanner-1.jpg" class="img-fluid full-width" alt="#">
                        <div class="overlay">
                            <div class="content-box transform-center">
                                <p class="text-custom-white">Quickmunch</p>
                                <h3 class="text-custom-white mb-1">More than 3000 restaurants</h3>
                                <h5 class="text-custom-white">Book a table easly at the best price</h5>
                                <a routerLink="/restaurant" class="btn-first white-btn">View All</a>
                            </div>
                        </div>
                        <div class="overlay overlay-bg"></div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
<section class="section-padding contact-top bg-light-theme">
    <div class="container">
        <div [innerHTML]="comment"></div>
    </div>
</section>
<app-footer2></app-footer2>