import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud.service';
import { InterceptorSkipHeader } from 'src/app/shared/constants/global-variable.model';
import { ResponseResult } from 'src/app/models-classes/common.model';

@Injectable({
  providedIn: 'root'
})
export class LookupsService extends CrudService<any, any, number> {
  constructor(protected _http: HttpClient) {
    super(_http, `${environment.api.baseUrl}/CMS_Lookups/`);
  }
  headers = new HttpHeaders().set(InterceptorSkipHeader, '').set('langid', localStorage.getItem('current_lang') == 'en' ? '2' : '1');

  getCountries(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(`${environment.api.baseUrl}/Admin/CMS_Lookups/Countries`, { headers: this.headers });
  }
  getCitiesByCountryId(id: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(`${environment.api.baseUrl}/Admin/CMS_Lookups/Cities?countryId=${id}`, { headers: this.headers });
  }
  getShopTypesByCountryId(id: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(`${environment.api.baseUrl}/Admin/CMS_Lookups/ShopTypes?countryId=${id}`, { headers: this.headers });
  }
  getCompanyCategories(companyId: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(`${environment.api.baseUrl}/Admin/CMS_Lookups/CompanyCategories?companyId=${companyId}`, { headers: this.headers });
  }
  getDeliveryCarSizes(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(`${environment.api.baseUrl}/Admin/CMS_Lookups/DeliveryCarSizes`, { headers: this.headers });
  }
  getDeliveryCars(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(`${environment.api.baseUrl}/Admin/CMS_Lookups/DeliveryCars`, { headers: this.headers });
  }
}
