import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import Swiper, { Navigation } from 'swiper';
declare let $:any;
@Component({
  selector: 'app-company-categories',
  templateUrl: './company-categories.component.html',
  styleUrls: ['./company-categories.component.css'],
})
export class CompanyCategoriesComponent implements OnInit {
  @Input() company_categories: any = []; //input from parent
  @Output() GetId = new EventEmitter();
  globalCategories: any[] = [];
  // x = [
  //   {
  //     "id": 1,
  //     "name": "أحمد"
  //   },
  //   {
  //     "id": 2,
  //     "name": "سارة"
  //   },
  //   {
  //     "id": 3,
  //     "name": "محمد"
  //   },
  //   {
  //     "id": 4,
  //     "name": "أحمد"
  //   },
  //   {
  //     "id": 5,
  //     "name": "سارة"
  //   },
  //   {
  //     "id": 6,
  //     "name": "محمد"
  //   },
  //   {
  //     "id": 7,
  //     "name": "أحمد"
  //   },
  //   {
  //     "id": 8,
  //     "name": "سارة"
  //   },
  //   {
  //     "id": 9,
  //     "name": "محمد"
  //   },
  //   {
  //     "id": 10,
  //     "name": "أحمد"
  //   },
  //   {
  //     "id": 11,
  //     "name": "سارة"
  //   },
  //   {
  //     "id": 12,
  //     "name": "محمد"
  //   }
  // ]

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }
  ngAfterViewInit() {
    // window.addEventListener('mousewheel', this.changeActiveWordColor);


    // const scroller = document.getElementById('nav-com');
    // scroller.addEventListener('scroll', this.changeActiveWordColor);
    // const topnav = document.querySelector<HTMLElement>('#top-nav');
    // scroller.addEventListener('scroll', (event) => {
    //   if (scroller.scrollTop >= 454) {
    //     topnav.style.cssText =
    //       'position: fixed;top: 50px; left: 0; right: 0; z-index: 3000; background-color: #fff; padding: 10px 20px; ';
    //   } else {
    //     topnav.style.cssText = '';
    //   }
    // });
  }
  changeActiveWordColor() {
    const slider = document.getElementById('nav-com');
    const words = Array.from(slider.getElementsByClassName('categories'));

    const scrollPosition = window.scrollY;

    let activeWord = null;
    for (const word of words) {
      const rect = word.getBoundingClientRect();
      if (rect.top <= scrollPosition + 100 && rect.bottom >= scrollPosition + 100) {
        activeWord = word;
        break;
      }
    }
    // تحديد الكلمة النشطة وتغيير لونها وإزالة اللون من الكلمات الأخرى
    for (const word of words) {
      if (word === activeWord) {
        word.classList.add('category-style-company-page');
        word.firstElementChild.classList.add('cat-color')
        word.firstElementChild.classList.remove('cat-color-black')
      }
       else {
        word.classList.remove('category-style-company-page');
      }
    }
  }
  // @HostListener('window:mousewheel', ['$event']) public async onClick(event) {
  //   console.log(document.body.scrollHeight)
  // }
  // @HostListener('document:scroll', ['$event'])
  // handleScroll(event) {
  //   console.log('ss');
  //   console.log(this.elementPosition);
  //   const windowScroll = window.pageYOffset;
  //   console.log(window.innerHeight);
  //     if(windowScroll >= this.elementPosition){
  //       this.sticky = true;
  //     } else {
  //       this.sticky = false;
  //     }
  // }
  constructor() {}
  ids = new Array();
  startProductBarPos = -1;
  ngOnInit(): void {
    
    // Swiper.use([Navigation]);
    // const mySwiper = new Swiper('.swiper-container3', {
    //   observer: true,
    //   observeParents: true,
    //   speed: 1000,
    //   direction: 'horizontal',
    //   slidesPerView: 6,
    //   spaceBetween: 6,
    //   navigation: {
    //     nextEl:
    //       localStorage.getItem('current_lang') == 'en'
    //         ? '.swiper-button-next'
    //         : '.swiper-button-prev',
    //     prevEl:
    //       localStorage.getItem('current_lang') == 'en'
    //         ? '.swiper-button-prev'
    //         : '.swiper-button-next',
    //   },
    //   autoplay: {
    //     delay: 0,
    //     disableOnInteraction: true,
    //     reverseDirection:
    //       localStorage.getItem('current_lang') == 'ar' ? true : false,
    //   },
    //   breakpoints: {
    //     // when window width is >= 320px
    //     320: {
    //       slidesPerView: 1,
    //       spaceBetween: 10,
    //     },
    //     // when window width is >= 480px
    //     480: {
    //       slidesPerView: 2,
    //       spaceBetween: 20,
    //     },
    //     // when window width is >= 576px
    //     576: {
    //       slidesPerView: 3,
    //       spaceBetween: 30,
    //     },
    //     768: {
    //       slidesPerView: 4,
    //       spaceBetween: 40,
    //     },
    //     1200: {
    //       slidesPerView: 5,
    //       spaceBetween: 15,
    //     },
    //     1400: {
    //       slidesPerView: 6,
    //       spaceBetween: 10,
    //     },
    //     1800: {
    //       slidesPerView: 7,
    //       spaceBetween: 6,
    //     },
    //   },
    // });
   
  }
  // findPosY(obj) {
  //   var curtop = 0;
  //   if (typeof obj.offsetParent != 'undefined' && obj.offsetParent) {
  //     while (obj.offsetParent) {
  //       curtop += obj.offsetTop;
  //       obj = obj.offsetParent;
  //     }
  //     curtop += obj.offsetTop;
  //   } else if (obj.y) curtop += obj.y;
  //   return curtop;
  // }
  toggleStyle(index: number, id) {
    this.ids = [];
    let elements = document.getElementsByClassName(
      'category-style-company-page'
    );
    const elementsArray = Array.from(elements);

    // Remove class from each element
    elementsArray.forEach((element) => {
      const containsSpan = element.querySelector('span');
      containsSpan.classList.add('cat-color-black');
      element.classList.remove('category-style-company-page');
    });

    let element = document.getElementById('cat' + index);
    let span = document.querySelector(`#cat${index} span`);
    if (!element.classList.contains('category-style-company-page')) {
      element.classList.add('category-style-company-page');
      span.classList.add('cat-color');
      span.classList.remove('cat-color-black');
      this.ids.push(id);
    } else {
      element.classList.remove('category-style-company-page');
      span.classList.add('cat-color-black');
      span.classList.remove('cat-color');
      this.ids = this.ids.filter((element) => {
        return element !== id;
      });
    }
    this.GetId.emit(this.ids);
  }

  // getGlobalCategories() {
  //   this.catalogService.getAllGlobalCategories(this.id).subscribe(res => {
  //     this.globalCategories = res.data;
  //   })
  // }
}
