<app-navbar></app-navbar>
<section class="pt-2 contact-top bg-light-theme">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-box mb-md-40">
                    <i class="fas fa-map-marker-alt"></i>
                    <h6 *ngIf="current_lang == 'en'" dir="ltr" class="text-theme fw-600">{{'MyAddress' | translate}}</h6>
                    <h6 *ngIf="current_lang == 'ar'" dir="ltr" class="text-theme fw-600">{{'MyAddress' | translate}}</h6>
                    <h6 *ngIf="current_lang == 'ar'" dir="ltr" class="text-theme fw-600">{{'MyAddress1' | translate}}</h6>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-box mb-md-40">
                    <i class="fas fa-phone-alt"></i>
                    <h6 dir="ltr" class="text-theme fw-600"><a href="#" class="text-theme">(+965) 189 09 99</a></h6>
                    <!-- <h6 class="text-theme fw-600"><a href="#" class="text-theme">(+347) 123 4567 890</a><br> Mon-Sat
                        9:00am-5:00pm</h6> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-box">
                    <i class="fas fa-envelope"></i>
                    <h6 dir="ltr" class="text-theme fw-600"><a href="#" class="text-theme">Info@deliveryrahal.com</a><br> 24 X 7
                        {{'online support' | translate}}</h6>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-padding contact-form">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-header-left title">
                    <h3 class="text-light-black header-title">{{'Get In Touch' | translate}}</h3>
                </div>
                <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" [(ngModel)]="model.name"
                                    class="form-control form-control-submit" placeholder="{{'Name' | translate}}" required>
                            </div>
                            <div class="form-group">
                                <input type="email" name="email" [(ngModel)]="model.email"
                                    class="form-control form-control-submit" placeholder="{{'Email' | translate}}" required>
                            </div>
                            <div class="form-group">
                                <input type="text" name="phone" [(ngModel)]="model.phone"
                                    class="form-control form-control-submit" placeholder="{{'Phone' | translate}}" required>
                            </div>
                            <div class="form-group">
                                <!-- <input type="text" name="subject" [(ngModel)]="model.subject"
                                    class="form-control form-control-submit" placeholder="Subject" required> -->
                                    <re-captcha name="recaptcha" [(ngModel)]="model.recaptcha" (resolved)="resolved($event)"
                                    siteKey="6Lf9qFcpAAAAAF7L6fJYjs8E-nth5RO2S8Iigdw9
                                    "></re-captcha>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea name="message" [(ngModel)]="model.message"
                                    class="form-control form-control-submit" placeholder="{{'Message' | translate}}" required></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            <!-- <re-captcha (resolved)="resolved($event)" name="recaptcha" [(ngModel)]="model.recaptcha"
                                siteKey="6Lf9qFcpAAAAAF7L6fJYjs8E-nth5RO2S8Iigdw9
                                " size="invisible"></re-captcha> -->
                            <button type="submit" class="btn-second btn-submit"
                                [disabled]="!contactForm.form.valid">{{'Send' | translate}}</button>
                            <!-- Form Messages -->
                            <!-- <div class="service-error mt-4 w-100" *ngIf="error">
                                <ngb-alert type="danger" class="mb-0 w-100" [dismissible]="false">
                                    <strong>Oops!</strong> Something bad happened. Please try again later.
                                </ngb-alert>
                            </div> -->
                            <!-- <div [hidden]="!submitted" class="contact-message w-100">
                                <div class="contact-success w-100 mt-4">
                                    <ngb-alert type="success" class="mb-0 w-100" [dismissible]="false">
                                        <strong>Success!</strong> Contact form has been successfully submitted.
                                    </ngb-alert>
                                </div>
                            </div> -->
                            <!-- Form Messages -->
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</section>
<!-- <div class="contact-map">
    <iframe
        src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
</div> -->
<app-footer2></app-footer2>