import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicCurrency',
})
export class DynamicCurrencyPipePipe implements PipeTransform {
  transform(value: number, currencySymbol: string, precision: number): string {
    if (value == null) {
      return '';
    }
    // return value.toLocaleString('ar', {
    //   style: 'currency',
    //   currency: currencySymbol,
    //   minimumFractionDigits: precision,
    //   maximumFractionDigits: precision,
    // });
    try {
      return value.toLocaleString(localStorage.getItem('current_lang'), {
        style: 'currency',
        currency: currencySymbol,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      });
    } catch (error) {
      return value.toLocaleString(localStorage.getItem('current_lang'), {
        style: 'currency',
        currency: localStorage.getItem('currencySymbolEn') || 'KWD',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      });
    }
  }
}
