<section class="section-padding errorpage">
    <div class="container">
        <div class="row align-items-center">
            <!-- <div class="col-lg-7 text-center">
                <img src="assets/img/banner/banner-2.jpg" alt="img" />
            </div> -->
            <div class="col-lg-5 text-center element-center">
                <div class="error-texts relative">
                    <!-- <h1>404</h1> -->
                    <h3>{{'OrderCanceled' | translate}}</h3>
                    <!-- <p>This file May Have Been Moved or Deleted. Be Sure to Check Your Spelling.</p> -->
                    <a routerLink="/" class="btn-second btn-submit">{{'Back to Home' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>