import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { Homepage1Component } from './pages/homepage1/homepage1.component';
import { Homepage2Component } from './pages/homepage2/homepage2.component';
import { Homepage3Component } from './pages/homepage3/homepage3.component';
import { Homepage4Component } from './pages/homepage4/homepage4.component';
import { AboutComponent } from './pages/about/about.component';
import { AddrestaurantComponent } from './pages/addrestaurant/addrestaurant.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogdetailsComponent } from './pages/blogdetails/blogdetails.component';
import { Blogstyle2Component } from './pages/blogstyle2/blogstyle2.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ExdealsComponent } from './pages/exdeals/exdeals.component';
import { GeolocatorComponent } from './pages/geolocator/geolocator.component';
import { ListviewComponent } from './pages/listview/listview.component';
import { LoginComponent } from './pages/login/login.component';
import { OrderdetailsComponent } from './pages/orderdetails/orderdetails.component';
import { RegisterComponent } from './pages/register/register.component';
import { RestaurantComponent } from './pages/restaurant/restaurant.component';
import { Restaurantstyle1Component } from './pages/restaurantstyle1/restaurantstyle1.component';
import { Restaurantstyle2Component } from './pages/restaurantstyle2/restaurantstyle2.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { Footer1Component } from './layouts/footer1/footer1.component';
import { Footer2Component } from './layouts/footer2/footer2.component';
import { AdvertisementbannerComponent } from './layouts/advertisementbanner/advertisementbanner.component';
import { BlogleftsidebarComponent } from './layouts/blogleftsidebar/blogleftsidebar.component';
import { BlogrightsidebarComponent } from './layouts/blogrightsidebar/blogrightsidebar.component';
import { RestaurantleftsidebarComponent } from './layouts/restaurantleftsidebar/restaurantleftsidebar.component';
import { RestaurantrightsidebarComponent } from './layouts/restaurantrightsidebar/restaurantrightsidebar.component';
import { Advertisementbanner1Component } from './layouts/advertisementbanner1/advertisementbanner1.component';
import { Advertisementbanner2Component } from './layouts/advertisementbanner2/advertisementbanner2.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactComponent } from './pages/contact/contact.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ProfileInfoComponent } from './pages/profile-info/profile-info.component';
import { LocalStorageGuard } from './shared/guards/local-storage/local-storage.guard';
import { ToastrModule } from 'ngx-toastr';
import { CategoriesComponent } from './layouts/categories/categories.component';
import { ResturantsComponent } from './pages/resturants/resturants.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { CompanyComponent } from './pages/company/company.component';
import { BasketComponent } from './layouts/basket/basket.component';
import { SpacePipe } from './shared/pipes/space.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CompanyCategoriesComponent } from './layouts/company-categories/company-categories.component';
// import {
//   GoogleLoginProvider,
//   SocialAuthServiceConfig,
//   SocialLoginModule,
// } from '@abacritt/angularx-social-login';
import { RatingModule } from 'primeng/rating';

import { MyOrdersComponent } from './pages/my-orders/my-orders.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { GridViewModule } from './components/my-custom-components/grid-view/grid-view.module';
import { ModalContentComponent } from './pages/modal-content/modal-content.component';
import { AddressGuard } from './shared/guards/address.guard';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { PaymentComponent } from './pages/payment/payment.component';
import { CardPaymentComponent } from './pages/card-payment/card-payment.component';
import { CancelUrlComponent } from './pages/cancel-url/cancel-url.component';
import { NoDataComponent } from './pages/no-data/no-data.component';
import { ResturantsDataComponent } from './pages/resturants-data/resturants-data.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';
import { DynamicCurrencyPipePipe } from './shared/pipes/dynamic-currency-pipe.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HtmlToPlainTextPipe } from './shared/pipes/html-to-plain-text.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { ConfirmDialogComponent } from './pages/confirm-dialog/confirm-dialog.component';
import { BasketModalComponent } from './pages/basket-modal/basket-modal.component';
// import { SkeletonModule } from '@syncfusion/ej2-angular-notifications';
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Homepage1Component,
    Homepage2Component,
    Homepage3Component,
    Homepage4Component,
    AboutComponent,
    AddrestaurantComponent,
    BlogComponent,
    BlogdetailsComponent,
    Blogstyle2Component,
    CheckoutComponent,
    ExdealsComponent,
    GeolocatorComponent,
    ListviewComponent,
    LoginComponent,
    OrderdetailsComponent,
    RegisterComponent,
    RestaurantComponent,
    Restaurantstyle1Component,
    Restaurantstyle2Component,
    NavbarComponent,
    Footer1Component,
    Footer2Component,
    AdvertisementbannerComponent,
    BlogleftsidebarComponent,
    BlogrightsidebarComponent,
    RestaurantleftsidebarComponent,
    RestaurantrightsidebarComponent,
    Advertisementbanner1Component,
    Advertisementbanner2Component,
    ContactComponent,
    ErrorPageComponent,
    PreloaderComponent,
    ResetPasswordComponent,
    ProfileInfoComponent,
    CategoriesComponent,
    ResturantsComponent,
    CompanyComponent,
    BasketComponent,
    SpacePipe,
    CompanyCategoriesComponent,
    MyOrdersComponent,
    OrderDetailsComponent,
    ModalContentComponent,
    TermsComponent,
    PrivacyComponent,
    SafeHtmlPipe,
    PaymentComponent,
    CardPaymentComponent,
    CancelUrlComponent,
    NoDataComponent,
    ResturantsDataComponent,
    DeleteAccountComponent,
    DynamicCurrencyPipePipe,
    HtmlToPlainTextPipe,
    ConfirmDialogComponent,
    BasketModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // SkeletonModule,
    NgxSkeletonLoaderModule.forRoot({//
      animation: 'pulse',
      loadingText: 'This item is actually loading...',
    }),
    MatDialogModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 2000, // 15 seconds
      progressBar: true,
      positionClass: 'toast-top-center',
    }),
    NgbModule,
    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      
    }),
    // SocialLoginModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    GridViewModule,
    RecaptchaFormsModule,
    RatingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4',
      apiKey: 'AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4',
      libraries: ['places'],
    }),
    GooglePlaceModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatIntlTelInputComponent
  ],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '474944764554-sud0t5tternvdj622jmnuq6bk4gmhq62.apps.googleusercontent.com'
    //           // '474944764554-gta4p9l12pdoucng3sfdocdc1p7i6gc0.apps.googleusercontent.com'//production
    //         ),
    //       },
    //     ],
    //     onerror: (err) => {
    //       console.error(err);
    //     },
    //   } as SocialAuthServiceConfig,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    LocalStorageGuard,
    AddressGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
