import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudOperations, PaginationFilter, ResponseResult } from '../models-classes/common.model';

// @Injectable({
//   providedIn: 'root',
// })
export abstract class CrudService<T, DTO, ID> implements CrudOperations<T, DTO, ID> {
  constructor(protected _http: HttpClient, protected _base: string) {}

  createOrupdate(model: T, actionName: string): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(this._base + actionName, model, {});
  }
  createOrupdateWithDTO(model: DTO, actionName: string): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(this._base + actionName, model, {});
  }
  filterList(filter: PaginationFilter, actionName: string): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(this._base + actionName, filter, {});
  }
  getById(id: ID, actionName: string): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + actionName + '?id=' + id);
  }

  getAll(actionName: string): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + actionName);
  }

  delete(id: ID, actionName: string): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(this._base + actionName + '?id=' + id, {});
  }
}
