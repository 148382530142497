import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Review } from 'src/app/models-classes/address/address.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  addReviewForm: UntypedFormGroup;
  current_lang = localStorage.getItem('current_lang') || 'en';
  order: any;
  id = 0;
  rate = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public settingService: SettingsService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService
  ) {}
  // paymentMethodText = 'Select your payment method';
  paymentMethodText = 'Your payment method is Cach';

  percent: number = 100;
  intervalTime = 0;
  showCircle: boolean = false;
  subscription: Subscription;
  intervalId: any;
  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.getOrder();
  }
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  paymentNumber = 0;
  isApple = false;

  paymentMethodIcons = {
    Cach: {
      iconClass: '/assets/img/cards/cash.png',
      numericValue: 0,
      text: 'Cach',
    },
    // POS: {
    //   iconClass: '/assets/img/cards/pos.png',
    //   numericValue: 1,
    //   text: 'POS',
    // },
    // ApplePay: {
    //   iconClass: '/assets/img/cards/apple-pay.png',
    //   numericValue: 2,
    //   text: 'Apple',
    // },
    Knet: {
      iconClass: '/assets/img/cards/knet.png',
      numericValue: 3,
      text: 'Knet',
    },
    Card: {
      iconClass: '/assets/img/cards/credit-card.png',
      numericValue: 4,
      text: 'Card',
    },
    WalletPay: {
      iconClass: '/assets/img/cards/wallet.png',
      numericValue: 5,
      text: 'Wallet',
    },
  };
  closeModal() {
    if (this.paymentMethodText == 'Select your payment method')
      this.paymentMethodText = 'Your payment method is Cach';
    $('#paymentMethodsModal').modal('hide');
  }
  toggleModal(id, text = 'Cach') {
    this.paymentNumber = id;
    this.paymentMethodText = 'Your payment method is ' + text;
  }
  getPaymentMethodKeys() {
    return Object.keys(this.paymentMethodIcons);
  }
  setReviewForm() {
    this.addReviewForm = this.fb.group({
      content: [''],
    });
  }
  totalTime: number = 0;
  orderDate: any;
  parseOrderDate(dateString: string): Date {
    // Check if the date string is in ISO 8601 format with fractional seconds
    if (dateString.includes('.')) {
      // Remove fractional seconds for consistent parsing
      dateString = dateString.split('.')[0];
    }
    return new Date(dateString);
  }
  getOrder() {
    this.authService.getOrderDetails(this.id).subscribe((res) => {
      this.order = res.data;
      this.orderDate = this.parseOrderDate(
        this.order.orderStatusDetails[0].date
      );
      console.log(this.orderDate);
      if (this.order.orderStatus == 4) {
        this.setReviewForm();
      }
      if (this.order.isDirectAcceptService) {
        this.updateCountdown();
        this.intervalId = setInterval(() => {
          this.updateCountdown();
        }, 1000);
      }
      // const currentDate = new Date();
      // // const orderDate = new Date(this.order.orderDate);
      // const orderDate = new Date(new Date().getTime() + 4.5 * 60 * 1000);
      // this.totalTime = (orderDate.getTime() - currentDate.getTime()) / 1000;
      // if (this.totalTime <= 300) {
      //   // Check if the remaining time is 5 minutes or less
      //   this.showCircle = true;
      //   const intervalTime = this.totalTime / 100; // Time for each percentage step in seconds

      //   interval(1000)
      //     .pipe(take(this.totalTime))
      //     .subscribe(() => {
      //       if (this.percent > 0) {
      //         this.percent -= 100 / this.totalTime; // Decrease the percentage
      //       }
      //     });
      // } else {
      //   this.showCircle = false;
      // }
      console.log(this.order);
    });
  }
  api_url = environment.api + '/Customer/PrintInvoice?id=';
  printInvoice() {
    let lang = this.current_lang == 'en' ? '' : '&lang=1'
    const url = `${environment.api.baseUrl}/customer/printinvoice?id=${this.id}${lang}`;
    window.open(url, '_blank');
    // this.openPdf(this.api_url + this.id);
  }
  openPdf(pdfUrl: string) {
    window.open(pdfUrl, '_blank');
  }
  updateCountdown(): void {
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - this.orderDate.getTime();
    console.log(timeDifference);
    const timeRemaining = timeDifference / 1000; // Remaining time in seconds
    console.log(timeRemaining);
    if (timeRemaining <= 300 && timeRemaining >= 0) {
      // Show circle if remaining time is 5 minutes or less and not negative
      this.showCircle = true;
      console.log(currentDate);
      console.log(timeRemaining);
      this.percent = (timeRemaining / 300) * 100; // Calculate the percentage
      console.log(this.percent);
      // this.getOrder();
    } else {
      this.showCircle = false; // Hide the circle if remaining time is more than 5 minutes or time has passed
      this.percent = 0;
    }
  }
  openInNewTab(htmlString: string, type = 1) {
    const newWindow = window.open();
    newWindow.document.write(htmlString);
  }
  htmlForm: any;
  pay() {
    const transaction = Date.now().toString();
    localStorage.setItem('orderIdAfter', this.id + '');
    localStorage.setItem('paymentTypeId', this.paymentNumber + '');
    localStorage.setItem('paymentTransactionToken', transaction);
    let obj = {
      orderId: this.id,
      paymentTypeId: this.paymentNumber,
      paymentTransactionToken: transaction,
      isWeb: true,
    };
    this.paymentService.paymentChekout(obj).subscribe((res) => {
      if (res.isSuccess) {
        if (this.paymentNumber == 3) {
          this.htmlForm = res.data;
          this.openInNewTab(res.data);
        } else {
          localStorage.setItem('paymentRefNo', res.data.sessionId);
          this.htmlForm = res.data.form;
          window.location.href = res.data.url;
        }
      }
    });
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
  }
  createReview(data, formDirective: FormGroupDirective) {
    if (!this.addReviewForm.valid) {
      this.toastr.error(
        this.translateService.instant('Please check all fields')
      );
      return;
    }
    if (this.rate == 0) {
      this.toastr.warning(
        this.translateService.instant('The field Rate must be between 1 and 5.')
      );
      return;
    }
    let review: Review = {
      rate: this.rate ? this.rate : 0,
      content: data.content,
      reviewDate: new Date(),
      companyId: this.order.companyId,
      orderId: this.id,
    };
    console.log(review);
    this.authService.addReview(review).subscribe((res) => {
      if (res.isSuccess) {
        this.toastr.success(
          this.translateService.instant('Evaluation completed successfully')
        );
      } else {
        const translatedErrors = res.errors.map((error) =>
          this.translateService.instant(error)
        );
        const errorMessage = translatedErrors.join('\n');
        this.toastr.error(errorMessage);
        // let errorMessage = res.errors.join('\n'); // Using newline for better readability
        // this.toastr.error(errorMessage);
      }
    });
  }
}
