<div [ngClass]="current_lang == 'en' ? 'text-right m-4' : 'text-left m-4'">
  <a data-toggle="modal" data-target="#staticBackdrop" href="javascript:void(0)" class="text-light-white fw-500">
    <img style="height: 30px;width: 30px;" [src]="flagImage" class="rounded-circle" alt="userimg">
  </a>
</div>
<div class="row no-gutters">
  <div class="col-12 col-md-6 logo">
    <div class="main-banner">
      <img src="/assets/img/logo/logo-white.png" class="img-fluid full-width main-img2" alt="banner">
      <!-- <div class="overlay-2 main-padding">
        <img src="assets/img/logo-2.jpg" class="img-fluid" alt="logo">
      </div> -->
      <!-- <img src="assets/img/banner/burger.png" class="footer-img" alt="footer-img"> -->
    </div>
  </div>
  <div class="col-12 col-md-6 form-container-style">

    <div class="section-2 user-page main-padding">
      <div class="login-sec">
        <div class="login-box">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value,formDirective)"
            #formDirective="ngForm">
            <h4 class="text-light-black fw-600">{{'Create your account' | translate}}</h4>
            <div class="row">
              <!-- <div class="col-lg-12 col-md-12 col-sm-12 d-none">
                <div class="form-group">
                  <label class="text-light-white fs-14">{{'User Name' | translate}}</label>
                  <input
                    [ngClass]="{ 'is-invalid': f.userName.errors && (f.userName.dirty || submitted), 'is-valid': f.userName.valid }"
                    type="text" formControlName="userName" class="form-control form-control-submit"
                    placeholder="User Name" required>
                  <div *ngIf="f.userName.touched && f.userName.errors" class="invalid-feedback">
                    <div *ngIf="f.userName.errors.required">{{'User Name' | translate}} {{'validations.isrequired' |
                      translate}}</div>
                  </div>
                </div>
              </div> -->
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="text-light-white fs-14">{{'Full Name' | translate}}</label>
                  <input
                    [ngClass]="{ 'is-invalid': f.fullName.errors && (f.fullName.dirty || submitted), 'is-valid': f.fullName.valid }"
                    type="text" formControlName="fullName" class="form-control form-control-submit"
                    placeholder="Full Name" required>
                  <div *ngIf="f.fullName.touched && f.fullName.errors" class="invalid-feedback">
                    <div *ngIf="f.fullName.errors.required">{{'Full Name' | translate}} {{'validations.isrequired' |
                      translate}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <label>{{'PhoneNumber' | translate}}</label>
                <div class="input-group">
                  <!-- <input placeholder="{{'Type Phone Number'}}" type="number" class="form-control" formControlName="phone" value="0">
                    <div *ngIf="f.phone.touched && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">{{'Phone' | translate}}
                            {{'validations.isrequired' | translate}}</div>
                    </div> -->
                  <mat-form-field appearance="fill" class="w-100">
                    <ngx-mat-intl-tel-input formControlName="phone" [enablePlaceholder]="true"
                      [preferredCountries]="['kw', 'jo']" [enableSearch]="true"
                      searchPlaceholder="{{'Search country' | translate}}" [cssClass]="'custom form-control'"
                      name="phone"></ngx-mat-intl-tel-input>
                    <mat-error *ngIf="(submitted || f.phone.dirty) && f.phone.errors">
                      <ng-container *ngIf="f.phone.errors.required; else invalidPhone">
                        <div class="text-align-left">
                          {{'PhoneNumber' | translate}} {{'validations.isrequired' | translate}}
                        </div>
                      </ng-container>
                      <ng-template #invalidPhone>
                        <div class="text-align-left" *ngIf="f.phone.errors.invalidPhoneNumber">
                          {{'PhoneNumber' | translate}} {{'validations.notvalid' | translate}}
                        </div>
                      </ng-template>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 my-4">
                <div class="form-group">
                  <label class="text-light-white fs-14">{{'Email' | translate}}</label>
                  <input
                    [ngClass]="{ 'is-invalid': f.email.errors && (f.email.dirty || submitted), 'is-valid': f.email.valid }"
                    type="text" formControlName="email" class="form-control form-control-submit" placeholder="Email I'd"
                    required>
                  <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">{{'Email' | translate}} {{'validations.isrequired' |
                      translate}}</div>
                    <div *ngIf="f.email.errors.email">{{'Email' | translate}} {{'validations.notvalid' | translate}}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="text-light-white fs-14">{{'Password' | translate}}</label>
                  <input
                    [ngClass]="{ 'is-invalid': f.password.errors && (f.password.dirty || submitted), 'is-valid': f.password.valid }"
                    type="password" id="password-field" formControlName="password" name="#"
                    class="form-control form-control-submit pass" value="password" placeholder="Password" required>
                  <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                  <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
                    <!-- required -->
                    <span class="text-danger" *ngIf="(f.password.dirty || submitted) && !requiredValid">{{'Password' |
                      translate}} {{'validations.isrequired' | translate}}</span>

                    <!-- password validation rules -->
                    <div id="validation-rules" *ngIf="requiredValid">
                      <!-- minlength -->
                      <div [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                        <i [attr.class]="minLengthValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                        {{'validations.minlength' | translate : {number:8} }}
                      </div>

                      <!-- requires digit -->
                      <div [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                        <i [attr.class]="requiresDigitValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                        {{'validations.requiresDigit' | translate : {number:1} }}
                      </div>

                      <!-- requires uppercase -->
                      <div
                        [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                        <i [attr.class]="requiresUppercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                        {{'validations.requiresUppercase' | translate:{number:1} }}
                      </div>

                      <!-- requires lowercase -->
                      <div
                        [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                        <i [attr.class]="requiresLowercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                        {{'validations.requiresLowercase' | translate : {number:1} }}
                      </div>

                      <!-- requires special characters -->
                      <div
                        [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                        <i [attr.class]="requiresSpecialCharsValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                        {{'validations.requiresSpecialChars' | translate : {number:1} }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group checkbox-reset">
                    <label class="custom-checkbox mb-0">
                      <input type="checkbox" name="#"> <span class="checkmark"></span> Keep me signed in</label>
                  </div> -->
                <div class="form-group">
                  <button (click)="changeType(0)" type="submit" class="btn-second btn-submit full-width">{{'Create' |
                    translate}}</button>
                </div>
                <div class="sign-up-terms text-style-right">
                  {{'By signing up, you agree to our' | translate}}
                  <a routerLink="/terms" target="_blank">{{'Terms of use' | translate}}</a>
                  {{'and' | translate}}
                  <a routerLink="/privacy-policy" target="_blank">{{'Privacy Policy' | translate}}</a>.
                </div>

                <div *ngIf="confirm_email" class="form-group text-center alert alert-success">
                  <p class="text-light-black mb-0 font-weight-bold">{{'Confirm Your account' | translate}} <a
                      id="confirmBtn" style="color:#ff0018" (click)="open(modaladd)">{{'Confirm' | translate}}
                    </a>
                  </p>
                </div>
                <!-- <div class="form-group text-center"> <span>or</span>
                </div> -->
                <!-- <div class="form-group">
                  <button (click)="changeType(1)" type="submit" class="btn-second btn-facebook full-width">
                    <img src="assets/img/facebook-logo.svg" alt="btn logo">Continue with Facebook</button>
                </div> -->

                <!-- <div class="form-group">
                  <button (click)="changeType(2)" type="submit" class="btn-second btn-google full-width">
                    <img src="assets/img/google-logo.png" alt="btn logo">Continue with Google</button>
                </div> -->
                <div class="form-group text-center">
                  <p class="text-light-black mb-0">{{'I already have an account' | translate}} <a
                      routerLink="/login">{{'Sign in' | translate}}</a>
                  </p>
                </div>
                <!-- <span class="text-light-black fs-12 terms">By creating your quickmunch account, you agree to
                  the <a routerLink="/register"> Terms of Use </a> and <a routerLink="/register"> Privacy
                    Policy.</a></span> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modaladd let-modal>
  <div class="modal-header">
    <h3 class="modal-title has-icon ms-icon-round"><i class="flaticon-share bg-primary text-white"></i>
      {{'Activate Your Account' | translate}}</h3>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <h5 *ngIf="activationMethod == ''">{{'Select Activation Type' | translate}}</h5>
    <div class="d-flex" *ngIf="!checkVertification">
      <button [ngClass]="{'selected': activationMethod === 'email'}" (click)="setActivationMethod('email')" type="button" class="btn-second btn-submit mx-2 full-width selected">{{'Email' | translate}}</button>
      <button [ngClass]="{'selected': activationMethod === 'phone'}" (click)="setActivationMethod('phone')" type="button" class="btn-second btn-submit full-width selected">{{'PhoneNumber' | translate}}</button>
    </div>
    <form *ngIf="confirmEmailForm" [formGroup]="confirmEmailForm"
      (ngSubmit)="verifyOtp(confirmEmailForm.value,formDirective)" #formDirective="ngForm">
      <!-- <h4 class="text-light-black fw-600">Activate Your Account</h4> -->
      <div class="row my-2" *ngIf="checkVertification">
        <div class="col-12">
          <div class="form-group">
            <!-- <label class="text-light-white fs-14">{{'Code' | translate}}</label> -->
            <input
              [ngClass]="{ 'is-invalid': f1.code.errors && (f1.code.dirty || submitted1), 'is-valid': f1.code.valid }"
              formControlName="code" type="text" class="form-control form-control-submit" value="" placeholder="Code">
            <div *ngIf="f1.code.touched && f1.code.errors" class="invalid-feedback">
              <div *ngIf="f1.code.errors.required">{{'Code' | translate}} {{'validations.isrequired'}}!</div>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn-second btn-submit full-width">
              <!-- <img src="assets/img/M.png" alt="btn logo"> -->
              {{'Confirm' | translate}}</button>
          </div>
          <div class="form-group text-center"> <span>{{'or' | translate}}</span>
          </div>
          <div class="form-group text-center mb-0" style="cursor:pointer"> <a (click)="resendOtp()"
              style="color:#ff0018">{{'Resend Code' | translate}}</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-nav modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{'Languages' | translate}}</h5>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div [ngClass]="{'selected-country' : lang.key == current_lang}" (click)="changeLang(lang.key)"
              *ngFor="let lang of langs | keyvalue" class="gem-points country-hover p-2">
              <a href="javascript:void(0)"> <img style="height: 30px;width: 30px;"
                  [src]="lang.key == 'ar' ? 'https://rahaldelivery.blob.core.windows.net/static/kuwait.png' : 'https://th.bing.com/th/id/R.235fc65463d0c1eefa102801ba282e71?rik=rToZIjguCeXCXQ&pid=ImgRaw&r=0'"
                  class="rounded-circle" alt="userimg">
                <span class="span-color">{{lang.value | translate}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Close' | translate}}</button>
      </div>
    </div>
  </div>
</div>