<app-navbar (SetLatLng)="handleLatLng($event)" (GetCountry)="getCountry($event)" (GetCountryId)="getCountryId($event)"></app-navbar>
<!-- <app-categories [id]="id" (GetIds)="getGlobalCategoryIds($event)"></app-categories> -->
<div class="row fixed-style mb-3 w-100" style="z-index: 3;">
  <div class="col-lg-12 my-4">
    <div class="scroll-container">
      <app-categories style="text-align: -webkit-center;" [id]="id" (GetIds)="getGlobalCategoryIds($event)"></app-categories>
    </div>
  </div>
</div>
<main class="main-style">
  <aside class="sidebar-left">
    <div class="filter-sidebar mb-5">
      <h4 class="text-light-black fw-600 title-2">{{'Filters' | translate}}
        <small class="fs-12">
          <a (click)="clear()" class="text-light-black fw-500">{{'Clear all' | translate}}</a>
        </small>
      </h4>
      <div class="sidebar-tab">
        <div class="filters">
          <div class="card">
            <div class="card-header">
              <a class="card-link text-light-black fw-700 fs-16" data-toggle="collapse" href="#deliverycollapseOne">
                {{'Feature' | translate}}
              </a>
            </div>
            <div id="deliverycollapseOne" class="collapse show">
              <div class="card-body">
                <form>
                  <label class="custom-checkbox">
                    <input [(ngModel)]="openNow" (change)="changeFeature($event, 1)" type="checkbox" name="#">
                    <span class="checkmark"></span> {{'Open Now' | translate}}
                  </label>
                  <label class="custom-checkbox">
                    <input [(ngModel)]="freeDelivery" (change)="changeFeature($event, 2)" type="checkbox" name="#">
                    <span class="checkmark"></span> {{'Free Delivery' | translate}}
                  </label>
                </form>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <a class="card-link text-light-black fw-700 fs-16" data-toggle="collapse" href="#deliverycollapseTwo">
                {{'Rating' | translate}}
              </a>
            </div>
            <div id="deliverycollapseTwo" class="collapse show">
              <div class="card-body">
                <div class="rating">
                  <ngb-rating (rateChange)="onRatingChange($event)" [max]="5" [(rate)]="currentRate"></ngb-rating>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <a class="card-link text-light-black fw-700 fs-16" data-toggle="collapse" href="#deliverycollapseThree">
                {{'Minimum amount' | translate}}
              </a>
            </div>
            <div id="deliverycollapseThree" class="collapse show">
              <div class="card-body">
                <label class="rad-label">
                  <input checked value="1" (change)="getMinimumOrderAmmount(0)" type="radio" class="rad-input"
                    name="rad" id="showAllRad">
                  <div class="rad-design"></div>
                  <div class="rad-text">{{'Show all' | translate}}</div>
                </label>
                <label class="rad-label">
                  <input value="2" type="radio" class="rad-input" name="rad" (change)="getMinimumOrderAmmount(10)">
                  <div class="rad-design"></div>
                  <div class="rad-text">{{'10,000'}} {{currencySymbol}} {{'or less' | translate}}</div>
                </label>
                <label class="rad-label">
                  <input value="3" type="radio" class="rad-input" name="rad" (change)="getMinimumOrderAmmount(15)">
                  <div class="rad-design"></div>
                  <div class="rad-text">{{'15,000'}} {{currencySymbol}} {{'or less' | translate}}</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </aside>
  <section>
    <div class="row">
      
      <div class="col-12">
        <div class="section-header-left">
          <h3 class="text-light-black header-title title-2">
            {{'Most popular near you' | translate}}
            <small>
              <a href="javascript:void(0)" class="text-dark-white fw-600">{{fullCount}} {{shopTypeName}}</a>
            </small>
          </h3>
        </div>
      </div>
      
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row w-50">
        </div>
        <div class="sort-tag-filter padding-15">
          <div class="search">
            <input type="text" placeholder="{{'Search...' | translate}}" [(ngModel)]="filter_input"
              (input)="filter($event)" />
          </div>
          <div class="sorting">
            <span class="text-dark-white fs-16 fw-600">{{'Sort' | translate}}: </span>
            <select [(ngModel)]="sort_by" (change)="changeOrder($event)">
              <option value="0">{{'Best Match' | translate}}</option>
              <option value="1">{{'Reviews' | translate}}</option>
              <option value="2">{{'Distance' | translate}}</option>
              <option value="3">{{'Delivery Time' | translate}}</option>
              <option value="4">{{'Most Popular' | translate}}</option>
              <option value="5">{{'Min. Order Amount' | translate}}</option>
              <option value="6">{{'Delivery Costs' | translate}}</option>
              <option value="7">{{'Product Price' | translate}}</option>
              <option value="8">{{'Alphabetical' | translate}}</option>
            </select>
          </div>
        </div>
        <ng-container *ngIf="!check_filter">
          <ngb-pagination *ngIf="companies.length > 0" class="d-flex justify-content-center"
            [collectionSize]="fullCount" [page]="pageIndex" [pageSize]="pageSize" [rotate]="true" [ellipses]="false"
            [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
        </ng-container>
        <div class="row" *ngIf="!check_filter">
          <ng-container *ngIf="companies.length > 0; else noDataComp">
            <div class="col-md-4 mb-4" *ngFor="let item of companies; let i = index">
              <div class="product-box">
                <div class="product-img product-img-style">
                  <a routerLink="/company" [queryParams]="{id: item.id, fav: item.isFavorite}">
                    <img
                      [src]="!item.coverImage?.includes('http') || !item.coverImage ? 'assets/img/restaurants/255x150/shop-1.jpg' : item.coverImage"
                      class="img-fluid full-width img-child" alt="product-img">
                    <img class="logo-overlay"
                      [src]="!item.logo?.includes('http') || !item.logo ? 'assets/img/gallery/img-1.jpg' : item.logo"
                      alt="Logo">
                  </a>
                </div>
                <div class="product-caption">
                  <div class="title-box">
                    <h6 class="product-title">
                      <a routerLink="/company" [queryParams]="{id: item.id, fav: item.isFavorite}"
                        class="text-light-black">{{item.name}}</a>
                    </h6>
                  </div>
                  <div class="product-details">
                    <div class="price-time">
                      <span class="text-light-black time">{{item.deliveryTime}} {{'min' | translate}}</span>
                      <div class="d-flex">
                        <span class="text-light-white price">{{'Minimum' | translate}} </span>
                        <span class="text-light-white price mx-1"> {{item.minOrderPrice | currency:
                        settingService.getCurrencySymbol()}} </span>
                      </div>
                      <div class="d-flex">
                        <span class="text-light-white price">{{'WorkHours' | translate}} :</span>
                        <span class="text-light-white text-style-right price mx-1"> {{item.workHours}}</span>
                      </div>
                    </div>
                    <div class="rating">
                      <span>
                        <i class="fas fa-star main-color" *ngFor="let _ of getarr(item.rate)"></i>
                        <i class="fas fa-star" *ngFor="let _ of getarr(5 - item.rate)"></i>
                      </span>
                      <span class="text-light-white text-right">{{item.reviewsCount}} {{'Ratings' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="product-footer-2">
                  <div class="discount">

                    <div class="d-flex">
                      <span class="text-light-white price">{{'Delivery Costs' | translate}} :</span>
                      <span class="text-light-white text-style-right price mx-1"> {{item.deliveryFees | currency:
                      settingService.getCurrencySymbol()}}</span>
                    </div>

                    <!-- <span><img class="motor-icon" src="assets/img/delivery_motor.png" width="14" alt="..."></span>
                    <span class="text-success fs-12">{{item.deliveryFees | currency:
                      settingService.getCurrencySymbol()}}</span> -->
                  </div>
                  <div class="discount-coupon" *ngIf="authService.checkUserIsLogedIn()">
                    <span class="circle-tag" (click)="addToFavorite(item, 1)">
                      <img [src]="!item.isFavorite ? 'assets/img/svg/013-heart-1.svg' : 'assets/img/svg/010-heart.svg'"
                        alt="tag">
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noDataComp>
            <div class="no-data-container text-center col-lg-12">
              <img src="assets/img/no-data.png" alt="No data found" class="no-data-img mb-4">
              <h2 class="text-dark">{{'No Items Available' | translate}}</h2>
              <p class="text-muted">{{'CouldNotFindItems' | translate}}</p>
              <!-- <button class="btn btn-primary" (click)="refresh()">Refresh</button> -->
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="!check_filter">
          <ngb-pagination *ngIf="companies.length > 0" class="d-flex justify-content-center"
            [collectionSize]="fullCount" [page]="pageIndex" [pageSize]="pageSize" [rotate]="true" [ellipses]="false"
            [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
        </ng-container>
        <div class="payment-option-tab" *ngIf="check_filter">
          <ul class="nav nav-tabs d-flex justify-content-center">
            <li (click)="navChanged(1)" class="nav-item"> <a class="nav-link fw-600 active" data-toggle="tab"
                href="#companies">{{'Companies' | translate}}</a>
            </li>
            <li (click)="navChanged(2)" class="nav-item"> <a class="nav-link fw-600" data-toggle="tab"
                href="#products">{{'Products' | translate}} </a>
            </li>
          </ul>
          <div class="tab-content">
            <ng-container *ngIf="check_filter">
              <ngb-pagination *ngIf="companies.length > 0" class="d-flex justify-content-center"
                [collectionSize]="fullCount" [page]="pageIndex" [pageSize]="pageSize" [rotate]="true" [ellipses]="false"
                [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
            </ng-container>
            <div class="tab-pane active" id="companies">
              <div class="row">
                <ng-container *ngIf="companies.length > 0; else noData">
                  <div class="col-lg-12" *ngFor="let item of companies;let i = index">
                    <div class="restaurent-product-list">
                      <div class="restaurent-product-detail">
                        <div class="restaurent-product-left">
                          <div class="restaurent-product-title-box">
                            <div class="restaurent-product-box">
                              <div class="restaurent-product-title" style="width: 300px;">
                                <h6 class="mb-2" data-toggle="modal" data-target="#restaurent-popup"><a
                                    routerLink="/company" [queryParams]="{id: item.id, fav: item.isFavorite}"
                                    class="text-light-black fw-600">{{item.name}}</a></h6>
                                <!-- <p class="text-light-white">600-700 Cal.</p> -->
                              </div>
                              <div class="restaurent-product-label"> <span
                                  class="rectangle-tag bg-gradient-red text-custom-white">{{item.minOrderPrice |
                                  currency:
                                  settingService.getCurrencySymbol()}} {{'Min.' | translate}}</span>
                                <span class="rectangle-tag bg-dark text-custom-white">{{item.deliveryTime}} {{'min' |
                                  translate}}</span>
                              </div>
                            </div>
                            <div class="restaurent-product-rating">
                              <div class="ratings">
                                <span *ngFor="let _ of getarr(item.rate)" class="text-yellow"><i
                                    class="fas fa-star"></i></span>
                                <span *ngFor="let _ of getarr(5 - item.rate)"><i class="fas fa-star"></i></span>
                              </div>
                              <div class="rating-text">
                                <p class="text-light-white fs-12 title">{{item.reviewsCount}} {{'Ratings' | translate}}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="restaurent-product-caption-box"> <span
                              class="text-light-white">{{item.description}}</span>
                          </div>
                          <div class="restaurent-tags-price">
                            <!-- <div class="restaurent-tags"> <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/004-leaf.svg" alt="tag">
                                  </span>
                                  <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/006-chili.svg" alt="tag">
                                  </span>
                                  <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/005-chef.svg" alt="tag">
                                  </span>
                                  <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/008-protein.svg" alt="tag">
                                  </span>
                                  <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/009-lemon.svg" alt="tag">
                                  </span>
                                </div> -->
                            <span *ngIf="authService.checkUserIsLogedIn()" class="circle-tag"
                              (click)="addToFavorite(item, 1)">
                              <img
                                [src]="!item.isFavorite ? 'assets/img/svg/013-heart-1.svg' : 'assets/img/svg/010-heart.svg'"
                                alt="tag">
                            </span>
                            <div class="restaurent-product-price">
                              <h6 class="text-success fw-600 no-margin d-flex">
                                <span class="fa fa-clock"></span>
                                <span class="px-2">{{item.workHours}}</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="restaurent-product-img">
                          <img
                            [src]="!item.logo?.includes('http') || !item.logo ? 'assets/img/gallery/img-1.jpg' : item.logo"
                            class="img-fluid" alt="#">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noData>
                  <div class="no-data-container text-center col-lg-12">
                    <img src="assets/img/no-data.png" alt="No data found" class="no-data-img mb-4">
                    <h2 class="text-dark">{{'No Items Available' | translate}}</h2>
                    <p class="text-muted">{{'CouldNotFindItems' | translate}}</p>
                    <!-- <button class="btn btn-primary" (click)="refresh()">Refresh</button> -->
                  </div>
                </ng-template>
              </div>
            </div>
            <ng-container *ngIf="check_filter">
              <ngb-pagination *ngIf="companies.length > 0" class="d-flex justify-content-center"
                [collectionSize]="fullCount" [page]="pageIndex" [pageSize]="pageSize" [rotate]="true" [ellipses]="false"
                [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
            </ng-container>
            <ng-container *ngIf="check_filter">
              <ngb-pagination *ngIf="products.length > 0" class="d-flex justify-content-center"
                [collectionSize]="fullCount" [page]="pageIndex" [pageSize]="pageSize" [rotate]="true" [ellipses]="false"
                [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
            </ng-container>
            <div class="tab-pane fade" id="products">
              <div class="row">
                <ng-container *ngIf="products.length > 0; else noData">
                  <div class="col-lg-12" *ngFor="let product of products;let i = index">
                    <div class="restaurent-product-list">
                      <div class="restaurent-product-detail">
                        <div class="restaurent-product-left">
                          <div class="restaurent-product-title-box">
                            <div class="restaurent-product-box">
                              <div class="restaurent-product-title" style="width: 300px;">
                                <h6 class="mb-2" data-toggle="modal" data-target="#restaurent-popup"><a
                                    routerLink="/company" [queryParams]="{id: product.id, fav: product.isFavorite}"
                                    class="text-light-black fw-600">{{product.name}}</a></h6>
                                <!-- <p class="text-light-white">600-700 Cal.</p> -->
                              </div>
                              <div class="restaurent-product-label"> <span
                                  class="rectangle-tag bg-gradient-red text-custom-white">{{product.deliveryFees |
                                  currency:
                                  settingService.getCurrencySymbol()}} {{'Fees' | translate}}</span>
                                <span class="rectangle-tag bg-dark text-custom-white">{{product.deliveryTime}} {{'min' |
                                  translate}}</span>
                              </div>
                            </div>
                            <div class="restaurent-product-rating">
                              <div class="ratings">
                                <span *ngFor="let _ of getarr(product.rate)" class="text-yellow"><i
                                    class="fas fa-star"></i></span>
                                <span *ngFor="let _ of getarr(5 - product.rate)"><i class="fas fa-star"></i></span>
                              </div>
                              <div class="rating-text">
                                <p class="text-light-white fs-12 title">{{product.reviewsCount}} {{'Ratings' |
                                  translate}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="restaurent-product-caption-box"> <span
                              class="text-light-white">{{product.description}}</span>
                          </div>
                          <div class="restaurent-tags-price">
                            <!-- <div class="restaurent-tags"> <span class="text-custom-white square-tag">
                                <img src="assets/img/svg/004-leaf.svg" alt="tag">
                              </span>
                              <span class="text-custom-white square-tag">
                                <img src="assets/img/svg/006-chili.svg" alt="tag">
                              </span>
                              <span class="text-custom-white square-tag">
                                <img src="assets/img/svg/005-chef.svg" alt="tag">
                              </span>
                              <span class="text-custom-white square-tag">
                                <img src="assets/img/svg/008-protein.svg" alt="tag">
                              </span>
                              <span class="text-custom-white square-tag">
                                <img src="assets/img/svg/009-lemon.svg" alt="tag">
                              </span>
                            </div> -->
                            <span *ngIf="authService.checkUserIsLogedIn()" class="circle-tag"
                              (click)="addToFavorite(product, 2)">
                              <img
                                [src]="!product.isFavorite ? 'assets/img/svg/013-heart-1.svg' : 'assets/img/svg/010-heart.svg'"
                                alt="tag">
                            </span>
                            <div class="restaurent-product-price">
                              <h6 class="text-success fw-600 no-margin d-flex">
                                <span class="px-2">{{product.price | dynamicCurrency: settingService.getCurrencySymbol() : +settingService.getCurrencyPrecision()}}</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="restaurent-product-img">
                          <img
                            [src]="!product.image?.includes('http') || !product.image ? 'assets/img/gallery/img-1.jpg' : product.logo"
                            class="img-fluid" alt="#">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="check_filter">
              <ngb-pagination *ngIf="products.length > 0" class="d-flex justify-content-center"
                [collectionSize]="fullCount" [page]="pageIndex" [pageSize]="pageSize" [rotate]="true" [ellipses]="false"
                [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
            </ng-container>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!-- <aside class="sidebar-right">
		<ul>
			<li>
				<a href="#heading-1">Headeing 1</a>
				<ul>
					<li><a href="">Subheading 1.1</a></li>
					<li><a href="">Subheading 1.2</a></li>
					<li><a href="">Subheading 1.3</a></li>
				</ul>
			</li>
			<li>
				<a href="#heading-2">Headeing 2</a>
				<ul>
					<li><a href="">Subheading 2.1</a></li>
					<li><a href="">Subheading 2.2</a></li>
					<li><a href="">Subheading 2.3</a></li>
				</ul>
			</li>
		</ul>
	</aside> -->
</main>

<app-footer2 id="footer"></app-footer2>