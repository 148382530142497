<app-navbar (GetCountryId)="getCountryId($event)"></app-navbar>
<!-- 
<div class="container mt-4">
    <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Handle</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td colspan="2">Larry the Bird</td>
            <td>@twitter</td>
          </tr>
        </tbody>
      </table>
</div> -->
<div class="container-table100">
    <div class="wrap-table100">
        <div class="text-style-right font-weight-bold mb-2">
            <a class="btn btn-warning text-white" (click)="back()">{{'Go back' | translate}}</a>
        </div>
        <div class="table-responsive">
            <!-- <app-grid-view [toolbarClick]="toolbarClick" [model]="gridModel"></app-grid-view> -->
            <app-grid-view [editUrl]="'/order-details'" [model]="gridModel"></app-grid-view>

            <!-- <ng2-smart-table [settings]="settings" [source]="source" class="table w-100 thead-primary"></ng2-smart-table> -->
          </div>
        <!-- <div class="d-flex">
            <div class="first-section d-flex">
                <div class="box">
                    <div class="container-1">
                        <span class="icon"><i class="fa fa-search"></i></span>
                        <input (input)="onSearch()" [(ngModel)]="searchTerm" type="search" id="search" placeholder="Search..." />
                    </div>
                </div>
                <div class="box" id="toolbar">
                    <select class="form-control">
                        <option value="">Export Basic</option>
                        <option value="all">Export All</option>
                        <option value="selected">Export Selected</option>
                    </select>
                </div>
            </div>
            <div *ngIf="orders.length > 10" class="second-section"
                [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'">
                <nav class="box" aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" href="#">{{'Previous' | translate}}</a></li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item"><a class="page-link" href="#">{{'Next' | translate}}</a></li>
                    </ul>
                </nav>
            </div>
        </div> -->
        <!-- <div class="table">
            <div class="row-table header-table">
                <div class="cell">
                    {{'Status' | translate}}
                </div>
                <div class="cell">
                    {{'Customer' | translate}}
                </div>
                <div class="cell">
                    {{'Payment method' | translate}}
                </div>
                <div class="cell">
                    {{'Date' | translate}}
                </div>
                <div class="cell">
                    {{'Total' | translate}}
                </div>
                <div class="cell">
                    {{'Order No' | translate}}
                </div>
                <div class="cell">
                </div>
            </div>
            <a routerLink="/order-details" [queryParams]="{id:order.id}" class="row-table row2"
                *ngFor="let order of orders">
                <div class="cell" data-title="Status">
                    <div class="span-content">
                        <div [ngClass]="{'circle-backcolor-pending': order.orderStatus == orderStatus['Pending'], 'circle-backcolor-orderSentToCompany': order.orderStatus == orderStatus['OrderSentToCompany']
                        , 'circle-backcolor-companyPreparingOrder': order.orderStatus == orderStatus['CompanyPreparingOrder'], 'circle-backcolor-driverOnWay': order.orderStatus == orderStatus['DriverOnWay']
                        , 'circle-backcolor-driverArrived': order.orderStatus == orderStatus['DriverArrived'], 'circle-backcolor-orderDelivered': order.orderStatus == orderStatus['OrderDelivered']
                        , 'circle-backcolor-orderCanceled': order.orderStatus == orderStatus['OrderCanceled'],'mr-2' : current_lang == 'en','ml-2':current_lang == 'ar'}"
                            class="circle"></div>
                        <span
                            [ngClass]="{'span-backcolor-pending': order.orderStatus == orderStatus['Pending'], 'span-backcolor-orderSentToCompany': order.orderStatus == orderStatus['OrderSentToCompany']
                        , 'span-backcolor-companyPreparingOrder': order.orderStatus == orderStatus['CompanyPreparingOrder'], 'span-backcolor-driverOnWay': order.orderStatus == orderStatus['DriverOnWay']
                        , 'span-backcolor-driverArrived': order.orderStatus == orderStatus['DriverArrived'], 'span-backcolor-orderDelivered': order.orderStatus == orderStatus['OrderDelivered']
                        , 'span-backcolor-orderCanceled': order.orderStatus == orderStatus['OrderCanceled']}">{{order.orderStatusName}}</span>
                    </div>
                </div>
                <div class="cell" data-title="Customer Name">
                    {{order.customerName}}
                </div>
                <div class="cell" data-title="Age">
                    {{order.paymentTypeName}}
                </div>
                <div class="cell" data-title="Job Title">
                    {{order.orderDate | date:'medium'}}
                </div>
                <div class="cell" data-title="Location">
                    {{order.total}}
                </div>
                <div class="cell" data-title="Location">
                    {{order.orderNumber}}
                </div>
                <div class="cell" data-title="Arrow" data-toggle="tooltip" data-placement="top"
                    title="{{'DetailsSee' | translate}}">
                    <i [ngClass]="current_lang == 'en' ? 'fas fa-angle-right' : 'fas fa-angle-left'"></i>
                </div>
            </a>
        </div> -->
    </div>
</div>
<app-footer2></app-footer2>