import { Component, OnInit } from '@angular/core';
import { InfoService } from 'src/app/services/info/info.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  comment = '';
  getTerms() {
    this.infoService.getTerms().subscribe((res) => {
      this.comment = res.data;
    });
  }
  constructor(private infoService: InfoService) {}

  ngOnInit(): void {
    this.getTerms()
  }
}
