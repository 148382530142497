import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { GridComponent, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { EmitType, L10n, setCulture } from '@syncfusion/ej2-base';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridViewModel } from 'src/app/models-classes/grid-view/grid-view.model';

declare var $: any;

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.css']
})
export class GridViewComponent implements OnInit {
  /**
   *
   */
  public loadingIndicator?: any;

  current_lang = localStorage.getItem("current_lang");
  constructor(private _router: Router, public settingService: SettingsService,private translate: TranslateService,private modalService: NgbModal) {
    if (localStorage.getItem('current_lang') == 'ar') {
      setCulture('ar-AE')
      L10n.load({
        'ar-AE': {
          grid: {
            EmptyRecord: 'لا سجلات لعرضها',
            EmptyDataSourceError: 'يجب أن يكون مصدر البيانات فارغة في التحميل الأولي منذ يتم إنشاء الأعمدة من مصدر البيانات في أوتوجينيراتد عمود الشبكة',
            Delete: 'حذف',
            Add: 'اضافة',
            Edit: 'تعديل',
            Search: 'بحث',
            ClearButton: 'مسح الفلترة',
            FilterButton: 'فلترة',
            Contains: 'يحتوي',
            Equal: 'يساوي',
            NotEqual: 'لا يساوي',
            StartsWith: 'يبدأ ب',
            EndsWith: 'ينتهي ب',
            EnterValue: 'ادخل قيمة',
            GroupDropArea: 'اسحب رأس العمود هنا لتجميع العمود الخاص به'
          },
          pager: {
            currentPageInfo: '{0} من {1} صفحة',
            totalItemsInfo: '({0} العناصر)',
            firstPageTooltip: 'انتقل إلى الصفحة الأولى',
            lastPageTooltip: 'انتقل إلى الصفحة الأخيرة',
            nextPageTooltip: 'انتقل إلى الصفحة التالية',
            All: 'الكل',
            pagerDropDown: 'العناصر لكل صفحة',
            previousPageTooltip: 'انتقل إلى الصفحة السابقة',
            nextPagerTooltip: 'انتقل إلى عناصر بيجر التالية',
            previousPagerTooltip: 'للذهاب إلى عناصر بيجر السابقة'
          }
        }
      });
    }
    // translate.addLangs(['en', 'ar'])
    // translate.use(localStorage.getItem('current_lang'));
    console.log(localStorage.getItem('current_lang'))
  }
  // translateColumn(columnName: string): string {
  //   return this.translate.instant(columnName);
  // }
  ngOnInit(): void {
    this.loadingIndicator = {indicatorType: 'Shimmer'};
    // for (let index = 0; index < this.model.gridViewColumns.length; index++) {
    //   this.model.gridViewColumns[index].headerText = this.translateColumn[this.model.gridViewColumns[index].headerText];
    // }
    console.log(localStorage.getItem('current_lang'));
  }
  trans_item = 'Are you sure to delete this item?';

  query = new Query().addParams('ej2grid', 'true');
  // editSettings = {
  //   allowEditing: true,
  //   allowAdding: true,
  //   allowDeleting: true,
  //   mode: 'Normal',
  // };
  // toolbar = ['Add','Delete', 'Update', 'Cancel'];
  @ViewChild('modaldelete') modalDeleteTemplate: any; // Use ElementRef if needed

  @Input() model!: GridViewModel;
  @Input() service!: any;
  @Input() actionName!: string;
  @Input() addUrl!: string;
  @Input() editUrl: string = '';
  // @Input() toolbarClick: (args: ClickEventArgs) => void;
  @ViewChild('grid', { static: true })
  public grid: GridComponent;
  // clickEvent(e) {
  //   console.log("rr");
  //   var obj = $("#grid").ejGrid("instance"); // take the Grid instance using grid ID
  //   var data = obj.getCurrentViewData()[$(e).closest("tr").index()]; // using tr index in getCurrentViewData get the record
  //   alert(JSON.stringify(data));
  // }
  queryCellInfo(args) {//for add href of column
    // if (args.column.headerText == this.translate.instant('ManageRecords')) {
    //   var aEle = document.createElement("a");
    //   aEle.className = "btn btn-light btn-sm";
    //   aEle.href = "Javascript:void(0)";
    //   aEle.innerText = "Edit";
    //   // args.cell.innerText = "Edit";
    //   args.cell.appendChild(aEle);
    // }
    // if (args.column.field == 'isActive' || args.column.field == 'isDeleted') {
    //   if (args.data.OrderID % 2 == 0) {
    //     //based on condition we have set the font color to the cell
    //     args.cell.style.color = 'red';
    //   } else {
    //     args.cell.style.color = 'blue';
    //   }
    // }
  }
  public queryCellInfoEvent: EmitType<QueryCellInfoEventArgs> = (
    args: QueryCellInfoEventArgs | any
  ) => {
    if (args.column?.field == 'orderStatusName') {
      const isActive = !!args.cell?.textContent?.trim(); // Get the subscriptionStatus value
      // console.log(args.cell?.children);
      // console.log(args.cell?.children[0].children);
      console.log(args);
      console.log(args.cell.classList);
      console.log(args.data);

      switch (args.data.orderStatus) {
        case 0:
          args.cell?.classList.add('span-backcolor-pending');
          break;
        case 1:
          args.cell?.classList.add('span-backcolor-orderSentToCompany');
          break;
        case 2:
          args.cell?.classList.add('span-backcolor-companyPreparingOrder');
          break;
          case 3:
          args.cell?.classList.add('span-backcolor-driverOnWay');
          break;
          case 4:
          args.cell?.classList.add('span-backcolor-driverArrived');
          break;
          case 5:
          args.cell?.classList.add('span-backcolor-orderDelivered');
          break;
          case 6:
          args.cell?.classList.add('span-backcolor-orderCanceled');
          break;
        default:
      }
    }
  };
  cellSelected(e) {
    console.log("asd")
    // var grid = $('.e-grid')[0].ej2_instances[0];
    // var field = grid.columns[e.cellIndex.cellIndex].field;
    // console.log("field-- " + field);
  }
  onRecordClick(args) {
    console.log(args)
    console.log(args.column)
    if (args.column.headerText == '#' || args.column.template.includes('order-details-btn')) {
      if (this.editUrl != '')
        this._router.navigate([this.editUrl], { queryParams: { id: args.rowData.id } });
      else
        this._router.navigate([this.addUrl], { queryParams: { id: args.rowData.id } })
    }
    // console.log(JSON.stringify(args.rowData.id))
    // console.log(args.column.headerText)
  }
  toolbarClick(args) {
    var list = [];
    let counter = 0;
    if (args.item.id == "grid_delete") {
      if (this.grid.getSelectedRecords().length > 1)
        this.trans_item = 'Are you sure to delete this items?';
      this.modalService.open(this.modalDeleteTemplate, { centered: true, windowClass: 'modal-max' });

      // this.grid.getSelectedRecords().map((e) => list.push(e['id']));
      // for (let i = 0; i < list.length; i++) {
      //   this.service.delete(list[i], this.actionName).subscribe(res => {
      //     counter++;
      //   })
      // }
      // if (counter) {
      //   this.grid.refresh();
      // }
      // Write Your code here
    }
    else if (args.item.id == "grid_add") {
      this._router.navigate([this.addUrl])
    }
    else if (args.item.id === 'grid_excelexport') {
      // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
      (this.grid as GridComponent).excelExport();
    }
  }
  dataBound(e) {
    console.log("aaa");
  }
  delete() {
    var list = [];
    let counter = 0;
    this.grid.getSelectedRecords().map((e) => list.push(e['id']));
    for (let i = 0; i < list.length; i++) {
      this.service.delete(list[i], this.actionName).subscribe((res) => {
        counter++;
      });
    }
    if (counter) {
      this.grid.refresh();
    }
  }
}
