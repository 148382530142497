

export class GridViewModel {
  gridId?: string = 'Grid';
  data?: any;
  onCommandClick?: string = '(e)=>commandClick(e)';
  allowSorting?: boolean = true;
  allowMultiSorting?: boolean = false;
  allowFiltering?: boolean = false;
  allowReordering?: boolean = false;
  pageSettings?: any;
  sortSettings?: any;
  allowExcelExport?: boolean = false;
  allowPaging?: boolean = true;
  allowResizing?: boolean = false;
  allowGrouping?: boolean = true;
  allowSelection?: boolean = true;
  allowPdfExport?: boolean = false;
  allowRowDragAndDrop?: boolean = false;
  allowTextWrap?: boolean = false;
  allowEditing?: boolean = false;
  actionBeginName?: string = '';
  allowToEdit?: boolean = false;
  isAllowToDelete?: boolean = false;
  allowToShowDetails?: boolean = false;
  allowToChat?: boolean = false;
  displayAsCheckBox?: boolean = false;
  // pageSettings?: PageSettingsModel;
  recordClick?: any;
  filterSettings?: any;
  editSettings?: any;
  toolbar?: any;
  selectionSettings?: any;
  blueButtonName?: string = 'btn.edit';
  redButtonName?: string = 'btn.delete';
  redButtonType?: string = 'DeleteBtn';
  blueButtonType?: string = 'EditBtn';
  isShowFilterBarOperator?: boolean = false;
  isInformationManaged?: boolean = false;
  url?: string = '';
  onDataBound?: string = '(e)=>OnDataBound(e)';
  gridViewColumns: GridViewColumn[] = [];
}

export class GridViewColumn {
  constructor(field: string = '', headerText: string) {
    this.field = field;
    this.headerText = headerText;
  }
  id?: string = '';
  field?: string = '';
  headerText?= '';
  width?= '';
  type?= GridColumnTypes.Text;
  format?= '';
  visible?= true;
  isAllowEditing?= false;
  isPrimaryKey?= false;
  required?= '';
  template?= ''
  commands?= [];
  disableHtmlEncode?= false;
  valueAccessor?: any;
  textAlign?= TextAlign.Center
}
export enum TextAlign {
  Right = "Right",
  Left = "Left",
  Center = "Center"
}
export enum GridColumnTypes {
  Text = "",
  Date = "",
  Number = "",
  Boolean = "",
  RateEditor = "",
  Combobox = "",
  Checkbox = "checkbox",
}
