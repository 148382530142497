<div *ngIf="check == 1" class="container element-center">
    <h2 class="status success">{{'Payment Successful' | translate}}</h2>
    <!-- Uncomment the line below for payment failure -->
    <!-- <h2 class="status failure">Payment Failed</h2> -->
    
    <p>{{'PaymentSuccessfully' | translate}}!</p>
    <!-- Uncomment the line below for payment failure -->
    <!-- <p>Oops! Something went wrong with the payment. Please try again.</p> -->
    <a routerLink="/order-details" [queryParams]="{id:orderId}" class="btn-second mx-2 btn-submit btn-submit2">{{'Show Order Details' | translate}}</a>
    <a routerLink="/" class="btn-second btn-submit">{{'Back to Home' | translate}}</a>
  </div>
  
  <div *ngIf="check == 2" class="container element-center">
      <h2 class="status failure">{{'Payment Failed' | translate}}</h2>
      <p>{{'PaymentFailed' | translate}}.</p>
      <a routerLink="/" class="btn-second btn-submit">{{'Back to Home' | translate}}</a>
    </div>