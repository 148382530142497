import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/helper/contact/contact-helper.service';
import { Contact } from '../../models/contact/contact';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  model = new Contact();
  submitted = false;
  error: {} | undefined;
  constructor(
    private contactService: ContactService,
    private authService: AuthService,
    private _toastr: ToastrService,
    private translateService: TranslateService
  ) {}
  current_lang = localStorage.getItem('current_lang');
  onSubmit() {
    this.submitted = true;
    if(this.model.recaptcha == undefined)
      return;
    let data = {
      name: this.model.name,
      phone: this.model.phone,
      email: this.model.email,
      message: this.model.message,
    };
    console.log(this.model.recaptcha == undefined)
    this.authService.contact(data).subscribe((res) => {
      if (res.isSuccess) {
        this.model = new Contact();
        this._toastr.success(
          this.translateService.instant('The email was sent successfully')
        );
      } else {
        const translatedErrors = res.errors.map((error) =>
          this.translateService.instant(error)
        );
        const errorMessage = translatedErrors.join('\n');
        this._toastr.error(errorMessage);
      }
    });
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
  }

  ngOnInit(): void {}
}
