<app-navbar (GetCountryId)="getCountryId($event)"></app-navbar>


<div class="container my-4">

    <div class="w-100">
        <div class="d-flex">
            <h4 class="p-3 mb-0 text-style-right">{{order?.companyName}}</h4>
            <p [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'" class="p-3 mb-0 text-style-right">
                {{order?.orderStatusDetails[0].date | date:'medium'}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <h4 class="text-style-right"><span class="badge badge-pill badge-warning">{{'Order Number' | translate}} #
                {{order?.orderNumber}}</span>
        </h4>
        <button class="btn btn-success my-2" type="button"
                    (click)="printInvoice()">{{'Print Invoice' | translate}}</button>
        </div>
        <div class="alert alert-info d-flex">
            <strong>{{order?.orderStatusName}}</strong>
        </div>
        <ng-container *ngIf="showCircle && order?.isDirectAcceptService && !order?.isPaid && order?.orderStatus == 0">
            <div class="text-center">
                <!-- <circle-progress
      [percent]="percent"
      [radius]="100"
      [outerStrokeWidth]="16"
      [innerStrokeWidth]="8"
      [outerStrokeColor]="'#78C000'"
      [innerStrokeColor]="'#C7E596'"
      [animation]="true"
      [animationDuration]="1"
    ></circle-progress> -->
                <div *ngIf="showCircle" class="text-center">
                    <circle-progress [percent]="percent" [radius]="100" [outerStrokeWidth]="16" [innerStrokeWidth]="8"
                        [outerStrokeColor]="'#78C000'" [innerStrokeColor]="'#C7E596'" [animation]="true"
                        [animationDuration]="0"></circle-progress>
                </div>
            </div>
        </ng-container>
        <div *ngIf="!showCircle || order?.orderStatus != 0" class="card card-order-details mt-4">
            <div class="card-body p-0 table-responsive">
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th class="col-order-details text-style-right" scope="col">{{'Product Name' | translate}}
                            </th>
                            <th class="col-order-details text-style-right" scope="col"></th>
                            <th class="col-order-details text-style-right" scope="col">{{'Quantity' | translate}}</th>
                            <th class="col-order-details text-style-right" scope="col">{{'Price' | translate}}</th>
                            <th class="col-order-details text-style-right" scope="col">{{'Total' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-style-right" *ngFor="let detail of order?.orderDtls">
                            <th>
                                <img [src]="detail.imageUrl ? detail.imageUrl : '/assets/img/sidebanner-1.jpg'"
                                    alt="product" class="img-order-detail" width="80">
                            </th>
                            <td>{{detail.extraDetails}}</td>
                            <td>{{detail.quantity}}</td>
                            <td><strong>{{detail.price | currency : settingService.getCurrencySymbol()}}</strong></td>
                            <td><span class="badge badge-warning">{{detail.total | currency :
                                    settingService.getCurrencySymbol()}}</span></td>
                        </tr>
                        <tr>
                            <th class="d-flex" [colSpan]="4">
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"><img
                                        src="/assets/img/Fa-Team-Fontawesome-FontAwesome-Money-Bill.512.png" width="20"
                                        alt=""> {{'Payment method' | translate}}: </span>
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment">
                                    {{order?.paymentTypeName}}</span>
                            </th>
                            <!-- <td>
                                <span class="text-muted">Total Price</span>
                                <strong>$100</strong>
                            </td> -->
                            <!-- <td>
                                <span class="text-muted">Total Paid</span>
                                <strong>$100</strong>
                            </td> -->
                            <!-- <td>
                                <span class="text-muted">Due</span>
                                <strong>$100</strong>
                            </td> -->
                            <td>
                            </td>
                            <td>
                            </td>
                            <td class="text-style-right">
                                <span class="text-muted span-payment">{{'SubTotal' | translate}}</span>
                            </td>
                            <td class="text-style-right" [colSpan]="2">
                                <!-- <span class="text-muted"></span> -->
                                <strong>{{order?.subTotal | currency : settingService.getCurrencySymbol()}}</strong>
                            </td>
                        </tr>
                        <tr class="payment-order-details text-style-right">
                            <th class="d-flex td-order-details" [colSpan]="4">
                                <!-- <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"><img src="/assets/img/Fa-Team-Fontawesome-FontAwesome-Money-Bill.512.png" width="20" alt=""> Payment Method: </span>
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"> {{order?.paymentTypeName}}</span> -->
                            </th>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                                <span class="text-muted span-payment">{{'Delivery'}}</span>
                            </td>
                            <td class="td-order-details" [colSpan]="2">
                                <!-- <span class="text-muted"></span> -->
                                <strong>{{order?.deliverFees | currency : settingService.getCurrencySymbol()}}</strong>
                            </td>
                        </tr>
                        <tr class="payment-order-details text-style-right">
                            <th class="d-flex td-order-details" [colSpan]="4">
                                <!-- <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"><img src="/assets/img/Fa-Team-Fontawesome-FontAwesome-Money-Bill.512.png" width="20" alt=""> Payment Method: </span>
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"> {{order?.paymentTypeName}}</span> -->
                            </th>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                                <span class="text-muted font-weight-bold">{{'Total' | translate}}</span>
                            </td>
                            <td class="td-order-details" [colSpan]="2">
                                <!-- <span class="text-muted"></span> -->
                                <strong>{{(order?.total) | currency :
                                    settingService.getCurrencySymbol()}}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <a *ngIf="!order?.isPaid && order?.orderStatus != 5 && order?.orderStatus != 4 && order?.orderStatus != 0"
            href="javascript:void(0)" data-toggle="modal" data-target="#paymentMethodsModal">
            <div class="mt-3 checkout-card2">
                <div class="d-flex">
                    <div class="p-2">
                        <span class="fa fa-credit-card"></span>
                    </div>
                    <div class="p-2 d-flex flex-column">
                        <h5>{{'Pay With' | translate}}</h5>
                        <span>{{paymentMethodText | translate}}</span>
                    </div>
                    <div [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'" class="p-2">
                        <span class="text-danger fa fa-exclamation-triangle"></span>
                    </div>
                </div>
            </div>
        </a>
        <div class="form-group my-2" *ngIf="paymentNumber != 0">
            <button type="button" (click)="pay()"
                class="btn-first green-btn text-custom-white full-width fw-500">{{'Confirm payment'|
                translate}}</button>
        </div>
    </div>
    <div *ngIf="addReviewForm" class="comment-form">
        <div class="section-header-left">
            <h3 class="text-light-black header-title">{{'Leave a Reply' | translate}}</h3>
        </div>
        <form #addReview [formGroup]="addReviewForm" (ngSubmit)="createReview(addReviewForm.value,addReview)">
            <div class="row">
                <div class="col-md-12">
                    <p-rating [(ngModel)]="rate" [ngModelOptions]="{standalone: true}"></p-rating>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="text-light-black fw-600">Your Comment</label>
                        <textarea formControlName="content" class="form-control form-control-submit" name="#" rows="6"
                            placeholder="Your Comment"></textarea>
                    </div>
                    <button type="submit" class="btn-second btn-submit full-width">Send</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="paymentMethodsModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-left">{{'Payment methods' | translate}}</h4>
                <button type="button" class="close text-right" data-dismiss="modal"><span
                        aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            </div>
            <div class="modal-body">
                <!-- <ng-container *ngFor="let method of getPaymentMethodKeys(); let i = index">
                    <div *ngIf="paymentMethods[method] != 2" (click)="toggleModal(paymentMethods[method])"
                    class="d-flex mb-2 checkout-card2">
                    <div class="p-2">
                        <span class="fa fa-credit-card"></span>
                    </div>
                    <div class="p-2 d-flex flex-column">
                        <h5>{{ method | translate }}</h5>
                    </div>
                    <div *ngIf="paymentNumber === paymentMethods[method]" class="p-2 ml-auto">
                        <span class="text-danger fa fa-check"></span>
                    </div>
                </div>
                </ng-container> -->
                <ng-container *ngFor="let method of getPaymentMethodKeys(); let i = index">
                    <ng-container *ngIf="isApple">
                        <div (click)="toggleModal(paymentMethodIcons[method].numericValue,paymentMethodIcons[method].text)"
                            class="d-flex mb-2 checkout-card2">
                            <div class="p-2">
                                <img class="payment-image" [src]="paymentMethodIcons[method].iconClass"
                                    alt="{{ method }} Image">
                            </div>
                            <div class="p-2 d-flex flex-column">
                                <h5>{{ method | translate }}</h5>
                            </div>
                            <div *ngIf="paymentNumber === paymentMethodIcons[method].numericValue" class="p-2 ml-auto">
                                <span class="text-danger fa fa-check"></span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isApple">
                        <div *ngIf="paymentMethodIcons[method].numericValue != 2"
                            (click)="toggleModal(paymentMethodIcons[method].numericValue,paymentMethodIcons[method].text)"
                            class="d-flex mb-2 checkout-card2">
                            <div class="p-2">
                                <img class="payment-image" width="70" [src]="paymentMethodIcons[method].iconClass"
                                    alt="{{ method }} Image">
                            </div>
                            <div class="p-2 d-flex flex-column">
                                <h5>{{ method | translate }}</h5>
                            </div>
                            <div *ngIf="paymentNumber === paymentMethodIcons[method].numericValue" class="p-2 ml-auto">
                                <span class="text-danger fa fa-check"></span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>


                <!-- <div (click)="toggleModal(0)" class="d-flex mb-2 checkout-card2">
                    <div class="p-2">
                        <span class="fa fa-credit-card"></span>
                    </div>
                    <div class="p-2 d-flex flex-column">
                        <h5>{{'Cache' | translate}}</h5>
                    </div>
                    <div *ngIf="paymentNumber == 0" class="p-2 ml-auto">
                        <span class="text-danger fa fa-check"></span>
                    </div>
                </div> -->
                <!-- <div (click)="toggleModal(1)" class="d-flex mb-2 checkout-card2">
                    <div class="p-2">
                        <span class="fa fa-credit-card"></span>
                    </div>
                    <div class="p-2">
                        <h5>Cache</h5>
                    </div>
                    <div *ngIf="paymentNumber == 1" class="p-2 ml-auto">
                        <span class="text-danger fa fa-check"></span>
                    </div>
                </div> -->
                <hr>
                <!-- <p class="fs-12">You will be redirected to the secure (SSL) pages of GiroPay. Here you will be able to
                    complete the GiroPay payment for your order.
                </p> -->
                <button (click)="closeModal()" type="button" class="btn done-btn pt-1 pb-2 w-100">
                    <span>{{'Done' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<app-footer2></app-footer2>