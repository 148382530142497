import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filter } from 'src/app/models-classes/common.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CatalogService } from 'src/app/services/catalogs/catalog.service';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';

@Component({
  selector: 'app-resturants-data',
  templateUrl: './resturants-data.component.html',
  styleUrls: ['./resturants-data.component.css']
})
export class ResturantsDataComponent implements OnInit {
  constructor(
    private catalogService: CatalogService,
    private activatedRoute: ActivatedRoute,
    private favoriteService: FavoriteService,
    public authService: AuthService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    // await this.getCompaniesOrProducts(this.pageIndex, this.pageSize);
  }
  // public async getCompaniesOrProducts(
  //   pageIndex,
  //   pageSize,
  //   type = 1,
  //   search = '',
  //   rate = 0,
  //   minAmmount = 0,
  //   openNow = 0,
  //   freeDelivery = 0,
  //   sort_by = 0,
  //   selectedCategories = '',
  //   check_scroll = false
  // ) {
  //   this.companies = [];
  //   this.products = [];
  //   try {
  //     this.filter_model = {
  //       pageNumber: pageIndex,
  //       pageSize: pageSize,
  //       shopTypeId: this.id,
  //       myLocationLat: this.lat,
  //       myLocationLng: this.long
  //     };
  //     if (search != '') this.filter_model.search = search;
  //     if (selectedCategories != '')
  //       this.filter_model.selectedCategories = selectedCategories;
  //     if (rate != 0) this.filter_model.rate = rate;
  //     if (minAmmount != 0) this.filter_model.minOrderAmtLessThan = minAmmount;
  //     if (sort_by != 0) this.filter_model.sortBy = sort_by;
  //     if (openNow != 0) this.filter_model.onlyOpenedNow = true;
  //     if (freeDelivery != 0) this.filter_model.onlyFreeDelivery = true;

  //     if (type == 1) {
  //       const response: any = await this.catalogService
  //         .companiesList(this.filter_model, this.countryId)
  //         .toPromise();
  //       this.fullCount = response.data.recordsCount;
  //       if (check_scroll) {
  //         // Filter out items that already exist in this.companies
  //         const newCompanies = response.data.result.filter((newCompany) => {
  //           return !this.companies.some(
  //             (existingCompany) => existingCompany.id === newCompany.id
  //           );
  //         });
  //         // Add the new companies to this.companies
  //         this.companies = [...this.companies, ...newCompanies];
  //         if (response.data.recordsCount == 0) this.pageIndex = 1;
  //       } else {
  //         // console.log('tarikk')
  //         // console.log(this.companies);
  //         console.log(search)
  //         this.companies = response.data.result;
  //         // console.log(this.companies);
  //       }
  //       this.totalCount = response.totalCount;
  //     } else {
  //       const response: any = await this.catalogService
  //         .productList(this.filter_model)
  //         .toPromise();
  //       this.fullCount = response.data.recordsCount;
  //       if (check_scroll) {
  //         // Filter out items that already exist in this.products
  //         const newProducts = response.data.result.filter((newProduct) => {
  //           return !this.products.some(
  //             (existingProduct) => existingProduct.id === newProduct.id
  //           );
  //         });
  //         // Add the new products to this.products
  //         this.products = [...this.products, ...newProducts];
  //         if (response.data.recordsCount == 0) this.pageIndex = 1;
  //       } else this.products = response.data.result;
  //       this.totalCount = response.totalCount;
  //     }
  //     if (this.totalCount > 0) this.hasData = true;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  countryId = +localStorage.getItem('countryId');
  nav_number = 1;
  openNow = false;
  freeDelivery = false;
  filter_input = '';
  selectedCategories = '';
  public fullCount = 0;
  public totalCount = 0;
  public minAmmount = 1;
  public pageIndex = 1;
  public pageSize = 10;
  filter_model: Filter;
  check_filter = false;
  lat = +localStorage.getItem('lat');
  long = +localStorage.getItem('lng');
  id = 0;
  clear_one = 0;
  currentRate = 0;
  public companies: any[] = [];
  public products: any[] = [];
  onRatingChange(num: number) {
    this.pageIndex = 1;
    this.clear_one = 0;
    this.companies = [];
    this.products = [];
    this.currentRate = num;
    // if ((this.nav_number = 1)) this.globalFilter();
    // else this.globalFilter(2);
  }
  getGlobalCategoryIds(data) {
    this.pageIndex = 1;
    this.selectedCategories = data.join(',');
    // console.log(data);
    // console.log(typeof(this.selectedCategories));
    console.log(data);
    console.log(this.selectedCategories);
    // this.globalFilter();
  }
  handleLatLng(event: { lat: number, lng: number }) {
    this.lat = event.lat;
    this.long = event.lng;
    // this.globalFilter(1);
  }
  changeFeature(event, num) {
    this.clear_one = 0;
    if (num == 1) this.openNow = event.target.checked;
    else this.freeDelivery = event.target.checked;
    // this.globalFilter();
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
    this.countryId = +id;
    // this.globalFilter(1);
  }
  getMinimumOrderAmmount(val) {
    this.clear_one = 0;
    this.minAmmount = val;
    this.pageIndex = 1;
    // this.globalFilter();
    // this.companies = [];
    // if (event.target.value == 1) {
    //   this.getCompanies(this.pageIndex, this.pageSize, this.filter_input, this.currentRate, 0)
    // }
    // else if(event.target.value == 2){
    //   this.getCompanies(this.pageIndex, this.pageSize, this.filter_input, this.currentRate, 60)
    // }
    // else{
    // }
    // console.log(event.target.value);
  }
  clear() {
    window.scrollTo({ top: 0 });
    this.check_filter = false;
    this.freeDelivery = false;
    this.openNow = false;
    this.filter_input = '';
    this.selectedCategories = '';
    this.pageIndex = 1;
    this.currentRate = 0;
    // this.sort_by = 0;
    if (this.clear_one == 0) {
      this.companies = [];
      this.products = [];
      // this.globalFilter();
    }
    this.clear_one++;
    // this.childComponent.receiveDataFromParent();
  }
}
