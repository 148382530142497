import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Address, Image } from 'src/app/models-classes/address/address.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
import { ConvertFileToBase64 } from 'src/app/shared/constants/file-process.model';
import { GetRequiredUserInfo } from 'src/app/shared/constants/global-variable.model';
import { CompressImageService } from 'src/app/shared/file-compressor/compress-image.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
declare var $: any;

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css'],
  encapsulation: ViewEncapsulation.None, //set image of body
})
export class ProfileInfoComponent implements OnInit {
  walletBalance = +localStorage.getItem('walletBalance') || 0
  image: Image;
  visible = false;
  current_lang = localStorage.getItem('current_lang') || 'en';
  x = true;
  profileImage = '';
  imageUrl: any;
  myAddresses: any[];
  myWallets: any[];
  lastAddresses: any[];
  myReviews: any[];
  countryId = 0;
  cityId = 0;
  countries: any = [];
  cities: any = [];
  infoForm: UntypedFormGroup;
  customerAddressAddForm: UntypedFormGroup;
  changePassForm: UntypedFormGroup;
  customerAddressForm: UntypedFormGroup;
  submitted = false;
  submitted1 = false;
  current_user;
  addressId: number;
  address: Address;
  submittedAddress = false;
  isWorking = false;
  constructor(
    private fb: UntypedFormBuilder,
    private compressImage: CompressImageService,
    private _toast: ToastrService,
    private cd: ChangeDetectorRef,
    private translateService:TranslateService,
    private _authService: AuthService,
    private modalService: NgbModal,
    private lookupsService: LookupsService,
    public authService: AuthService
  ) {}
  fullName: string;
  get fA() {
    return this.customerAddressForm.controls;
  }
  get fB() {
    return this.customerAddressAddForm.controls;
  }
  get f() {
    return this.infoForm.controls;
  }
  get f1() {
    return this.changePassForm.controls;
  }
  get passwordValid() {
    return this.changePassForm.controls['password'].errors === null;
  }

  get requiredValid() {
    return !this.changePassForm.controls['password'].hasError('required');
  }

  get minLengthValid() {
    return !this.changePassForm.controls['password'].hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.changePassForm.controls['password'].hasError('requiresDigit');
  }

  get requiresUppercaseValid() {
    return !this.changePassForm.controls['password'].hasError(
      'requiresUppercase'
    );
  }

  get requiresLowercaseValid() {
    return !this.changePassForm.controls['password'].hasError(
      'requiresLowercase'
    );
  }

  get requiresSpecialCharsValid() {
    return !this.changePassForm.controls['password'].hasError(
      'requiresSpecialChars'
    );
  }
  returnIcon(check: boolean) {
    if (check) return '<i class="fa fa-check-circle"></i>';
    else return '<i class="fa fa-times-circle"></i>';
  }
  getCustomerInfo() {
    this._authService.customerInfo().subscribe(
      (res) => {
        console.log(res)
        if (res.data.fullName)
          this.infoForm = this.fb.group({
            fullName: [res.data.fullName, Validators.required],
          });
        else
          this.infoForm = this.fb.group({
            fullName: ['', Validators.required],
          });
        this.fullName = res.data.fullName;
        this.imageUrl =
          res.data.image ??
          'https://bootdey.com/img/Content/avatar/avatar6.png';
      },
      (err) => {
        this.infoForm = this.fb.group({
          fullName: ['', Validators.required],
        });
      }
    );
  }
  changeCountry(event) {
    this.countryId = +event.target.value;
    if (this.countryId != 0) {
      this.getCities(this.countryId);
    } else {
      this.customerAddressForm.patchValue({
        cityId: 0,
      });
      this.cityId = 0;
    }
  }
  getCountries() {
    this.lookupsService.getCountries().subscribe((res) => {
      this.countries = res.data;
    });
  }
  getCities(id) {
    this.lookupsService.getCitiesByCountryId(id).subscribe((res) => {
      this.cities = res.data;
    });
  }
  setAddressAddForm() {
    this.getCountries();
    this.customerAddressAddForm = this.fb.group({
      addressName: ['', [Validators.required]],
      countryId: ['', [Validators.required]],
      cityId: [0, [Validators.required]],
      region: ['', [Validators.required]],
      street: ['', [Validators.required]],
      buildingNumber: ['', [Validators.required]],
      apartmentNumber: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      latLng: [''],
      notes: [''],
    });
  }
  setAddressForm() {
    this.customerAddressForm = this.fb.group({
      id: [0, [Validators.required]],
      addressName: ['', [Validators.required]],
      region: ['', [Validators.required]],
      street: ['', [Validators.required]],
      buildingNumber: ['', [Validators.required]],
      apartmentNumber: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      latLng: [''],
      notes: [''],
    });
  }
  open(content: any, id: number, type = 1) {
    this.addressId = id;
    if (id == 0) {
      this.setAddressAddForm();
    } else {
      if (type == 1) {
        this.setAddressForm(); //edit form
        const address = this.myAddresses.find((obj) => {
          return obj.id === id;
        });
        this.customerAddressForm.patchValue(address);
      }
    }
    this.modalService.open(content, {
      centered: true,
      windowClass: 'modal-large-custom',
    });
  }
  ngOnInit(): void {
    this.getCustomerInfo();
    // this.fullName = GetRequiredUserInfo.fullName;
    this.current_user = JSON.parse(localStorage.getItem('current_user'));
    document.body.className = 'image-delivery';
  }
  createAddress(form: any, data: any, formDirective: FormGroupDirective) {
    this.submittedAddress = true;
    if (this.customerAddressAddForm.invalid) {
      return;
    }
    this.address = {
      id: 0,
      addressName: data.addressName,
      apartmentNumber: data.apartmentNumber,
      buildingNumber: data.buildingNumber,
      cityId: data.cityId,
      countryId: data.countryId,
      latLng: data.latLng,
      notes: data.notes,
      phoneNumber: data.phoneNumber,
      region: data.region,
      street: data.street,
      customerId: this.authService.getCustomerId(),
    };
    this._authService.addAddress(this.address).subscribe((res) => {
      if (res.isSuccess) {
        this._toast.success(this.translateService.instant('Saved successfully'));
        this.customerAddressAddForm.reset();
        formDirective.resetForm(); //for reset validation of form
        this.getMyAddresses();
        this.modalService.dismissAll();
      } else this._toast.error(this.translateService.instant('Some thing went wrong'));
    });
    this.isWorking = true;
    this.customerAddressForm.disable();

    setTimeout(() => {
      this.isWorking = false;
      this.customerAddressForm.enable();
    }, 1500);
  }
  // navChanged(event) {
  //   if (event.nextId == 2) this.setChangePassForm();
  // }
  openNav(type) {
    if (type == 2) this.setChangePassForm();
    else if (type == 3) this.getMyAddresses();
    else if (type == 4) this.getMyWallet();
    // else if (type == 4) this.getLastAddresses();
  }
  getLastAddresses() {
    this._authService.getAddresses().subscribe((res) => {
      this.lastAddresses = res.data;
    });
  }
  getMyWallet() {
    this._authService.getMyWallet().subscribe((res) => {
      this.myWallets = res.data;
    });
  }
  getMyAddresses() {
    this._authService.getAddresses().subscribe((res) => {
      this.myAddresses = res.data;
    });
  }
  getMyReviews() {
    this._authService.myReviews().subscribe((res) => {
      this.myReviews = res.data;
    });
  }
  setChangePassForm() {
    this.changePassForm = this.fb.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[$@^!%*?&])'), {
            requiresSpecialChars: true,
          }),
        ]),
      ],
      oldPassword: ['', [Validators.required]],
    });
  }
  ngOnDestroy() {
    document.body.className = '';
  }
  update(form: any, data: any, formDirective: FormGroupDirective) {
    this.submitted = true;
    if (!this.infoForm.valid) return;
    this._authService.updateFullName(data.fullName).subscribe((res) => {
      if (res.isSuccess) {
        this.current_user.fullName = data.fullName;
        localStorage.setItem('current_user', JSON.stringify(this.current_user));
        this._toast.success(
          this.translateService.instant('The full name has been updated successfully'),
          'Success',
          {
            positionClass: 'toast-top-left',
            progressBar: true,
          }
        );
        this._authService.serUserName(data.fullName);
        this.fullName = data.fullName;
      } else
        this._toast.success(this.translateService.instant('Some thing went wrong'), this.translateService.instant('Error'), {
          positionClass: 'toast-top-left',
          progressBar: true,
        });
    });
  }
  updateAddress(form: any, data: any, formDirective: FormGroupDirective) {
    this.submitted = true;
    if (this.customerAddressForm.invalid) {
      console.log(this.customerAddressForm)
      console.log(data)
      return;
    }
    this.address = {
      id: this.addressId,
      addressName: data.addressName,
      apartmentNumber: data.apartmentNumber,
      buildingNumber: data.buildingNumber,
      latLng: data.latLng,
      notes: data.notes,
      phoneNumber: data.phoneNumber,
      region: data.region,
      street: data.street,
      customerId: this.authService.getCustomerId(),
    };
    this._authService.editAddress(this.address).subscribe((res) => {
      if (res.isSuccess) {
        this._toast.success(this.translateService.instant('Success'),'', {
          positionClass: 'toast-top-left',
          progressBar: true,
        });
        this.modalService.dismissAll();
        this.getMyAddresses();
      } else this._toast.error(this.translateService.instant('Some thing went wrong'));
    });
    this.isWorking = true;
    this.customerAddressForm.disable();

    setTimeout(() => {
      this.isWorking = false;
      this.customerAddressForm.enable();
    }, 1500);
  }
  deleteAddress() {
    this._authService.deleteAddress(this.addressId).subscribe((res) => {
      if (res.isSuccess) {
        this._toast.success(this.translateService.instant('Success'),'', {
          positionClass: 'toast-top-left',
          progressBar: true,
        });
        this.modalService.dismissAll();
        this.getMyAddresses();
      }
    });
  }
  updatePassword(form: any, data: any, formDirective: FormGroupDirective) {
    this.submitted1 = true;
    if (!this.changePassForm.valid) return;
    this._authService
      .changePassword(data.oldPassword, data.password)
      .subscribe((res) => {
        if (res.isSuccess) {
          this._toast.success(
            this.translateService.instant('The password has been updated successfully'),
            this.translateService.instant('Success'),
            {
              positionClass: 'toast-top-left',
              progressBar: true,
            }
          );
          this.fullName = data.fullName;
        } else
          this._toast.error(this.translateService.instant('Incorrect password.'));
      });
  }
  uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      this.compressImage
        .compress(file)
        .pipe(take(1))
        .subscribe((compressedImage) => {
          // ConvertFileToBase64(compressedImage).then((result) => { this.base64File = result; }, (error) => { })
          ConvertFileToBase64(compressedImage).then(
            (result) => {
              this.profileImage = result;
              this.saveImage(this.profileImage);
            },
            (error) => {}
          );
        });
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  toggle(num = 1) {
    if (num != 1) {
      if ($('#eye1').hasClass('fa-eye-slash')) {
        $('#eye1').removeClass('fa-eye-slash');

        $('#eye1').addClass('fa-eye');

        $('#password1').attr('type', 'text');
      } else {
        $('#eye1').removeClass('fa-eye');

        $('#eye1').addClass('fa-eye-slash');

        $('#password1').attr('type', 'password');
      }
    } else {
      if ($('#eye').hasClass('fa-eye-slash')) {
        $('#eye').removeClass('fa-eye-slash');

        $('#eye').addClass('fa-eye');

        $('#password').attr('type', 'text');
      } else {
        $('#eye').removeClass('fa-eye');

        $('#eye').addClass('fa-eye-slash');

        $('#password').attr('type', 'password');
      }
    }
  }
  saveImage(image) {
    if (!image) {
      this._toast.error(this.translateService.instant('Please enter image'), this.translateService.instant('Error'), {
        positionClass: 'toast-top-left',
        progressBar: true,
      });
      return;
    }
    this.image = {
      image: image,
    };
    this._authService.updateProfileImage(this.image).subscribe((res) => {
      
      if (res.isSuccess) {
        this._toast.success(
          this.translateService.instant('The image has been updated successfully'),
          this.translateService.instant('Success'),
          {
            positionClass: 'toast-top-left',
            progressBar: true,
          }
        );
        this.current_user.image = res.data;
        localStorage.setItem('current_user', JSON.stringify(this.current_user));
      } else
        this._toast.error(this.translateService.instant('Some thing went wrong'), this.translateService.instant('Error'), {
          positionClass: 'toast-top-left',
          progressBar: true,
        });
    });
  }
}
