import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesComponent } from 'src/app/layouts/categories/categories.component';
import { Filter } from 'src/app/models-classes/common.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CatalogService } from 'src/app/services/catalogs/catalog.service';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
import { SettingsService } from 'src/app/services/settings.service';
declare let $:any;
@Component({
  selector: 'app-resturants',
  templateUrl: './resturants.component.html',
  styleUrls: ['./resturants.component.css'],
  // encapsulation: ViewEncapsulation.None, //change style for child in parent
})
export class ResturantsComponent implements OnInit {
  @ViewChild(CategoriesComponent) childComponent: CategoriesComponent;
  current_lang = localStorage.getItem('current_lang') || 'en';
  @ViewChild('fixedElement') fixedElement!: ElementRef;
  shopTypeName = localStorage.getItem('shopTypeName') || '';
  lat = +localStorage.getItem('lat');
  long = +localStorage.getItem('lng');
  sort_by = 0;
  nav_number = 1;
  openNow = false;
  freeDelivery = false;
  filter_input = '';
  selectedCategories = '';
  public fullCount = 0;
  public totalCount = 0;
  public minAmmount = 0;
  public pageIndex = 1;
  public pageSize = 20;
  filter_model: Filter;
  check_filter = false;
  id = 0;
  @Output() ClearIds = new EventEmitter();
  countryId = +localStorage.getItem('countryId');
  currentRate = 0;
  public companies: any[] = [];
  public products: any[] = [];
  hasData = false;
  loadingData = false; // Add a loading indicator variable
  sidebar: HTMLElement | null = null;
  footer: HTMLElement | null = null;
  sidebarHeight: number = 0;
  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   this.onScroll();
  // }

  // onScroll() {
  //   if (this.sidebar && this.footer) {
  //     const footerRect = this.footer.getBoundingClientRect();
  //     const sidebarRect = this.sidebar.getBoundingClientRect();
  //     const windowHeight = window.innerHeight;

  //     if (footerRect.top <= windowHeight + sidebarRect.height) {
  //       this.sidebar.style.position = 'absolute';
  //       this.sidebar.style.top = 'initial';
  //       this.sidebar.style.bottom = `${footerRect.top - windowHeight}px`;
  //     } else {
  //       this.sidebar.style.position = 'sticky';
  //       this.sidebar.style.top = '0';
  //       this.sidebar.style.bottom = 'initial';
  //     }
  //   }
  // }
  // @HostListener("window:scroll", [])
  // onScroll(): void {
  //   if (this.bottomReached()) {
  //     // this.elements = [...this.elements, this.count++];
  //   }
  // }
  // @ViewChild('uiElement', { static: false })
  // public uiElement: ElementRef;
  // bottomReached(): boolean {
  //   // console.log(window.scrollY);
  //   return window.innerHeight + window.scrollY >= document.body.offsetHeight;
  // }
  // @HostListener('window:scroll', ['$event']) public async onClick(event) {
  //   // let box = document.querySelector<HTMLElement>('.product-list-view"');
  //   // let height = box.offsetHeight;

  //   var fixedColumn = document.querySelector<HTMLElement>('.fixed');
  //   var footer = document.querySelector<HTMLElement>('.footer-top');

  //   var fixedColumnHeight = fixedColumn.offsetHeight;
  //   var footerOffset = footer.offsetTop;
  //   var scrollTop = window.scrollY || window.pageYOffset;

  //   if (scrollTop + window.innerHeight > footerOffset) {
  //     fixedColumn.style.transform = 'translateY(-' + (scrollTop + window.innerHeight - footerOffset) + 'px)';
  //   } else {
  //     fixedColumn.style.transform = 'none';
  //   }

  // }
  // @HostListener('window:scroll', []) public async onScrollLoadData(){
  //   if( this.companies.length !== this.totalCount){
  //     this.pageIndex +=1;
  //     await this.getCompanies(this.pageIndex, this.pageSize);
  //   }
  // }
  // ngAfterViewInit() {
  //   window.addEventListener('scroll', this.handleScroll.bind(this));
  // }
  // handleScroll() {
  //   const fixedElement = this.fixedElement.nativeElement;
  //   const footerElement = document.querySelector('footer');
  //   const fixedElementHeight = fixedElement.offsetHeight;

  //   if (footerElement) {
  //     const footerPosition = footerElement.getBoundingClientRect().top;

  //     if (footerPosition - fixedElementHeight < 0) {
  //       fixedElement.style.position = 'absolute';
  //       fixedElement.style.top = `${footerPosition - fixedElementHeight}px`;
  //     } else {
  //       fixedElement.style.position = 'fixed';
  //       fixedElement.style.top = '120px'; // تعديل القيمة حسب الارتفاع المطلوب
  //     }
  //   }
  // }
  onPageChange(event: any) {
    this.pageIndex = +event
    this.globalFilter(this.nav_number, this.filter_input);
    // Handle page change event here
    // You can update any necessary data or perform any action here
  }
  reachedFooter = false;
  clear_one = 0;
  // @HostListener('window:scroll', []) public async onDivScroll(type = 1) {
  //   const nativeElement = this.uiElement.nativeElement;
  //   //fixed left filter sidebar
  //   // var fixedColumn = document.querySelector<HTMLElement>('.fixed');
  //   // var footer = document.querySelector<HTMLElement>('.footer-top');

  //   // var fixedColumnHeight = fixedColumn.offsetHeight;
  //   // var footerOffset = footer.offsetTop;
  //   // var scrollTop = window.scrollY || window.pageYOffset;

  //   // if (scrollTop + window.innerHeight > footerOffset) {
  //   //   fixedColumn.style.transform = 'translateY(-' + (scrollTop + window.innerHeight - footerOffset) + 'px)';
  //   // } else {
  //   //   fixedColumn.style.transform = 'none';
  //   // }
  //   //fixed left filter sidebar

  //   // const elementOffsetTop = document.getElementById('test').offsetTop;
  //   // const scrollPosition =
  //   //   window.pageYOffset ||
  //   //   document.documentElement.scrollTop ||
  //   //   document.body.scrollTop ||
  //   //   0;

  //   // if (scrollPosition >= elementOffsetTop) {
  //   //   // this.reachedElement = true;
  //   //   console.log('1');
  //   // } else {
  //   //   // this.reachedElement = false;

  //   //   console.log('2');
  //   // }

  //   // const element = document.getElementById('test');
  //   // const elementRect = element.getBoundingClientRect();
  //   // const scrollPosition =
  //   //   window.pageYOffset ||
  //   //   document.documentElement.scrollTop ||
  //   //   document.body.scrollTop ||
  //   //   0;
  //   // // console.log(scrollPosition)
  //   // if (scrollPosition > element.offsetTop) {
  //   //   this.reachedFooter = true;
  //   // } else {
  //   //   this.reachedFooter = false;
  //   // }
  //   if (this.bottomReached() && !this.loadingData) {
  //     this.loadingData = true;
  //     if (type == 1) {
  //       // const container = document.querySelector<HTMLElement>('.ng-container');
  //       // const containerHeight = container.clientHeight;
  //       // const contentHeight = container.scrollHeight;
  //       // const scrollOffset = container.scrollTop;
  //       // if (scrollOffset + containerHeight >= contentHeight) {
  //       // }
  //       await this.getCompaniesOrProducts(
  //         this.pageIndex,
  //         this.pageSize,
  //         1,
  //         this.filter_input,
  //         this.currentRate,
  //         0,
  //         this.openNow ? 1 : 0,
  //         this.freeDelivery ? 1 : 0,
  //         +this.sort_by,
  //         this.selectedCategories,
  //         true
  //       );
  //       this.pageIndex += 1;
  //       // nativeElement.scrollTop=0;
  //       // this.pageIndex++;
  //       // await this.getCompaniesOrProducts(
  //       //   this.pageIndex,
  //       //   this.pageSize,
  //       //   1,
  //       //   this.filter_input,
  //       //   this.currentRate,
  //       //   0,
  //       //   this.openNow ? 1 : 0,
  //       //   this.freeDelivery ? 1 : 0,
  //       //   +this.sort_by
  //       // );
  //     } else {
  //       // const container = document.querySelector<HTMLElement>('.ng-container2');
  //       // const containerHeight = container.clientHeight;
  //       // const contentHeight = container.scrollHeight;
  //       // const scrollOffset = container.scrollTop;
  //       // if (scrollOffset + containerHeight >= contentHeight) {
  //       // }

  //       await this.getCompaniesOrProducts(
  //         this.pageIndex,
  //         this.pageSize,
  //         2,
  //         this.filter_input,
  //         this.currentRate,
  //         0,
  //         this.openNow ? 1 : 0,
  //         this.freeDelivery ? 1 : 0,
  //         +this.sort_by,
  //         this.selectedCategories,
  //         true
  //       );
  //       this.pageIndex += 1;
  //       // nativeElement.scrollTop=0;

  //       // this.pageIndex++;
  //       // await this.getCompaniesOrProducts(
  //       //   this.pageIndex,
  //       //   this.pageSize,
  //       //   2,
  //       //   this.filter_input,
  //       //   this.currentRate,
  //       //   0,
  //       //   this.openNow ? 1 : 0,
  //       //   this.freeDelivery ? 1 : 0,
  //       //   +this.sort_by
  //       // );
  //     }
  //     this.loadingData = false;
  //   }
  // }
  changeOrder(event) {
    this.sort_by = +event.target.value;
    if (this.nav_number == 1) this.globalFilter(1, this.filter_input);
    else this.globalFilter(2, this.filter_input);
  }
  // @HostListener('window:scroll', []) public async onDivProductScroll() {
  //   //fixed left filter sidebar
  //   var fixedColumn = document.querySelector<HTMLElement>('.fixed');
  //   var footer = document.querySelector<HTMLElement>('.footer-top');

  //   var fixedColumnHeight = fixedColumn.offsetHeight;
  //   var footerOffset = footer.offsetTop;
  //   var scrollTop = window.scrollY || window.pageYOffset;

  //   if (scrollTop + window.innerHeight > footerOffset) {
  //     fixedColumn.style.transform = 'translateY(-' + (scrollTop + window.innerHeight - footerOffset) + 'px)';
  //   } else {
  //     fixedColumn.style.transform = 'none';
  //   }
  //   //fixed left filter sidebar
  //   const container = document.querySelector<HTMLElement>('.ng-container2');
  //   const containerHeight = container.clientHeight;
  //   const contentHeight = container.scrollHeight;
  //   const scrollOffset = container.scrollTop;

  //   if (scrollOffset + containerHeight >= contentHeight) {
  //     this.pageIndex++;
  //     if (this.filter_input || this.nav_number == 2)
  //       await this.getCompaniesOrProducts(this.pageIndex, this.pageSize, 2);
  //   }
  // }
  constructor(
    private catalogService: CatalogService,
    private activatedRoute: ActivatedRoute,
    private favoriteService: FavoriteService,
    public authService: AuthService,
    private router: Router,
    public settingService: SettingsService
  ) {}
  getarr(length: number) {
    return new Array(Math.round(length));
  }
  CalculateHeight() {
    return window.innerHeight + 15;
  }
  public async ngOnInit(): Promise<void> {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.getCompaniesOrProducts(this.pageIndex, this.pageSize);
    // this.pageIndex += 1;
    // this.sidebar = document.getElementById('sticky-sidebar');
    // this.footer = document.getElementById('footer');
    // this.onScroll();
  }
  public getCompaniesOrProducts(
    pageIndex,
    pageSize,
    type = 1,
    search = '',
    rate = 0,
    minAmmount = 0,
    openNow = 0,
    freeDelivery = 0,
    sort_by = 0,
    selectedCategories = '',
    check_scroll = false
  ) {
    this.companies = [];
    this.products = [];
    try {
      this.filter_model = {
        pageNumber: pageIndex,
        pageSize: pageSize,
        shopTypeId: this.id,
        myLocationLat: this.lat,
        myLocationLng: this.long,
      };
      if (search != '') this.filter_model.search = search;
      if (selectedCategories != '')
        this.filter_model.selectedCategories = selectedCategories;
      if (rate != 0) this.filter_model.rate = rate;
      if (minAmmount != 0) this.filter_model.minOrderAmtLessThan = minAmmount;
      if (sort_by != 0) this.filter_model.sortBy = sort_by;
      if (openNow != 0) this.filter_model.onlyOpenedNow = true;
      if (freeDelivery != 0) this.filter_model.onlyFreeDelivery = true;
      console.log(this.filter_model);
      if (type == 1) {
        // const response: any = await this.catalogService
        //   .companiesList(this.filter_model, this.countryId)
        //   .toPromise();
        this.catalogService
          .companiesList(this.filter_model, this.countryId)
          .subscribe((response) => {
            console.log(response);
            this.fullCount = response.data.recordsCount;
            this.companies = response.data.result;
          });
        // if (check_scroll) {
        //   // Filter out items that already exist in this.companies
        //   const newCompanies = response.data.result.filter((newCompany) => {
        //     return !this.companies.some(
        //       (existingCompany) => existingCompany.id === newCompany.id
        //     );
        //   });
        //   // Add the new companies to this.companies
        //   this.companies = [...this.companies, ...newCompanies];
        //   if (response.data.recordsCount == 0) this.pageIndex = 1;
        // }
        //  else {
        //   // console.log('tarikk')
        //   // console.log(this.companies);
        //   console.log(search)
        //   this.companies = response.data.result;
        //   // console.log(this.companies);
        // }
        // this.totalCount = response.totalCount;
      } else {
        this.catalogService
          .productList(this.filter_model)
          .subscribe((response) => {
            console.log(response);
            this.fullCount = response.data.recordsCount;
            this.products = response.data.result;
          });
        // const response: any = this.catalogService
        //   .productList(this.filter_model)
        //   .toPromise();
        // this.fullCount = response.data.recordsCount;
        // if (check_scroll) {
        //   // Filter out items that already exist in this.products
        //   const newProducts = response.data.result.filter((newProduct) => {
        //     return !this.products.some(
        //       (existingProduct) => existingProduct.id === newProduct.id
        //     );
        //   });
        //   // Add the new products to this.products
        //   this.products = [...this.products, ...newProducts];
        //   if (response.data.recordsCount == 0) this.pageIndex = 1;
        // } else this.products = response.data.result;
        // this.totalCount = response.totalCount;
      }
      // if (this.totalCount > 0) this.hasData = true;
    } catch (error) {
      console.log(error);
    }
  }
  currencySymbol = (this.current_lang == 'en' ? localStorage.getItem('currencySymbolEn') : localStorage.getItem('currencySymbolAr')) || 'KWD'
  // public async getProducts(pageIndex, pageSize, search = '', rate = 0, minAmmount = 0, openNow = 0, freeDelivery = 0) {
  //   try {
  //     this.filter_model = {
  //       pageNumber: pageIndex,
  //       pageSize: pageSize,
  //       shopTypeId: this.id,
  //     }
  //     if (search != '')
  //       this.filter_model.search = search;
  //     if (rate != 0)
  //       this.filter_model.rate = rate;
  //     if (minAmmount != 0)
  //       this.filter_model.minOrderAmtLessThan = minAmmount;
  //     if (openNow != 0)
  //       this.filter_model.onlyOpenedNow = true;
  //     if (freeDelivery != 0)
  //       this.filter_model.onlyFreeDelivery = true;
  //     console.log(this.filter_model);
  //     const response: any = await this.catalogService.productList(this.filter_model).toPromise();
  //     this.fullCount = response.data.recordsCount;
  //     this.products = [...this.products, ...response.data.result]
  //     this.totalCount = response.totalCount;
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  
  changeFeature(event, num) {
    this.clear_one = 0;
    if (num == 1) this.openNow = event.target.checked;
    else this.freeDelivery = event.target.checked;
    this.globalFilter(this.nav_number, this.filter_input);
  }
  imageSource: string = 'assets/img/svg/013-heart-1.svg';
  toggleFavorite(item: any) {
    item.isFavorite = !item.isFavorite;
  }
  getMinimumOrderAmmount(val) {
    this.clear_one = 0;
    this.minAmmount = val;
    this.pageIndex = 1;
    this.globalFilter(this.nav_number, this.filter_input);
    // this.companies = [];
    // if (event.target.value == 1) {
    //   this.getCompanies(this.pageIndex, this.pageSize, this.filter_input, this.currentRate, 0)
    // }
    // else if(event.target.value == 2){
    //   this.getCompanies(this.pageIndex, this.pageSize, this.filter_input, this.currentRate, 60)
    // }
    // else{
    // }
    // console.log(event.target.value);
  }
  getGlobalCategoryIds(data) {
    this.pageIndex = 1;
    this.selectedCategories = data.join(',');
    // console.log(data);
    // console.log(typeof(this.selectedCategories));
    console.log(data);
    console.log(this.selectedCategories);
    this.globalFilter(this.nav_number, this.filter_input);
  }
  navChanged(val) {
    this.clear_one = 0;
    window.scrollTo({ top: 0 });
    this.nav_number = val;
    this.pageIndex = 1;
    if (val == 1) this.globalFilter(1, this.filter_input);
    else this.globalFilter(2, this.filter_input);
  }
  clear() {
    window.scrollTo({ top: 0 });
    if (this.currentRate == 0 && !this.openNow && !this.freeDelivery && this.minAmmount == 0 && this.selectedCategories.length == 0) {
      return
    }
    this.check_filter = false;
    this.freeDelivery = false;
    this.minAmmount = 0;
    this.openNow = false;
    this.filter_input = '';
    this.selectedCategories = '';
    this.pageIndex = 1;
    this.currentRate = 0;
    this.sort_by = 0;
    $("#showAllRad").prop("checked", true);
    console.log(this.currentRate)
    console.log(this.openNow)
    console.log(this.freeDelivery)
    console.log(this.minAmmount)
    this.clear_one++;
    this.globalFilter();
    this.childComponent.receiveDataFromParent();
  }
  filter(event) {
    console.log(event.target.value);
    this.pageIndex = 1;
    this.companies = [];
    this.products = [];
    this.clear_one = 0;
    // this.clear();
    this.filter_input = event.target.value;
    if (!event.target.value) {
      this.nav_number = 1;
      this.check_filter = false;
    } else this.check_filter = true;
    if (this.nav_number == 1) this.globalFilter(1, this.filter_input);
    else this.globalFilter(2, this.filter_input); //
  }
  returnToCompany(event, productId, num, isFavorite) {
    // console.log();
    // console.log(event.target.tagName);
    // return
    if (
      event.target.tagName != 'IMG' ||
      event.target.classList.contains('cover-image')
    )
      this.router.navigate(['/company'], {
        queryParams: { id: productId, fav: isFavorite },
      });
  }
  addToFavorite(item, type) {
    console.log(item)
    if (type == 1) {
      let index = this.companies.findIndex((x) => x.id == item.id);
      if (index > -1) {
        this.companies[index].isFavorite = !item.isFavorite;
      }
      this.favoriteService
        .addCompanyToFavorites(item.id, item.isFavorite)
        .subscribe((res) => {});
    } else {
      let index = this.products.findIndex((x) => x.id == item.id);
      if (index > -1) {
        this.products[index].isFavorite = !item.isFavorite;
      }
      this.favoriteService
        .addProductToFavorites(item.id, item.isFavorite)
        .subscribe((res) => {});
    }
    // item.isFavorite = !item.isFavorite;
  }
  handleLatLng(event: { lat: number; lng: number }) {
    this.lat = event.lat;
    this.long = event.lng;
    // console.log(event)
    // console.log(this.lat)
    // console.log(this.long)
    // return
    this.globalFilter(1, this.filter_input);
    // Now you can use lat and lng as needed
  }
  getCountry(country){
    this.currencySymbol = this.current_lang == 'en' ? country.currencySymbolEn : country.currencySymbolAr
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
    this.countryId = +id;
    this.globalFilter(1, this.filter_input);
  }
  onRatingChange(num: number) {
    this.pageIndex = 1;
    this.clear_one = 0;
    this.companies = [];
    this.products = [];
    this.currentRate = num;
    if ((this.nav_number = 1)) this.globalFilter(1, this.filter_input);
    else this.globalFilter(2, this.filter_input);
  }
  globalFilter(type = 1, search = '') {
    if (type == 1)
      this.getCompaniesOrProducts(
        this.pageIndex,
        this.pageSize,
        1,
        search,
        this.currentRate,
        this.minAmmount,
        this.openNow ? 1 : 0,
        this.freeDelivery ? 1 : 0,
        +this.sort_by,
        this.selectedCategories
      );
    else
      this.getCompaniesOrProducts(
        this.pageIndex,
        this.pageSize,
        2,
        search,
        this.currentRate,
        this.minAmmount,
        this.openNow ? 1 : 0,
        this.freeDelivery ? 1 : 0,
        +this.sort_by,
        this.selectedCategories
      );
  }
}
