<div [ngClass]="current_lang == 'en' ? 'text-right m-4' : 'text-left m-4'">
  <a data-toggle="modal" data-target="#staticBackdrop" href="javascript:void(0)"
    class="text-light-white fw-500">
    <img style="height: 30px;width: 30px;" [src]="flagImage" class="rounded-circle" alt="userimg">
  </a>
</div>
<div class="inner-wrapper">
    <div class="container-fluid no-padding">
      <div class="row no-gutters overflow-auto">
        <div class="col-md-6">
          <div class="main-banner">
            <img src="/assets/img/logo/logo-white.png" class="img-fluid full-width main-img2" alt="banner">
            <!-- <div class="overlay-2 main-padding">
              <img src="assets/img/logo-2.jpg" class="img-fluid" alt="logo">
            </div>
            <img src="assets/img/banner/burger.png" class="footer-img" alt="footer-img"> -->
          </div>
        </div>
        <div class="col-md-6">
          
          <div class="section-2 user-page main-padding">
            <div class="login-sec">
              <div class="login-box">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value,formDirective)" #formDirective="ngForm">
                  <h4 class="text-light-black fw-600">{{'Sign in with your account' | translate}}</h4>
                  <div class="row">
                    <div class="col-12">
                      <!-- <p class="text-light-black">Have a corporate username? <a routerLink="/add-restaurant">Click here</a>
                      </p> -->
                      <div class="form-group">
                        <label class="text-light-white fs-14">{{'User Name Or Email' | translate}}</label>
                        <input type="text" formControlName="userNameOrEmail" class="form-control form-control-submit" placeholder="Email I'd">
                      </div>
                      <div class="form-group">
                        <label class="text-light-white fs-14">{{'Password' | translate}}</label>
                        <input formControlName="password" type="password" id="password-field" name="#" class="form-control form-control-submit" value="" placeholder="Password">
                        <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                      </div>
                      <div class="form-group checkbox-reset">
                        <label class="custom-checkbox mb-0">
                          <input type="checkbox" formControlName="rememberMe"> <span class="checkmark"></span> {{'Keep me signed in' | translate}}</label> <a routerLink="/reset-password">{{'Reset Your Password' | translate}}</a>
                      </div>
                      <div class="form-group">
                        <button (click)="changeType(0)" type="submit" class="btn-second btn-submit full-width">
                          {{'Sign In' | translate}}</button>
                          <!-- <img src="assets/img/M.png" alt="btn logo">{{'Sign In' | translate}}</button> -->
                      </div>
                      <div class="form-group text-center"> <span>{{'or' | translate}}</span>
                      </div>
                      <div *ngIf="email_confirm" class="form-group text-center mb-1"> <a (click)="open(modaladd)" style="color:#ff0018;font-weight: bold;">{{'Resend Confirmation Code' | translate}}</a>
                      </div>
                      
                      <!-- <div class="form-group">
                        <button (click)="changeType(1)" type="submit" class="btn-second btn-facebook full-width">
                          <img src="assets/img/facebook-logo.svg" alt="btn logo">{{'Continue with Facebook' | translate}}</button>
                      </div> -->
                      <div class="form-group">
                        <asl-google-signin-button type='standart' size='large'></asl-google-signin-button>
                        <!-- <button (click)="changeType(2)" type="submit" class="btn-second btn-google full-width">
                          <img src="assets/img/google-logo.png" alt="btn logo">{{'Continue with Google' | translate}}</button> -->
                      </div>
                      <div class="form-group text-center mb-0"> <a routerLink="/register">{{'Create your account' | translate}}</a>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    
                  </div> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <ng-template #modaladd let-modal>
    <div class="modal-header">
      <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-share bg-primary text-white"></i>
        {{'Activate Your Account' | translate}}</h3>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
      <form *ngIf="confirmEmailForm" [formGroup]="confirmEmailForm"
        (ngSubmit)="confirmMail(confirmEmailForm.value,formDirective)" #formDirective="ngForm">
        
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="text-light-white fs-14">{{'Code' | translate}}</label>
              <input [ngClass]="{ 'is-invalid': f1.code.errors && (f1.code.dirty || submitted1), 'is-valid': f1.code.valid }"
                formControlName="code" type="text" class="form-control form-control-submit" value="" placeholder="Code">
              <div *ngIf="f1.code.touched && f1.code.errors" class="invalid-feedback">
                <div *ngIf="f1.code.errors.required">{{'Code' | translate}} {{'validations.isrequired' | translate}}</div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-second btn-submit full-width">
                <img src="assets/img/M.png" alt="btn logo">{{'Confirm' | translate}}</button>
            </div>
            <div class="form-group text-center"> <span>{{'or' | translate}}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template> -->
  <ng-template #modaladd let-modal>
    <div class="modal-header">
      <h3 class="modal-title has-icon ms-icon-round"><i class="flaticon-share bg-primary text-white"></i>
        {{'Activate Your Account' | translate}}</h3>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
      <h5 *ngIf="activationMethod == ''">{{'Select Activation Type' | translate}}</h5>
      <div class="d-flex" *ngIf="!checkVertification">
        <button [ngClass]="{'selected': activationMethod === 'email'}" (click)="setActivationMethod('email')" type="button" class="btn-second btn-submit mx-2 full-width selected">{{'Email' | translate}}</button>
        <button [ngClass]="{'selected': activationMethod === 'phone'}" (click)="setActivationMethod('phone')" type="button" class="btn-second btn-submit full-width selected">{{'PhoneNumber' | translate}}</button>
      </div>
      <form *ngIf="confirmEmailForm" [formGroup]="confirmEmailForm"
        (ngSubmit)="verifyOtp(confirmEmailForm.value,formDirective)" #formDirective="ngForm">
        <!-- <h4 class="text-light-black fw-600">Activate Your Account</h4> -->
        <div class="row my-2" *ngIf="checkVertification">
          <div class="col-12">
            <div class="form-group">
              <!-- <label class="text-light-white fs-14">{{'Code' | translate}}</label> -->
              <input
                [ngClass]="{ 'is-invalid': f1.code.errors && (f1.code.dirty || submitted1), 'is-valid': f1.code.valid }"
                formControlName="code" type="text" class="form-control form-control-submit" value="" placeholder="Code">
              <div *ngIf="f1.code.touched && f1.code.errors" class="invalid-feedback">
                <div *ngIf="f1.code.errors.required">{{'Code' | translate}} {{'validations.isrequired' | translate}}!</div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-second btn-submit full-width">
                <!-- <img src="assets/img/M.png" alt="btn logo"> -->
                {{'Confirm' | translate}}</button>
            </div>
            <div class="form-group text-center"> <span>{{'or' | translate}}</span>
            </div>
            <div class="form-group text-center mb-0" style="cursor:pointer"> <a (click)="resendOtp()"
                style="color:#ff0018">{{'Resend Code' | translate}}</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-nav modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{'Languages' | translate}}</h5>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div [ngClass]="{'selected-country' : lang.key == current_lang}" (click)="changeLang(lang.key)"
              *ngFor="let lang of langs | keyvalue" class="gem-points country-hover p-2">
              <a href="javascript:void(0)"> <img style="height: 30px;width: 30px;"
                  [src]="lang.key == 'ar' ? 'https://rahaldelivery.blob.core.windows.net/static/kuwait.png' : 'https://th.bing.com/th/id/R.235fc65463d0c1eefa102801ba282e71?rik=rToZIjguCeXCXQ&pid=ImgRaw&r=0'"
                  class="rounded-circle" alt="userimg">
                <span class="span-color">{{lang.value | translate}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Close' | translate}}</button>
      </div>
    </div>
  </div>
</div>