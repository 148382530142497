export declare type DataUrl = string;
export declare enum DOC_ORIENTATION {
  Up = 1,
  Down = 3,
  Right = 6,
  Left = 8,
  UpMirrored = 2,
  DownMirrored = 4,
  LeftMirrored = 5,
  RightMirrored = 7,
  Default = 0,
  NotJpeg = -1,
  NotDefined = -2
}

export interface UploadResponse {
  image: DataUrl;
  orientation: DOC_ORIENTATION;
  fileName: string;
}
export function ConvertFileToBase64(file1): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const file = file1;
    var reader = new FileReader();
    var base64File = '';
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      resolve(e.target.result as string);
      // var img = new Image();
      // // img.src = reader.result;
      //       img.onload = function () {
      //         resolve(e.target.result as string);
      //       };
      //       img.onerror = function () {
      //         resolve('');
      //       };

    }

    reader.onerror = () => { reject(true) }
  });
};
// export function compressFile(imageCompress: NgxImageCompressService) {
//   return imageCompress
//     .uploadFile()
//     .then(({ image, orientation, fileName }: UploadResponse) => {
//       this.imgResultBeforeCompress = image;
//       console.warn('File Name:', fileName);
//       console.warn(
//         `Original: ${image.substring(0, 50)}... (${image.length} characters)`
//       );
//       console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

//       this.imageCompress
//         .compressFile(image, orientation, 50, 50)
//         .then((result: DataUrl) => {
//           this.imgResultAfterCompress = result;
//           console.warn(
//             `Compressed: ${result.substring(0, 50)}... (${
//               result.length
//             } characters)`
//           );
//           console.warn(
//             'Size in bytes is now:',
//             this.imageCompress.byteCount(result)
//           );
//         });
//     });
// }
// export function CompressFile(event, imageCompress: NgxImageCompressService, type = 'image/jpeg'): Promise<File> {
//   return new Promise<File>((resolve, reject) => {
//     const file = event.target.files[0];
//     var reader = new FileReader();
//     //
//     debugger
//     var fileName = file['name'];
//     var image: any;
//     var localCompressedURl: any;
//     var sizeOFCompressedImage: number;
//     var sizeOfOriginalImage: number;
//     //
//     reader.onload = (e) => {
//       var orientation = -1;
//       sizeOfOriginalImage = imageCompress.byteCount(e.target.result) / (1024 * 1024);
//       console.warn('Size in bytes is now:', sizeOfOriginalImage);
//       imageCompress.uploadFile().then(({image, orientation}) => {
//         // this.imgResultBeforeCompression = image;
//         console.log('Size in bytes of the uploaded image was:', imageCompress.byteCount(image));

//         imageCompress
//             .compressFile(image, orientation, 50, 50) // 50% ratio, 50% quality
//             .then(compressedImage => {
//                 console.log('Size in bytes after compression is now:', imageCompress.byteCount(compressedImage));
//             });
//     });
//       // imageCompress.compressFile(image, orientation, 50, 50).then(
//       //   result => {
//       //     localCompressedURl = result;
//       //     sizeOFCompressedImage = imageCompress.byteCount(result) / (1024 * 1024)
//       //     console.warn('Size in bytes after compression:', sizeOFCompressedImage);
//       //     // create file from byte
//       //     const imageName = fileName;
//       //     // call method that creates a blob from dataUri
//       //     // const imageBlob = dataURItoBlob(imgResultAfterCompress.split(',')[1]);
//       //     //imageFile created below is the new compressed file which can be send to API in form data
//       //     const imageFile = new File([result], imageName, { type: type });
//       //     resolve(imageFile);
//       //   });
//       // var img = new Image();
//       // // img.src = reader.result;
//       //       img.onload = function () {
//       //         resolve(e.target.result as string);
//       //       };
//       //       img.onerror = function () {
//       //         resolve('');
//       //       };

//     }

//     reader.onerror = (e) => { reject(e) }
//   });
//   // var image: any;
//   // var file: any;
//   // var localCompressedURl: any;
//   // var sizeOFCompressedImage: number;
//   // var sizeOfOriginalImage: number;
//   // file = event.target.files[0];
//   // debugger
//   // if (event.target.files && event.target.files[0]) {
//   //   var reader = new FileReader();
//   //   reader.onload = (event: any) => {
//   //     image = event.target.result;
//   //   }
//   //   //

//   // }
// }
