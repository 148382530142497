import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { OrderStatus } from 'src/app/services/enums/order-status';
import { Location } from '@angular/common';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';

import {
  GridColumnTypes,
  GridViewColumn,
  GridViewModel,
  TextAlign,
} from 'src/app/models-classes/grid-view/grid-view.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { GetRequiredUserInfo } from 'src/app/shared/constants/global-variable.model';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css'],
})
export class MyOrdersComponent implements OnInit {
  api_admin = environment.api.baseUrl + '/Customer/GetAllOrders';

  gridModel: GridViewModel = new GridViewModel();

  public columns: GridViewColumn[] = [
    { type: GridColumnTypes.Checkbox, width: '40' },
    {
      type: GridColumnTypes.Number,
      field: 'id',
      headerText: 'Id',
      isPrimaryKey: true,
      visible: false,
    },
    {
      field: 'orderStatusName',
      textAlign: TextAlign.Center,
      headerText: this.translate.instant('Status'),
    },
    {
      field: 'customerName',
      textAlign: TextAlign.Center,
      headerText: this.translate.instant('Customer'),
    },
    {
      field: 'paymentTypeName',
      textAlign: TextAlign.Center,
      headerText: this.translate.instant('Payment method'),
    },
    {
      field: 'total',
      textAlign: TextAlign.Center,
      headerText: this.translate.instant('Total'),
    },
    {
      field: 'orderNumber',
      textAlign: TextAlign.Center,
      headerText: this.translate.instant('Order No'),
    },
    // { field: 'workDays', headerText: 'Work Days' },
    {
      field: 'orderDate',
      textAlign: TextAlign.Center,
      headerText: this.translate.instant('Date'),
      format: 'yMd',
    },
    // {
    //   headerText: this.translate.instant('ManageRecords'),
    //   commands: [
    //     { headerText: "Actions", template: '<button >Open</button>' }
    //   ],
    // }
    {
      headerText: this.translate.instant('Order Details'),
      textAlign: TextAlign.Center,
      template: `<button class='btn btn-light btn-sm order-details-btn'><i class="fas fa-exclamation-circle"><i/></button>`,
    },

    // { field: 'Phone', headerText: 'Phone' },
    // { field: 'DateTo', headerText: 'DateTo', format: 'yMd' },
    // { field: 'DateFrom', headerText: 'DateFrom', format: 'yMd' },
  ];
  current_lang = localStorage.getItem('current_lang') || 'en';
  orders: any[] = [];
  old_orders: any[] = [];
  searchTerm = '';
  constructor(
    private authService: AuthService,
    private location: Location,
    private translate: TranslateService
  ) {}
  public sortOptions?: object;

  orderStatus = OrderStatus;
  ngOnInit(): void {
    this.sortOptions = {
      columns: [{ field: 'orderNumber', direction: 'Descending' }],
    };
    this.gridModel = {
      gridViewColumns: this.columns,
      sortSettings: this.sortOptions,
      recordClick: function (args) {
        console.log('adas');
      },
      pageSettings: { pageSize: 10, pageSizes: true },
      filterSettings: { type: 'Menu' },
      // selectionSettings: DataGridConstants.selectionSettings,
      toolbar: ['ExcelExport'],
      editSettings: {
        allowEditing: false,
        // allowAdding: true,
        // allowDeleting: true,
        mode: 'Normal',
      },
      allowPaging: true,
      allowSorting: true,
      allowFiltering: true,
      // allowSelection: true,
      allowGrouping: false,
      allowRowDragAndDrop: false,
      data: new DataManager({
        url: this.api_admin,
        adaptor: new UrlAdaptor(),
        headers: [
          {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            langid: localStorage.getItem('current_lang') == 'en' ? '2' : '1',
          },
        ],
      }),
    };
    // this.getOrders();
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
  }
  getOrders() {
    this.authService.getAllOrders().subscribe((res) => {
      this.orders = res.data;
      this.old_orders = res.data;
    });
  }
  back(): void {
    this.location.back();
  }
  onSearch() {
    if (this.searchTerm)
      this.orders = this.orders.filter(
        (item) =>
          item.customerName
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          item.orderStatusName
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          item.paymentTypeName
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          item.orderDate.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    else this.orders = this.old_orders;
  }
}
