<app-navbar></app-navbar>
<div class="container">
    <h2 class="text-light-black">{{'Delete Account' | translate}}</h2>
    <div class="images">
      <img *ngIf="current_lang == 'en'" src="/assets/img/delete_s_en.png" alt="Delete Account Image" class="image">
      <img *ngIf="current_lang == 'en'" src="/assets/img/delete_a_en.png" alt="Delete Account Icon" class="icon">
      <img *ngIf="current_lang == 'ar'" src="/assets/img/delete_s_ar.png" alt="Delete Account Image" class="image">
        <img *ngIf="current_lang == 'ar'" src="/assets/img/delete_a_ar.png" alt="Delete Account Icon" class="icon">
      </div>
    <p class="description text-light-black">
      {{'To delete your account and associated information from our system, please follow these steps' | translate}}:
    </p>
    <ol>
      <li class="text-light-black"><strong>{{'Confirmation' | translate}}:</strong> {{'Before proceeding, please ensure that you genuinely wish to delete your account. Deleting your account is irreversible and will result in the permanent loss of all data associated with it.' | translate}}</li>
      <li class="text-light-black"><strong>{{'Initiate Deletion' | translate}}:</strong> {{'If you are certain about deleting your account, locate and click on the "Delete Account" button. This action will start the account deletion process.' | translate}}</li>
      <li class="text-light-black"><strong>{{'Completion' | translate}}:</strong> {{'Once your account deletion request has been processed, you will receive confirmation that your account and all associated data have been permanently removed from our system. You will no longer have access to your account or any data related to it.' | translate}}</li>
    </ol>
    <p class="contact-info">
      {{'If you encounter any issues during the account deletion process or have any questions regarding account deletion, please do not hesitate to contact our support team for assistance. We are here to help you through the process.' | translate}}
    </p>
    <!-- <button class="delete-button" (click)="deleteAccount()">Delete Account</button> -->
  </div>
  
  <app-footer2></app-footer2>