<app-navbar></app-navbar>
<div id="image-with-content" class="p-relative w-100">
  <div id="image-content" class="d-flex flex-column w-100 text-center">
    <div class="image-content-header-title pb-2">{{'Order takeaway in' | translate}}</div>
    <div class="input-group2 image-content-parent-input">
      <input type="search" class="form-control form-control-submit image-content-input"
        ngx-google-places-autocomplete />
    </div>
  </div>
</div>
<div class="mb-5 mt-5">
  <div class="row">
    <div class="container-fluid col-md-10">
      <!-- Nav tabs -->
      <div class="card card-height">
        <div class="card-header">
          <ul class="nav nav-tabs justify-content-center" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#image" role="tab">
                <i class="now-ui-icons objects_umbrella-13"></i> {{'Profile Image' | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#profile" role="tab">
                <i class="now-ui-icons shopping_cart-simple"></i> {{'Personel Data' | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a (click)="openNav(2)" class="nav-link" data-toggle="tab" href="#change-password" role="tab">
                <i class="now-ui-icons shopping_shop"></i> {{'Change Password' | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a (click)="openNav(3)" class="nav-link" data-toggle="tab" href="#addresses" role="tab">
                <i class="now-ui-icons ui-2_settings-90"></i> {{'Addresses' | translate}}
              </a>
            </li>
            <li class="nav-item">
              <a (click)="openNav(4)" class="nav-link" data-toggle="tab" href="#wallets" role="tab">
                <i class="now-ui-icons ui-2_settings-90"></i> {{'Talabat Pay' | translate}}
              </a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <!-- Tab panes -->
          <div class="tab-content text-center">
            <div class="tab-pane active" id="image" role="tabpanel">
              <div class="avatar-upload">
                <div class="avatar-edit">
                  <form action="" method="post" id="form-image">
                    <input (change)="uploadFile($event)" type="file" id="upload" hidden />
                    <label class="label-input-edit" for="upload"> <i class="fa fa-edit"></i>
                    </label>
                  </form>
                </div>
                <div class="avatar-preview">
                  <img class="profile-user-img img-responsive img-circle" id="imagePreview" [src]="imageUrl"
                    alt="User profile picture">
                </div>
                <!-- <button title="Save image" style="width: 125px;" class="btn btn-secondary mt-2" (click)="saveImage()"><i
                  class="fa fa-save"></i></button> -->
              </div>
            </div>
            <div class="tab-pane" id="profile" role="tabpanel">
              <!-- <h2 class="tab-content-title">{{'Personel Data' | translate}}</h2> -->
              <div class="text-center">
                <form *ngIf="infoForm" [formGroup]="infoForm" (ngSubmit)="update(infoForm,infoForm.value,formDirective)"
                  #formDirective="ngForm">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="text-light-black fw-700">{{'Full Name' | translate}} <span style="color:red;"
                            class="fs-16">*</span>
                        </label>
                        <input
                          [ngClass]="{ 'is-invalid': f.fullName.errors && (f.fullName.dirty || submitted), 'is-valid': f.fullName.valid }"
                          type="text" formControlName="fullName" class="form-control form-control-submit">
                        <div *ngIf="f.fullName.touched && f.fullName.errors" class="invalid-feedback">
                          <div *ngIf="f.fullName.errors.required">{{'Full Name' | translate}} {{'validations.isrequired'
                            |
                            translate}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                        <button [disabled]="!infoForm.valid" class="btn btn-secondary d-block" type="submit">{{'Update' | translate}}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="tab-pane" id="change-password" role="tabpanel">
              <form *ngIf="changePassForm" [formGroup]="changePassForm"
              (ngSubmit)="updatePassword(changePassForm,changePassForm.value,formDirective)" #formDirective="ngForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-light-black fw-700">{{'Old Password' | translate}} <span style="color:red;" class="fs-16">*</span>
                    </label>
                    <div class="input-group mb-2 mr-sm-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fas fa-eye-slash" id="eye1" (click)="toggle(2)"></i>
                        </div>
                      </div>
                      <input
                        [ngClass]="{ 'is-invalid': f1.oldPassword.errors && (f1.oldPassword.dirty || submitted1), 'is-valid': f1.oldPassword.valid }"
                        type="password" class="form-control" id="password1" formControlName="oldPassword">
                    </div>
                    <!-- <input
                      [ngClass]="{ 'is-invalid': f1.oldPassword.errors && (f1.oldPassword.dirty || submitted1), 'is-valid': f1.oldPassword.valid }"
                      type="text" formControlName="oldPassword" name="#" class="form-control form-control-submit pass"
                      value="password" placeholder="Password" required> -->
                    <div *ngIf="f1.oldPassword.touched && f1.oldPassword.errors" class="invalid-feedback">
                      <div *ngIf="f1.oldPassword.errors.required">{{'Old Password' | translate}}
                        {{'validations.isrequired' | translate}}</div>
                    </div>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-light-black fw-700">{{'New Password' | translate}} <span style="color:red;"
                        class="fs-16">*</span>
                    </label>
                    <div class="input-group mb-2 mr-sm-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fas fa-eye-slash" id="eye" (click)="toggle()"></i></div>
                      </div>
                      <input
                        [ngClass]="{ 'is-invalid': f1.password.errors && (f1.password.dirty || submitted1), 'is-valid': f1.password.valid }"
                        type="password" class="form-control" id="password" formControlName="password">
                    </div>
                    <!-- <input
                      [ngClass]="{ 'is-invalid': f1.password.errors && (f1.password.dirty || submitted1), 'is-valid': f1.password.valid }"
                      type="password" id="password-field" formControlName="password" name="#"
                      class="form-control form-control-submit pass" value="password" placeholder="Password" required> -->
                    <!-- <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div> -->
                    <div *ngIf="f1.password.touched && f1.password.errors" class="invalid-feedback">
                      <span class="text-danger" *ngIf="(f1.password.dirty || submitted) && !requiredValid">Password is
                        required</span>

                      <div id="validation-rules" *ngIf="requiredValid">
                        <div [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                          <i [attr.class]="minLengthValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                          Must be at least 8 characters long
                        </div>

                        <div [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                          <i [attr.class]="requiresDigitValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                          Must contain at least 1 digit
                        </div>

                        <div
                          [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                          <i [attr.class]="requiresUppercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                          Must contain at least 1 uppercase character
                        </div>

                        <div
                          [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                          <i [attr.class]="requiresLowercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                          Must contain at least 1 lowercase character
                        </div>

                        <div
                          [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                          <i [attr.class]="requiresSpecialCharsValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                          Must contain at least 1 special character
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                    <button [disabled]="!changePassForm.valid" class="btn btn-secondary d-block"
                      type="submit">{{'Update' | translate}}</button>
                </div>
              </div>
            </form>
            </div>
            <div class="tab-pane table-responsive" id="addresses" role="tabpanel">
              <div class="d-flex">
                <!-- <div class="add-btn" [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'"> -->
                <div class="add-btn mx-1">
                  <a (click)="open(modaladd,0,1)" class="btn btn-sm btn-warning text-white"><i class="fa fa-plus"></i></a>
                </div>
                <h2 class="tab-content-title">{{'Addresses' | translate}}</h2>
              </div>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-style-right">#</th>
                    <th class="text-style-right">{{'Address Name' | translate}}</th>
                    <th class="text-style-right">{{'Region' | translate}}</th>
                    <th class="text-style-right">{{'Street' | translate}}</th>
                    <th class="text-style-right">{{'Blok Number' | translate}}</th>
                    <th class="text-style-right">{{'Appartment Number' | translate}}</th>
                    <th class="text-style-right">{{'Phone' | translate}}</th>
                    <th class="text-style-right">{{'Notes' | translate}}</th>
                    <!-- <th class="text-style-right">{{'Is Deleted' | translate}}</th> -->
                    <th class="text-style-right">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let address of myAddresses;let i = index">
                    <td class="text-style-right">{{i+1}}</td>
                    <td class="text-style-right">{{address.addressName}}</td>
                    <td class="text-style-right">{{address.region}}</td>
                    <td class="text-style-right">{{address.street}}</td>
                    <td class="text-style-right">{{address.buildingNumber}}</td>
                    <td class="text-style-right">{{address.apartmentNumber}}</td>
                    <td class="text-style-right">{{address.phoneNumber}}</td>
                    <td class="text-style-right">{{address.notes}}</td>
                    <!-- <td class="text-center"><i class="fa"
                        [ngClass]="address.isDeleted ? 'fa-check-circle' : 'fa-times-circle'"></i></td> -->
                    <td class="text-center">
                      <div class="d-flex">
                        <a [ngClass]="current_lang == 'en' ? 'mr-1' : 'ml-1'" class="btn btn-success"
                          (click)="open(modaledit,address.id,1)"><i class="fa fa-edit text-white"></i></a><a
                          (click)="open(modaldelete,address.id,0)" class="btn btn-danger text-white"><i
                            class="fa fa-trash"></i></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tab-pane" id="wallets" role="tabpanel">
              <div class="d-flex">
                <h2 class="tab-content-title text-style-right">{{'Available Balance' | translate}}: </h2>
                <h4 class="tab-content-title mx-2"> {{walletBalance}}</h4>
                <!-- <div class="add-btn" [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'">
                  <a (click)="open(modaladd,0,1)" class="btn btn-sm btn-warning text-white"><i class="fa fa-plus"></i></a>
                </div> -->
              </div>
              <table
                      summary="This table shows how to create responsive tables using RWD-Table-Patterns' functionality"
                      class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th class="text-style-right">#</th>
                          <th class="text-style-right">{{'Date' | translate}}</th>
                          <th class="text-style-right" data-priority="1">{{'Details' | translate}}</th>
                          <th class="text-style-right" data-priority="2">{{'Ammount' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let wallet of myWallets;let i = index">
                          <td class="text-style-right">{{i+1}}</td>
                          <td class="text-style-right">{{wallet.transDate | date:'medium'}}</td>
                          <td class="text-style-right">{{wallet.description}}</td>
                          <td class="text-style-right">{{wallet.amount}}</td>
                        </tr>
                      </tbody>
                      <!-- <tfoot>
                      </tfoot> -->
                    </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer2></app-footer2>

<ng-template #modaladd let-modal>
  <div class="modal-header">
    <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-share bg-primary text-white"></i>
      {{'Add' | translate}} {{'Address' | translate}}</h3>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <form *ngIf="customerAddressAddForm" [formGroup]="customerAddressAddForm"
      (ngSubmit)="createAddress(customerAddressAddForm,customerAddressAddForm.value,formDirectiveB)"
      #formDirectiveB="ngForm" class="needs-validation clearfix">
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="validationCustom01B">{{'Address' | translate}} {{'Name' | translate}}</label>
          <div class="input-group">
            <input formControlName="addressName" type="text" class="form-control" id="validationCustom01B"
              placeholder="Name of address">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fB.addressName.dirty) && fB.addressName.errors">
              <div class="text-align-left" *ngIf="fB.addressName.errors.required">{{'Address' | translate}} {{'Name' |
                translate}} {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom02B">{{'Region' | translate}}</label>
          <div class="input-group">
            <input formControlName="region" type="text" class="form-control" id="validationCustom02B"
              placeholder="Region">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fB.region.dirty) && fB.region.errors">
              <div class="text-align-left" *ngIf="fB.region.errors.required">{{'Region' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05B">{{'Street' | translate}}</label>
          <div class="input-group">
            <input formControlName="street" type="text" class="form-control" id="validationCustom05B"
              placeholder="Street">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fB.street.dirty) && fB.street.errors">
              <div class="text-align-left" *ngIf="fB.street.errors.required">{{'Street' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05B1">{{'Building Number' | translate}}</label>
          <div class="input-group">
            <input formControlName="buildingNumber" type="text" class="form-control" id="validationCustom05B1"
              placeholder="{{'Building Number' | translate}}">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fB.buildingNumber.dirty) && fB.buildingNumber.errors">
              <div class="text-align-left" *ngIf="fB.buildingNumber.errors.required">{{'Building Number' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05B2">{{'Appartment Number' | translate}}</label>
          <div class="input-group">
            <input formControlName="apartmentNumber" type="text" class="form-control" id="validationCustom05B2"
              placeholder="{{'Appartment Number' | translate}}">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback"
              *ngIf="(submitted || fB.apartmentNumber.dirty) && fB.apartmentNumber.errors">
              <div class="text-align-left" *ngIf="fB.apartmentNumber.errors.required">{{'Appartment Number' |
                translate}} {{'validations.isrequired' |
                translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05B3">{{'Phone' | translate}}</label>
          <div class="input-group">
            <input formControlName="phoneNumber" type="text" class="form-control" id="validationCustom05B3"
              placeholder="{{'Phone' | translate}}">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fB.phoneNumber.dirty) && fB.phoneNumber.errors">
              <div class="text-align-left" *ngIf="fB.phoneNumber.errors.required">{{'Phone' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05B4">{{'Latitude and longitude' | translate}}</label>
          <div class="input-group">
            <input formControlName="latLng" type="text" class="form-control" id="validationCustom05B4"
              placeholder="LatLng">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <!-- <div class="invalid-feedback" *ngIf="(submitted || fB.latLng.dirty) && fB.latLng.errors">
              <div class="text-align-left" *ngIf="fB.latLng.errors.required">{{'Latitude and longitude' | translate}} {{'validations.isrequired' | translate}}</div>
            </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <label for="validationCustom09B">{{'Select Country' | translate}}</label>
          <div class="input-group">
            <select (change)="changeCountry($event)"
              [ngClass]="{'is-invalid':fB.countryId.touched && fB.countryId.errors}" formControlName="countryId"
              class="form-control form-control-submit custom-select-2 full-width">
              <option value='' selected disabled>{{'Select Country' | translate}}</option>
              <option *ngFor="let country of countries" [value]="country.id">
                {{country.name}}
              </option>
            </select>
            <div class="invalid-feedback" *ngIf="fB.countryId.touched && fB.countryId.errors">
              <div *ngIf="fB.countryId.errors.required">{{'Country' | translate}} {{'validations.isrequired' |
                translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label for="validationCustom10B">{{'Select City' | translate}}</label>
          <div class="input-group">
            <select [disabled]="countryId == 0" formControlName="cityId"
              class="form-control form-control-submit custom-select-2 full-width">
              <option value="0" disabled>{{'Select City' | translate}}</option>
              <option *ngFor="let city of cities" [value]="city.id">
                {{city.name}}
              </option>
            </select>

            <!-- <div class="invalid-feedback" *ngIf="f.cityId.touched && f.cityId.errors">
                    <div *ngIf="f.cityId.errors.required">City {{'validations.isrequired' | translate}}</div>
                  </div> -->
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <label for="validationCustom13B">{{'Notes' | translate}}</label>
          <div class="input-group">
            <textarea formControlName="notes" rows="5" id="validationCustom13B" class="form-control"
              placeholder="Notes"></textarea>
          </div>
        </div>

        <div class="col-md-12">
          <div class="ms-panel">
            <div class="ms-panel-header new">
              <button class="btn btn-secondary d-block" type="submit">{{'Save' | translate}}</button>
              <!-- <button class="btn btn-primary d-block" type="submit">Save and Add</button> -->
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #modaledit let-modal>
  <div class="modal-header">
    <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-share bg-primary text-white"></i>
      {{'Edit' | translate}} {{'Address' | translate}}</h3>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <form *ngIf="customerAddressForm" [formGroup]="customerAddressForm"
      (ngSubmit)="updateAddress(customerAddressForm,customerAddressForm.value,formDirective)" #formDirective="ngForm"
      class="needs-validation clearfix">
      <div class="form-row">
        <input type="hidden" formControlName="id">
        <div class="col-md-4 mb-3">
          <label for="validationCustom01">{{'Address' | translate}} {{'Name' | translate}}</label>
          <div class="input-group">
            <input formControlName="addressName" type="text" class="form-control" id="validationCustom01"
              placeholder="Name of address">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fA.addressName.dirty) && fA.addressName.errors">
              <div class="text-align-left" *ngIf="fA.addressName.errors.required">{{'Address' | translate}} {{'Name' |
                translate}} {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom02">{{'Region' | translate}}</label>
          <div class="input-group">
            <input formControlName="region" type="text" class="form-control" id="validationCustom02"
              placeholder="Region">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fA.region.dirty) && fA.region.errors">
              <div class="text-align-left" *ngIf="fA.region.errors.required">{{'Region' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05">{{'Street' | translate}}</label>
          <div class="input-group">
            <input formControlName="street" type="text" class="form-control" id="validationCustom05"
              placeholder="Street">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fA.street.dirty) && fA.street.errors">
              <div class="text-align-left" *ngIf="fA.street.errors.required">{{'Street' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05">{{'Building Number' | translate}}</label>
          <div class="input-group">
            <input formControlName="buildingNumber" type="text" class="form-control" id="validationCustom05"
              placeholder="{{'Building Number' | translate}}">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fA.buildingNumber.dirty) && fA.buildingNumber.errors">
              <div class="text-align-left" *ngIf="fA.buildingNumber.errors.required">{{'Blok number' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05">{{'Appartment Number' | translate}}</label>
          <div class="input-group">
            <input formControlName="apartmentNumber" type="text" class="form-control" id="validationCustom05"
              placeholder="{{'Appartment Number' | translate}}">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback"
              *ngIf="(submitted || fA.apartmentNumber.dirty) && fA.apartmentNumber.errors">
              <div class="text-align-left" *ngIf="fA.apartmentNumber.errors.required">{{'Appartment Number' |
                translate}} {{'validations.isrequired' |
                translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom05">{{'Phone' | translate}} {{'Number' | translate}}</label>
          <div class="input-group">
            <input formControlName="phoneNumber" type="text" class="form-control" id="validationCustom05"
              placeholder="{{'Phone' | translate}}">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <div class="invalid-feedback" *ngIf="(submitted || fA.phoneNumber.dirty) && fA.phoneNumber.errors">
              <div class="text-align-left" *ngIf="fA.phoneNumber.errors.required">{{'Phone' | translate}} {{'Number' |
                translate}} {{'validations.isrequired' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationCustom05">{{'Latitude and longitude' | translate}}</label>
          <div class="input-group">
            <input formControlName="latLng" type="text" class="form-control" id="validationCustom05"
              placeholder="LatLng">
            <!-- <div class="valid-feedback">
              {{'Looks good' | translate}}
            </div> -->
            <!-- <div class="invalid-feedback" *ngIf="(submitted || fA.latLng.dirty) && fA.latLng.errors">
              <div class="text-align-left" *ngIf="fA.latLng.errors.required">{{'Latitude and longitude' | translate}}
                {{'validations.isrequired' | translate}}</div>
            </div> -->
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationCustom13">{{'Notes' | translate}}</label>
          <div class="input-group">
            <textarea formControlName="notes" rows="5" id="validationCustom13" class="form-control"
              placeholder="Notes"></textarea>
            <!-- <div class="invalid-feedback">
              {{'provideaddress' | translate}}
            </div> -->
          </div>
        </div>
        <div class="col-md-12">
          <div class="ms-panel">
            <div class="ms-panel-header new">
              <button class="btn btn-secondary d-block" type="submit">{{'Save' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
    <button type="submit" class="btn btn-primary shadow-none">Submit</button>
  </div> -->
</ng-template>

<ng-template #modaldelete let-modal>
  <div class="modal-header">
    <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-alert-1 bg-primary text-white"></i>
      {{'important Notice' | translate}}</h3>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <p>{{'Are you sure to delete this address?' | translate}}</p>
  </div>
  <div class="modal-footer">
    <!-- <div class="modal-notice">
          <label class="ms-checkbox-wrap">
              <input type="checkbox" value="">
              <i class="ms-checkbox-check"></i>
          </label>
          <span> Don't Show me Again </span>
      </div> -->
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">{{'Close' | translate}}</button>
    <button type="button" class="btn btn-primary shadow-none" (click)="deleteAddress()">{{'Accept' |
      translate}}</button>
  </div>
</ng-template>