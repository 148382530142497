import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseResult } from 'src/app/models-classes/common.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  _base = environment.api.baseUrl;
  constructor(private _http: HttpClient) {}
  headers = new HttpHeaders().set('langid', localStorage.getItem('current_lang') == 'en' ? '2' : '1');
  options = { headers: this.headers };
  getAbout(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + '/api/Info/GetAbout',this.options);
  }

  getTerms(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + '/api/Info/GetTerms',this.options);
  }

  getPrivacy(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + '/api/Info/GetPrivacy',this.options);
  }
}
