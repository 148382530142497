import { Address } from "../address/address.model";

export class Order {
  // id: number;
  // customerAddressId: number;
  // companyId: number;
  // orderNumber: number;
  // paymentTypeId: number;
  // total: number;
  // discount: number;
  // tax: number;
  // deliverFees: number;
  // orderDate: Date;
  // notes: string;
  // newAddress: Address;
  // isSaveAddress?: boolean;
  // paymentRefNo:string;
  // paymentTransactionToken:string;
  // paymentSuccessToken:string;
  isWeb:boolean;
  id: number;
  companyId?: number;
  branchId?: number;
  orderNumber: number;
  orderDate: Date;
  paymentTypeId: number;
  total: number;
  discount: number;
  tax: number;
  deliverFees: number;
  notes: string;
  country: string;
  city: string;
  region: string;
  street: string;
  buildingNo: string;
  apartmentNo: string;
  latLng: string;
  phoneNumber: string;
  isSaveAddress?: boolean;
  saveAddressAs?: string;
  paymentRefNo: string;
  paymentTransactionToken: string;
  paymentSuccessToken: string;
  orderDtls: OrderDtls[];
}
export class OrderDtls {
  id: number;
  productId: number;
  extraDetails: string;
  notes: string;
  quantity: number;
  price: number;
  total: number;
  discount: number;
  tax: number;
}
