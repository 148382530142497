import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/models-classes/order/order.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CatalogService } from 'src/app/services/catalogs/catalog.service';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
import { SettingsService } from 'src/app/services/settings.service';
import { getUniqueId } from 'src/app/shared/constants/global-variable.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
declare var $: any;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  encapsulation: ViewEncapsulation.None, //change style for child in parent
})
export class CompanyComponent implements OnInit, AfterViewChecked {
  days = [
    { id: 1, name: 'Sunday' },
    { id: 2, name: 'Monday' },
    { id: 3, name: 'Tuesday' },
    { id: 4, name: 'Wednesday' },
    { id: 5, name: 'Thursday' },
    { id: 6, name: 'Friday' },
    { id: 7, name: 'Saturday' },
  ];
  order: any;
  attributeDetails: any[] = [];
  product: any;
  productName = '';
  description = '';
  price = 0;
  fullPrice = 0;
  isNote = false;
  fav = false;
  extraDetails = '';
  detailStates: { [key: number]: boolean } = {};
  id = 0;
  productId = 0;
  company: any;
  rate = 4;
  current_lang = localStorage.getItem('current_lang') || 'en';

  companyProducts: any[] = [];
  companyReviews: any[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
    private catalogService: CatalogService,
    private elementRef: ElementRef,
    public settingService: SettingsService,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private favoriteService: FavoriteService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.modalConfig.centered = true;
    this.modalConfig.container = 'body';
  }
  sanitizedVideoUrl: SafeResourceUrl;
  enforceMaxSelectedOptions() {
    $('[id^="subCheckboxtree"]').each(function () {
      const subAttributeId = $(this)
        .attr('name')
        .replace('subCheckboxtree', '');
      const maxSelectedOptions = $(this).data('max-selected-options');

      if (maxSelectedOptions) {
        const selectedCount = $(
          `[name="subCheckboxtree${subAttributeId}"]:checked`
        ).length;
        if (selectedCount >= maxSelectedOptions) {
          $(`[name="subCheckboxtree${subAttributeId}"]`)
            .not(':checked')
            .prop('disabled', true);
        } else {
          $(`[name="subCheckboxtree${subAttributeId}"]`).prop(
            'disabled',
            false
          );
        }
      }
    });
  }
  hasType1Image(images: any[]): boolean {
    return images && images.some(image => image.type === 1);
}
  isEmbedUrl = false;
  openProductVideo(productFiles: any[]) {
    let video = productFiles.find((p) => p.type == 1);
    if (video) {
      this.modalService.open(this.modalTemplate, {
        centered: true,
        ariaLabelledBy: 'modal-basic-title',
      });
      // if (this.isEmbeddableUrl(video.image)) {
      //   this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.addAutoplayParam(video.image));
      //   this.isEmbedUrl = true;
      // } else {
      //   this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(video.image);
      //   this.isEmbedUrl = false;
      // }
      this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        video.image
      );
      this.isEmbedUrl = this.isEmbeddableUrl(video.image);
    }
    else{
      console.log("tarikllll")
    }
  }
  isEmbeddableUrl(url: string): boolean {
    const embeddablePatterns = ['youtube.com', 'vimeo.com'];
    return embeddablePatterns.some((pattern) => url.includes(pattern));
  }

  addAutoplayParam(url: string): string {
    if (url.includes('youtube.com')) {
      return `${url}?autoplay=1`;
    } else if (url.includes('vimeo.com')) {
      return `${url}?autoplay=1`;
    }
    return url;
  }
  ngAfterViewChecked() {
    this.enforceMaxSelectedOptions();
  }
  isSideBarVisible: boolean;
  sendIsSideBarValue(val) {
    this.isSideBarVisible = val;
  }
  day_count = 0;
  isWorkDay(dayId: number): boolean {
    this.day_count = this.company?.workDays.split(',').length;
    return this.company?.workDays.split(',').includes(dayId.toString());
  }
  // @ViewChildren('radioButtons') radioButtons: QueryList<ElementRef<HTMLInputElement>>;
  // addDynamicStyles(): void {
  //   const style = this.renderer.createElement('style');
  //   style.innerHTML = `
  //     .ms-image-slider .carousel-indicators {
  //       margin: 2% 0 0;
  //       bottom: 0;
  //       background: rgba(0, 0, 0, 0.4);
  //       padding: 1rem 0;
  //       display: flex;
  //       justify-content: center;
  //     }
  //     .ms-image-slider .carousel-indicators li {
  //       width: 80px;
  //       height: 50px;
  //       border-radius: 4px;
  //       overflow: hidden;
  //       margin: 0 5px;
  //       cursor: pointer;
  //       list-style: none;
  //       position: relative;
  //     }
  //     .ms-image-slider .carousel-indicators li:before {
  //       content: '';
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       width: 100%;
  //       height: 100%;
  //       background-position: center;
  //       background-size: cover;
  //       background-repeat: no-repeat;
  //       border: 2px solid transparent;
  //     }
  //     .ms-image-slider .carousel-indicators li.active:before {
  //       border-color: #fff;
  //     }
  //     .ms-image-slider .carousel-indicators li:not(.active):before {
  //       opacity: 0.7;
  //     }
  //   `;

  //   this.companyProducts.forEach((item) => {
  //     item.products.forEach((product) => {
  //       product.productImages.forEach((image, imageIndex) => {
  //         style.innerHTML += `
  //           #shoppingBox${item.id} .product-${product.id} .ms-image-slider .carousel-indicators li:nth-child(${imageIndex + 1}):before {
  //             background-image: url('${image.image}');
  //           }
  //         `;
  //       });
  //     });
  //   });

  //   this.renderer.appendChild(document.head, style);
  // }
  // updateOptionQuantity(detailId: number, optionId: number, change: number) {
  //   if (!this.optionQuantities[detailId]) {
  //     this.optionQuantities[detailId] = {};
  //   }
  //   if (this.optionQuantities[detailId][optionId] === undefined) {
  //     this.optionQuantities[detailId][optionId] = 0;
  //   }

  //   const newQuantity = this.optionQuantities[detailId][optionId] + change;
  //   if (newQuantity >= 0 && this.getTotalQuantity(detailId) + change <= this.getMaxQuantity(detailId)) {
  //     this.optionQuantities[detailId][optionId] = newQuantity;
  //   }
  // }

  // getTotalQuantity(detailId: number): number {
  //   return Object.values(this.optionQuantities[detailId] || {}).reduce((total, qty) => total + qty, 0);
  // }

  // getMaxQuantity(detailId: number): number {
  //   const detail = this.attributeDetails.find(d => d.id === detailId);
  //   return detail ? detail.maxSelectedOptions : 0;
  // }
  // addonProducts = [
  //   { id: 1, name: 'Extra Cheese', image: 'https://rahaldelivery.blob.core.windows.net/uploads/Product/AllAttributes/b8159168-4578-41c5-b813-399d38e9f848.jpeg', price: 1.5 },
  //   { id: 2, name: 'Bacon', image: 'https://rahaldelivery.blob.core.windows.net/uploads/Product/AllAttributes/b8159168-4578-41c5-b813-399d38e9f848.jpeg', price: 2.0 },
  //   // Add more addons as needed
  // ];
  addonQuantities = {};
  updateAddonQuantity(addonId, change) {
    if (!this.addonQuantities[addonId]) {
      this.addonQuantities[addonId] = 0;
    }
    this.addonQuantities[addonId] = Math.max(
      0,
      this.addonQuantities[addonId] + change
    );
    let addon = this.addonProducts.find((a) => a.id == addonId);
    if (change == -1) {
      this.fullPrice -= addon.price;
    } else this.fullPrice += addon.price;
    // this.calculateFullTotal();
  }
  @ViewChild('videoModal', { static: false }) modalTemplate: TemplateRef<any>;

  item: any;
  onOptionChange(detail, option) {
    // Clear previous sub-options
    const prevOptionId = this.selectedOptions[detail.id];
    const prevOption = detail.attributeOptions.find(
      (opt) => opt.id === prevOptionId
    );
    if (this.selectedOptions[detail.id]) {
      if (prevOption && prevOption.subAttributes) {
        prevOption.subAttributes.forEach((subAttribute) => {
          subAttribute.attributeOptions.forEach((subOption) => {
            const subOptionId = subOption.id;
            // Uncheck previous sub-options
            $(`#subRadiotree${subOptionId}`).prop('checked', false);
            $(`#subCheckboxtree${subOptionId}`).prop('checked', false);
          });
        });
      }
    }

    // Set the newly selected option
    this.selectedOptions[detail.id] = option.id;

    // Reset selectedSubOptions
    this.selectedSubOptions = {};
    console.log(option);
    console.log(detail.id);
    console.log(prevOptionId);
    console.log(prevOption);
    console.log(this.getFirstKeyValuePair(this.subAttributeQuantities));
    if (this.subAttributeQuantities) {
      let key = this.getFirstKeyValuePair(this.subAttributeQuantities)?.key;
      let value = this.getFirstKeyValuePair(this.subAttributeQuantities)?.value;
      if (key) {
        let oldOption = this.findOptionById(prevOption, +key);
        let oldPrice = value * oldOption.price;
        this.fullPrice -= oldPrice;
      }
    }
    this.subAttributeQuantities = {};
    // Recalculate fullPrice
    // this.calculateFullTotal();
  }
  findOptionById(product, id) {
    for (const subAttribute of product.subAttributes) {
      for (const option of subAttribute.attributeOptions) {
        if (option.id === id) {
          return option;
        }
      }
    }
    return null; // Return null if no option with the specified id is found
  }
  getFirstKeyValuePair(obj) {
    const firstLevelKeys = Object.keys(obj);
    if (firstLevelKeys.length === 0) return null;

    const firstNestedObject = obj[firstLevelKeys[0]];
    const nestedKeys = Object.keys(firstNestedObject);
    if (nestedKeys.length === 0) return null;

    const firstNestedKey = nestedKeys[0];
    const firstNestedValue = firstNestedObject[firstNestedKey];

    return { key: firstNestedKey, value: firstNestedValue };
  }
  updateOptionQuantity(detailId: number, optionId: number, change: number) {
    const detail = this.attributeDetails.find((d) => d.id === detailId);
    if (!detail) return;
    if (!this.optionQuantities[detailId]) {
      this.optionQuantities[detailId] = {};
    }
    if (this.optionQuantities[detailId][optionId] === undefined) {
      this.optionQuantities[detailId][optionId] = 0;
    }
    let currentQuantityOther = 0;
    const currentQuantity = this.optionQuantities[detailId][optionId];
    const newQuantity = currentQuantity + change;
    const maxQuantity = this.getMaxQuantity(detailId);
    // Update price based on current state before changes
    let priceChange = 0;
    const op2 = detail.attributeOptions.find((a) => a.id === optionId);
    if (op2) {
      priceChange = change * op2.price;
    }
    if (
      newQuantity >= 0 &&
      this.getTotalQuantity(detailId) + change <= maxQuantity
    ) {
      this.optionQuantities[detailId][optionId] = newQuantity;

      // Automatically distribute remaining quantity
      const totalQuantity = this.getTotalQuantity(detailId);
      const remainingQuantity = maxQuantity - totalQuantity;
      if (remainingQuantity > 0) {
        const otherOptions = detail.attributeOptions.filter(
          (option) => option.id !== optionId
        );
        otherOptions.forEach((option) => {
          const otherOptionId = option.id;
          const remainingPerOption = Math.floor(
            remainingQuantity / otherOptions.length
          );
          currentQuantityOther = this.optionQuantities[detailId][otherOptionId];
          this.optionQuantities[detailId][otherOptionId] += remainingPerOption;
          // Add to priceChange for distributed options
          // priceChange += remainingQuantity * option.price;
        });
      }
    } else {
      let endQuantity = newQuantity > maxQuantity ? maxQuantity : newQuantity;
      this.optionQuantities[detailId][optionId] = endQuantity;
      const remainingQuantity = maxQuantity - endQuantity;

      const otherOptions = detail.attributeOptions.filter(
        (option) => option.id !== optionId
      );
      otherOptions.forEach((option) => {
        const otherOptionId = option.id;
        currentQuantityOther = this.optionQuantities[detailId][otherOptionId];
        this.optionQuantities[detailId][otherOptionId] = +remainingQuantity;
        // Subtract from priceChange for redistributed options
        // priceChange -= remainingQuantity * option.price;
      });
    }
    let op = detail.attributeOptions.find((a) => a.id == optionId);
    let other = detail.attributeOptions.filter(
      (option) => option.id !== optionId
    );
    if (detail.attributeOptions.length == 1) {
      if (change == -1) {
        this.fullPrice -= op.price;
      } else {
        this.fullPrice += op.price;
      }
    } else {
      if (change == -1) {
        let orginalPrice = 0;
        orginalPrice += op.price * this.optionQuantities[detailId][optionId];
        let other = detail.attributeOptions.filter(
          (option) => option.id !== optionId
        );
        other.forEach((option) => {
          orginalPrice +=
            this.optionQuantities[detailId][option.id] * option.price;
        });
        // console.log(orginalPrice);
        // this.fullPrice += orginalPrice;
        this.fullPrice -=
          currentQuantity * op.price + currentQuantityOther * other[0].price;
        this.fullPrice += orginalPrice;
      } else {
        let orginalPrice = 0;
        orginalPrice += op.price * this.optionQuantities[detailId][optionId];
        let other = detail.attributeOptions.filter(
          (option) => option.id !== optionId
        );
        let pr = 0;
        other.forEach((option) => {
          orginalPrice +=
            this.optionQuantities[detailId][option.id] * option.price;
        });
        console.log(orginalPrice);
        console.log(
          currentQuantity * op.price + currentQuantityOther * other[0].price
        );
        this.fullPrice -=
          currentQuantity * op.price + currentQuantityOther * other[0].price;
        this.fullPrice += orginalPrice;
      }
    }

    // Update the fullPrice based on the total price change
    // this.fullPrice += priceChange;
  }

  // getTotalQuantity(detailId: number): number {
  //   return Object.values(this.optionQuantities[detailId] || {}).reduce(
  //     (total, qty) => total + qty,
  //     0
  //   );
  // }

  // getMaxQuantity(detailId: number): number {
  //   const detail = this.attributeDetails.find((d) => d.id === detailId);
  //   return detail ? detail.maxSelectedOptions : 0;
  // }

  // isOptionDisabled(subAttribute: any, subOption: any): boolean {
  //   return this.selectedSubOptions[subAttribute.id]?.length >= subAttribute.maxSelectedOptions && !this.selectedSubOptions[subAttribute.id].includes(subOption.id);
  // }

  getTotalQuantity(detailId: number): number {
    return Object.values(this.optionQuantities[detailId] || {}).reduce(
      (acc, quantity) => acc + quantity,
      0
    );
  }
  getTotalQuantityType2(subAttributeId: number, subOptionId: number): number {
    return Object.values(
      this.optionQuantities[subAttributeId][subOptionId] || {}
    ).reduce((acc, quantity) => acc + quantity, 0);
  }
  getTotalSelectedOptions(subAttributeId: number): number {
    return this.selectedSubOptions[subAttributeId]?.size || 0;
  }
  getMaxQuantity(detailId: number): number {
    const detail = this.attributeDetails.find((d) => d.id === detailId);
    console.log(detail);
    return detail ? detail.maxSelectedOptions : 0;
  }

  selectedOptions: { [key: number]: number } = {};
  optionQuantities: { [key: number]: { [key: number]: number } } = {};
  subAttributeQuantities: {
    [subAttributeId: string]: { [subOptionId: string]: number };
  } = {};

  // subAttributeQuantities: { [key: number]: number } = {};
  selectedSubOptions: { [key: number]: Set<number> } = {};
  isOptionDisabled(subAttribute: any, subOption: any): boolean {
    return (
      this.selectedSubOptions[subAttribute.id]?.size >=
        subAttribute.maxSelectedOptions &&
      !this.selectedSubOptions[subAttribute.id]?.has(subOption.id)
    );
  }

  addDynamicStyles(): void {
    const style = this.renderer.createElement('style');
    style.innerHTML = `
      .ms-image-slider .carousel-indicators {
        margin: 2% 0 0;
        bottom: 0;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
      }
      .ms-image-slider .carousel-indicators li {
        width: 80px;
        height: 50px;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 5px;
        cursor: pointer;
        list-style: none;
        position: relative;
      }
      .ms-image-slider .carousel-indicators li:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 2px solid transparent;
      }
      .ms-image-slider .carousel-indicators li.active:before {
        border-color: #fff;
      }
      .ms-image-slider .carousel-indicators li:not(.active):before {
        opacity: 0.7;
      }
    `;

    this.companyProducts.forEach((item) => {
      item.products.forEach((product) => {
        product.productImages.forEach((image, imageIndex) => {
          style.innerHTML += `
            #shoppingBox${item.id} .product-${
            product.id
          } .ms-image-slider .carousel-indicators li:nth-child(${
            imageIndex + 1
          }):before {
              background-image: url('${image.image}');
            }
          `;
        });
      });
    });

    this.renderer.appendChild(document.head, style);
  }

  active = 2;
  cost = 0;
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // let element = document.getElementById('shoppingBox78');
    //   console.log(element.offsetTop);
  }
  getConcatenatedNames(): string {
    return this.company?.categories.map((category) => category.name).join(', ');
  }
  oldOptionValues: { [detailId: number]: number } = {};
  changeFullPrice(event, detailId) {
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement.value);
    // const newValue = parseInt(inputElement.value);
    const newValue = parseFloat(inputElement.value);
    const oldValue = this.oldOptionValues[detailId] || 0;

    // Update the full price
    this.fullPrice -= oldValue; // Subtract the old value
    this.fullPrice += newValue; // Add the new value

    // Store the new value as the old value for the next change
    this.oldOptionValues[detailId] = newValue;

    // this.calculateFullTotal();
  }
  ngAfterViewInit() {
    const scroller = document.querySelector('#content');
    const topnav = document.querySelector<HTMLElement>('#top-nav');
    scroller.addEventListener('scroll', (event) => {
      if (scroller.scrollTop >= 454) {
        topnav.style.cssText =
          'position: fixed;top: 50px; left: 0; right: 0; z-index: 3000; background-color: #fff; padding: 10px 20px;width:70%';
      } else {
        topnav.style.cssText = '';
      }
      // console.log(element.offsetTop)
      // if(element.scrollTop > 80){
      //   console.log("rrr")
      // }
    });
    this.attributeDetails.forEach((detail) => {
      if (
        detail.type === 0 &&
        detail.isRequired &&
        detail.attributeOptions.length == 1
      ) {
        const option = detail.attributeOptions[0];
        this.oldOptionValues[detail.id] = option.price;
        this.fullPrice += option.price;
      }
    });
    // this.radioButtons.forEach((radioButton, index) => {
    //   if (radioButton.nativeElement.checked) {
    //     // If the radio button is checked initially, manually trigger the change event
    //     this.changeFullPrice(null);
    //   }
    // });
  }
  orders: any[] = [];
  resetRadioButtons() {
    $('input[type="radio"]').each(function () {
      var controlId = $(this).attr('id');
      if (controlId && controlId.includes('radio')) {
        $(this).prop('checked', false);
      }
    });
  }
  resetCheckboxButtons() {
    $('input[type="checkbox"]').each(function () {
      var controlId = $(this).attr('id');
      if (controlId && controlId.includes('checkbox')) {
        $(this).prop('checked', false);
      }
    });
  }
  // addOrder() {
  //   const conditionsMetResult = this.areConditionsMet();
  //   if (!conditionsMetResult.isValid) {
  //     // Display toast with attribute name
  //     this.toastr.error(
  //       `${this.translate.instant('Please select required options for')} ${
  //         conditionsMetResult.attributeName
  //       }`,
  //       'Error'
  //     );
  //     return; // Prevent further execution
  //   }
  //   this.order = {
  //     companyId: this.id,
  //     branchId: this.branchId,
  //     uniqueId: getUniqueId(10),
  //     customerAddressId: 0,
  //     discount: 0,
  //     deliverFees: this.product.deliveryFees,
  //     notes: '',
  //     paymentTypeId: 0,
  //     orderDate: new Date(),
  //     orderNumber: 1,
  //     companyName: this.company.name,
  //     tax: 1,
  //     total: this.fullPrice,
  //     oldPrice: this.fullPrice,
  //     id: 0,
  //     productName: this.product.name,
  //     productId: this.productId,
  //     extraDetails: this.extraDetails,
  //     quantity: +$('.input-group-field').val(),
  //   };
  //   console.log(this.order);
  //   return
  //   this.settingService.addItemToLocalstorage(this.order, 'my_orders');
  //   this.extraDetails = '';
  //   if (this.attributeDetails.length) {
  //     this.resetCheckboxButtons();
  //     this.resetRadioButtons();
  //     $('#addItemToBasket').modal('hide');
  //   }
  //   // if (
  //   //   this.settingService.getData('my_orders') &&
  //   //   this.settingService.getData('my_orders')[0].companyId !=
  //   //     this.order.companyId
  //   // ) {
  //   // }
  //   this.fillOrders();
  //   console.log(this.settingService.getData('my_orders'));
  // }
  getSelectedOptions(id) {
    const selectedOptions = [];
    console.log(id);
    $(`.option-radio-type0${id}:checked`).each(function () {
      const optionId = parseInt($(this).data('option-id'), 10);
      selectedOptions.push(optionId);
    });
    return selectedOptions.length > 0 ? selectedOptions[0] : 0;
  }

  addOrder() {
    // const conditionsMetResult = this.areConditionsMet();
    // if (!conditionsMetResult.isValid) {
    //   // Display toast with attribute name
    //   this.toastr.error(
    //     `${this.translate.instant('Please select required options for')} ${
    //       conditionsMetResult.attributeName
    //     }`,
    //     'Error'
    //   );
    //   return; // Prevent further execution
    // }
    const extraDetailsArray = [];
    // Prepare order details
    const orderDetails = this.attributeDetails.map((detail) => {
      // console.log(detail);
      let optionIds = 0;
      // const selectedOptions = detail.attributeOptions.find(
      //   (option) => this.optionQuantities[detail.id]?.[option.id] > 0
      // );
      const selectedOptionsIds = [];
      const selectedSubAttributes = [];

      if (detail.type === 0) {
        const selectedOptionId = this.getSelectedOptions(detail.id);
        const selectedOption = detail.attributeOptions.find(
          (option) => option.id === selectedOptionId
        );
        if (selectedOptionId != 0) {
          selectedOptionsIds.push({
            optionsId: selectedOptionId,
            name: selectedOption.name,
            extraPrice: selectedOption.price,
            quantity: 1,
            subAttributes: [],
          });
          extraDetailsArray.push(`${detail.name}: 1 X ${selectedOption.name}`);
        }
      }
      if (detail.type === 1) {
        detail.attributeOptions.forEach((option) => {
          const quantity = +$(`#OptionType1${option.id}`).text();
          if (quantity > 0) {
            selectedOptionsIds.push({
              optionsId: option.id,
              name: option.name,
              extraPrice: option.price,
              quantity: quantity,
              subAttributes: [],
            });
            extraDetailsArray.push(
              `${detail.name}: ${quantity} X ${option.name}`
            );
          }
        });
      }
      // Type 2: Radio buttons with sub-attributes
      if (detail.type === 2 && this.selectedOptions[detail.id]) {
        const selectedOption = detail.attributeOptions.find(
          (option) => option.id === this.selectedOptions[detail.id]
        );
        if (selectedOption) {
          const subAttributes = selectedOption.subAttributes.map((subAttr) => ({
            subAttrId: subAttr.id,
            name: subAttr.name,
            minSelectedOptions: subAttr.minSelectedOptions,
            maxSelectedOptions: subAttr.maxSelectedOptions,
            options: subAttr.attributeOptions.map((subOption) => ({
              optionsId: subOption.id,
              name: subOption.name,
              extraPrice: subOption.price,
              quantity: this.subAttributeQuantities[subAttr.id] || 0,
            })),
          }));
          selectedOptionsIds.push({
            optionsId: selectedOption.id,
            name: selectedOption.name,
            extraPrice: selectedOption.price,
            quantity: 1,
            subAttributes: subAttributes,
          });
          extraDetailsArray.push(`${detail.name}: 1 X ${selectedOption.name}`);
          // Append subattribute details to the extraDetailsArray
          subAttributes.forEach((subAttr) => {
            subAttr.options.forEach((subOption) => {
              if (subOption.quantity > 0) {
                extraDetailsArray.push(
                  `  ${subAttr.name}: ${subOption.quantity} X ${subOption.name} (${subOption.extraPrice})`
                );
              }
            });
          });
        }
      }
      return {
        productId: this.productId,
        extraDetails: extraDetailsArray,
        quantity: +$('.input-group-field').val(), // Ensure this is correct based on your quantity logic
        price: this.price,
        total: this.fullPrice,
        discount: 0,
        tax: 0,
        notes: '',
        selectedAttributes: [
          {
            attrId: detail.id,
            name: detail.name,
            optionsIds: selectedOptionsIds,
          },
        ],
      };
    });
    // Prepare the order object
    this.order = {
      companyId: this.id,
      branchId: this.branchId,
      uniqueId: getUniqueId(10),
      customerAddressId: 0,
      discount: 0,
      minOrderPrice: this.minOrderPrice,
      deliverFees: this.product.deliveryFees,
      notes: '',
      paymentTypeId: 0,
      orderDate: new Date(),
      orderNumber: 1,
      companyName: this.company.name,
      tax: 1,
      total: this.fullPrice,
      oldPrice: this.fullPrice,
      id: 0,
      productName: this.product.name,
      productId: this.productId,
      extraDetails: extraDetailsArray,
      quantity: +$('.input-group-field').val(), // Ensure this matches your quantity logic
      orderDtls: orderDetails,
    };
    console.log(this.order);
    // Gather add-ons
    const selectedAddons = this.addonProducts
      .filter((addon) => this.addonQuantities[addon.id] > 0)
      .map((addon) => ({
        companyId: this.id,
        branchId: this.branchId,
        uniqueId: getUniqueId(10),
        customerAddressId: 0,
        discount: 0,
        deliverFees: this.product.deliveryFees,
        notes: '',
        paymentTypeId: 0,
        orderDate: new Date(),
        orderNumber: 1,
        companyName: this.company.name,
        tax: 1,
        total: addon.price * +this.addonQuantities[addon.id],
        oldPrice: addon.price,
        id: 0,
        productName: addon.name,
        productId: addon.id,
        extraDetails: addon.name,
        quantity: +this.addonQuantities[addon.id], // Ensure this matches your quantity logic
      }));
    // console.log(this.order);
    // Add order to local storage or perform further actions
    this.settingService.addItemToLocalstorage(this.order, 'my_orders');
    selectedAddons.forEach((addOns) => {
      this.settingService.addItemToLocalstorage(addOns, 'my_orders');
    });
    this.extraDetails = '';
    if (this.attributeDetails.length) {
      this.resetCheckboxButtons();
      this.resetRadioButtons();
      $('#addItemToBasket').modal('hide'); // Ensure this matches your modal ID
    }

    this.fillOrders();
    console.log(this.settingService.getData('my_orders'));
  }
  originalPriceMultiOption = 0;
  branchId = 0;
  checkOrders(id,
    branchId,
    companyProductId,
    name,
    description,
    price,
    isAvailable){
      const myOldOrders = JSON.parse(localStorage.getItem('my_orders') || '[]');
      const hasDifferentBranch = myOldOrders.some(
        (order) => order.branchId !== branchId
      );
      if (hasDifferentBranch) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '600px',
          data: {
            message: this.translate.instant(
              'You have orders from a different branch. Do you want to delete them and add the new order?'
            ),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            // User confirmed to delete old orders
            localStorage.setItem('my_orders', JSON.stringify([]));
            this.toastr.warning(
              this.translate.instant(
                'Your old products have been deleted from the cart. You can add products from this branch'
              ),
              this.translate.instant('Close'),
              {
                positionClass: 'toast-top-center' // Ensure the position is set here as well
              }
            );
            this.addItemToBasket(id,
              branchId,
              companyProductId,
              name,
              description,
              price,
              isAvailable)
          }
        });
      }
      else{
        this.addItemToBasket(id,
          branchId,
          companyProductId,
          name,
          description,
          price,
          isAvailable)
      }
  }
  addItemToBasket(
    id,
    branchId,
    companyProductId,
    name,
    description,
    price,
    isAvailable
  ) {
    if (!isAvailable) {
      this.toastr.warning(this.translate.instant('ProductNotAvailable'));
      return;
    }
    
    this.resetCheckboxButtons();
    this.resetRadioButtons();
    this.oldOptionValues = {};
    this.branchId = branchId;
    this.productId = id;
    this.productName = name;
    this.description = description;
    this.originalPriceMultiOption = price;
    this.price = price;
    this.fullPrice = price;
    this.product = this.companyProducts
      .find((c) => c.id == companyProductId)
      .products.find((p) => p.id == id);
    this.attributeDetails = this.companyProducts
      .find((x) => x.id === companyProductId)
      .products.find((x) => x.id == id).attributesDetails;

    // console.log(this.product);

    if (this.product.addOns) {
      const addOnIds = this.product.addOns.split(',').map((id) => id.trim());
      this.addonProducts = this.addonProducts.filter((product) =>
        addOnIds.includes(product.id.toString())
      );
    }
    console.log(this.addonProducts);
    // console.log(this.attributeDetails);
    this.attributeDetails.forEach((detail) => {
      if (detail.type === 1 || detail.type === 2) {
        this.optionQuantities[detail.id] = {};
        if (detail.type === 2) {
          this.selectedOptions[detail.id] = null;
        }
        detail.attributeOptions.forEach((option) => {
          this.optionQuantities[detail.id][option.id] = 0;
        });
      }
    });

    if (this.attributeDetails.length) {
      $('#addItemToBasket').modal('show');
      console.log(this.attributeDetails);
      for (let det of this.attributeDetails) {
        if (det.isRequired) {
          // if (det.attributeOptions && det.attributeOptions.length == 1)
          //   this.fullPrice += det.attributeOptions[0].price;
        }
      }
    } else {
      this.addOrder();
    }
  }
  // areConditionsMet(): { isValid: boolean; attributeName?: string } {
  //   for (let detail of this.attributeDetails) {
  //     // If minSelectedOptions is defined
  //     if (
  //       detail.minSelectedOptions !== undefined &&
  //       detail.minSelectedOptions !== null
  //     ) {
  //       let selectedCount = 0;
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radio${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount < detail.minSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met, return false along with the detail name
  //       }
  //     }

  //     // If maxSelectedOptions is defined
  //     if (
  //       detail.maxSelectedOptions !== undefined &&
  //       detail.maxSelectedOptions !== null
  //     ) {
  //       let selectedCount = 0;
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radio${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount > detail.maxSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded, return false along with the detail name
  //       }
  //     }

  //     // If attribute is required but not selected
  //     if (detail.isRequired && detail.type === 0) {
  //       // For radio buttons
  //       const selectedRadio = document.querySelector(
  //         `input[name="radio${detail.id}"]:checked`
  //       ) as HTMLInputElement;
  //       if (!selectedRadio) {
  //         return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //       }
  //     } else if (detail.isRequired && detail.type === 1) {
  //       // For checkboxes
  //       let atLeastOneSelected = false;
  //       for (let option of detail.attributeOptions) {
  //         const checkbox = document.querySelector(
  //           `input[name="checkbox${option.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (checkbox) {
  //           atLeastOneSelected = true;
  //           break;
  //         }
  //       }
  //       if (!atLeastOneSelected) {
  //         return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //       }
  //     }
  //   }
  //   return { isValid: true }; // If all conditions are met, return true
  // }
  // areConditionsMet(): { isValid: boolean; attributeName?: string } {
  //   for (let detail of this.attributeDetails) {
  //     let selectedCount = 0;

  //     // Check minSelectedOptions for radio buttons or checkboxes
  //     if (detail.minSelectedOptions !== undefined && detail.minSelectedOptions !== null) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(`input[name="radiofirst${detail.id}"]:checked`) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(`input[name="checkbox${option.id}"]:checked`) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount < detail.minSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met, return false along with the detail name
  //       }
  //     }

  //     // Check maxSelectedOptions for radio buttons or checkboxes
  //     selectedCount = 0;
  //     if (detail.maxSelectedOptions !== undefined && detail.maxSelectedOptions !== null) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(`input[name="radiofirst${detail.id}"]:checked`) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(`input[name="checkbox${option.id}"]:checked`) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount > detail.maxSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded, return false along with the detail name
  //       }
  //     }

  //     // Check required attributes for radio buttons or checkboxes
  //     if (detail.isRequired) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(`input[name="radiofirst${detail.id}"]:checked`) as HTMLInputElement;
  //         if (!selectedRadio) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
  //           if (optionQuantity > 0) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //         }
  //       } else {
  //         // Checkboxes
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(`input[name="checkbox${option.id}"]:checked`) as HTMLInputElement;
  //           if (checkbox) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //         }
  //       }
  //     }
  //   }

  //   return { isValid: true }; // If all conditions are met, return true
  // }
  // areConditionsMet(): { isValid: boolean; attributeName?: string } {
  //   for (let detail of this.attributeDetails) {
  //     let selectedCount = 0;

  //     // Check minSelectedOptions for radio buttons or checkboxes
  //     if (
  //       detail.minSelectedOptions !== undefined &&
  //       detail.minSelectedOptions !== null
  //     ) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiofirst${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity =
  //             this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else if (detail.type === 2) {
  //         // Radio buttons with sub-attributes
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiotree${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //           const selectedOption = detail.attributeOptions.find(
  //             (option) => option.id == selectedRadio.value
  //           );
  //           if (selectedOption) {
  //             for (let subAttribute of selectedOption.subAttributes) {
  //               let subSelectedCount = 0;
  //               for (let subOption of subAttribute.attributeOptions) {
  //                 const selectedSubOption =
  //                   (document.querySelector(
  //                     `input[name="subRadiotree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement) ||
  //                   (document.querySelector(
  //                     `input[name="subCheckboxtree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement);
  //                 if (selectedSubOption) {
  //                   subSelectedCount++;
  //                 }
  //               }
  //               if (subSelectedCount < subAttribute.minSelectedOptions) {
  //                 return {
  //                   isValid: false,
  //                   attributeName: `${detail.name} - ${subAttribute.name}`,
  //                 }; // If minSelectedOptions are not met for sub-attributes
  //               }
  //             }
  //           }
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount < detail.minSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met
  //       }
  //     }

  //     // Check maxSelectedOptions for radio buttons or checkboxes
  //     selectedCount = 0;
  //     if (
  //       detail.maxSelectedOptions !== undefined &&
  //       detail.maxSelectedOptions !== null
  //     ) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiofirst${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity =
  //             this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else if (detail.type === 2) {
  //         // Radio buttons with sub-attributes
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiotree${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //           const selectedOption = detail.attributeOptions.find(
  //             (option) => option.id == selectedRadio.value
  //           );
  //           if (selectedOption) {
  //             for (let subAttribute of selectedOption.subAttributes) {
  //               let subSelectedCount = 0;
  //               for (let subOption of subAttribute.attributeOptions) {
  //                 const selectedSubOption =
  //                   (document.querySelector(
  //                     `input[name="subRadiotree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement) ||
  //                   (document.querySelector(
  //                     `input[name="subCheckboxtree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement);
  //                 if (selectedSubOption) {
  //                   subSelectedCount++;
  //                 }
  //               }
  //               if (subSelectedCount > subAttribute.maxSelectedOptions) {
  //                 return {
  //                   isValid: false,
  //                   attributeName: `${detail.name} - ${subAttribute.name}`,
  //                 }; // If maxSelectedOptions are exceeded for sub-attributes
  //               }
  //             }
  //           }
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount > detail.maxSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded
  //       }
  //     }

  //     // Check required attributes for radio buttons or checkboxes
  //     if (detail.isRequired) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiofirst${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (!selectedRadio) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity =
  //             this.optionQuantities[detail.id]?.[option.id] || 0;
  //           if (optionQuantity > 0) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //       } else if (detail.type === 2) {
  //         // Radio buttons with sub-attributes
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiotree${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (!selectedRadio) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //         const selectedOption = detail.attributeOptions.find(
  //           (option) => option.id == selectedRadio.value
  //         );
  //         if (selectedOption) {
  //           for (let subAttribute of selectedOption.subAttributes) {
  //             let subAtLeastOneSelected = false;
  //             for (let subOption of subAttribute.attributeOptions) {
  //               const selectedSubOption =
  //                 (document.querySelector(
  //                   `input[name="subRadiotree${subOption.id}"]:checked`
  //                 ) as HTMLInputElement) ||
  //                 (document.querySelector(
  //                   `input[name="subCheckboxtree${subOption.id}"]:checked`
  //                 ) as HTMLInputElement);
  //               if (selectedSubOption) {
  //                 subAtLeastOneSelected = true;
  //                 break;
  //               }
  //             }
  //             if (!subAtLeastOneSelected && subAttribute.isRequired) {
  //               return {
  //                 isValid: false,
  //                 attributeName: `${detail.name} - ${subAttribute.name}`,
  //               }; // If required but not selected for sub-attributes
  //             }
  //           }
  //         }
  //       } else {
  //         // Checkboxes
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //       }
  //     }
  //   }

  //   return { isValid: true }; // If all conditions are met
  // }
  areConditionsMet(): { isValid: boolean; attributeName?: string } {
    for (let detail of this.attributeDetails) {
      let selectedCount = 0;

      // Check minSelectedOptions
      if (
        detail.minSelectedOptions !== undefined &&
        detail.minSelectedOptions !== null
      ) {
        if (detail.type === 0) {
          // Radio buttons
          const selectedRadio = document.querySelector(
            `input[name="radiofirst${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
          }
        } else if (detail.type === 1) {
          // Quantity-based options
          for (let option of detail.attributeOptions) {
            const optionQuantity =
              this.optionQuantities[detail.id]?.[option.id] || 0;
            selectedCount += optionQuantity;
          }
        } else if (detail.type === 2) {
          // Radio buttons with sub-attributes
          const selectedRadio = document.querySelector(
            `input[name="radiotree${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
            const selectedOption = detail.attributeOptions.find(
              (option) => option.id == selectedRadio.value
            );
            if (selectedOption) {
              for (let subAttribute of selectedOption.subAttributes) {
                let subSelectedCount = 0;
                for (let subOption of subAttribute.attributeOptions) {
                  const selectedSubOption =
                    (document.querySelector(
                      `input[name="subRadiotree${subOption.id}"]:checked`
                    ) as HTMLInputElement) ||
                    (document.querySelector(
                      `input[name="subCheckboxtree${subOption.id}"]:checked`
                    ) as HTMLInputElement);
                  if (selectedSubOption) {
                    subSelectedCount++;
                  }
                }
                if (subSelectedCount < subAttribute.minSelectedOptions) {
                  return {
                    isValid: false,
                    attributeName: `${detail.name} - ${subAttribute.name}`,
                  }; // If minSelectedOptions are not met for sub-attributes
                }
              }
            }
          }
        } else {
          // Checkboxes
          for (let option of detail.attributeOptions) {
            const checkbox = document.querySelector(
              `input[name="checkbox${option.id}"]:checked`
            ) as HTMLInputElement;
            if (checkbox) {
              selectedCount++;
            }
          }
        }
        if (selectedCount < detail.minSelectedOptions) {
          return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met
        }
      }

      // Check maxSelectedOptions
      selectedCount = 0;
      if (
        detail.maxSelectedOptions !== undefined &&
        detail.maxSelectedOptions !== null
      ) {
        if (detail.type === 0) {
          // Radio buttons
          const selectedRadio = document.querySelector(
            `input[name="radiofirst${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
          }
        } else if (detail.type === 1) {
          // Quantity-based options
          for (let option of detail.attributeOptions) {
            const optionQuantity =
              this.optionQuantities[detail.id]?.[option.id] || 0;
            selectedCount += optionQuantity;
          }
        } else if (detail.type === 2) {
          // Radio buttons with sub-attributes
          const selectedRadio = document.querySelector(
            `input[name="radiotree${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
            const selectedOption = detail.attributeOptions.find(
              (option) => option.id == selectedRadio.value
            );
            if (selectedOption) {
              for (let subAttribute of selectedOption.subAttributes) {
                let subSelectedCount = 0;
                for (let subOption of subAttribute.attributeOptions) {
                  const selectedSubOption =
                    (document.querySelector(
                      `input[name="subRadiotree${subOption.id}"]:checked`
                    ) as HTMLInputElement) ||
                    (document.querySelector(
                      `input[name="subCheckboxtree${subOption.id}"]:checked`
                    ) as HTMLInputElement);
                  if (selectedSubOption) {
                    subSelectedCount++;
                  }
                }
                if (subSelectedCount > subAttribute.maxSelectedOptions) {
                  return {
                    isValid: false,
                    attributeName: `${detail.name} - ${subAttribute.name}`,
                  }; // If maxSelectedOptions are exceeded for sub-attributes
                }
              }
            }
          }
        } else {
          // Checkboxes
          for (let option of detail.attributeOptions) {
            const checkbox = document.querySelector(
              `input[name="checkbox${option.id}"]:checked`
            ) as HTMLInputElement;
            if (checkbox) {
              selectedCount++;
            }
          }
        }
        if (selectedCount > detail.maxSelectedOptions) {
          return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded
        }
      }

      // Check required attributes
      if (detail.isRequired) {
        if (detail.type === 0) {
          // Radio buttons
          const selectedRadio = document.querySelector(
            `input[name="radiofirst${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (!selectedRadio) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
        } else if (detail.type === 1) {
          // Quantity-based options
          let atLeastOneSelected = false;
          for (let option of detail.attributeOptions) {
            const optionQuantity =
              this.optionQuantities[detail.id]?.[option.id] || 0;
            if (optionQuantity > 0) {
              atLeastOneSelected = true;
              break;
            }
          }
          if (!atLeastOneSelected) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
        } else if (detail.type === 2) {
          // Radio buttons with sub-attributes
          const selectedRadio = document.querySelector(
            `input[name="radiotree${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (!selectedRadio) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
          const selectedOption = detail.attributeOptions.find(
            (option) => option.id == selectedRadio.value
          );
          if (selectedOption) {
            for (let subAttribute of selectedOption.subAttributes) {
              let subAtLeastOneSelected = false;
              for (let subOption of subAttribute.attributeOptions) {
                const selectedSubOption =
                  (document.querySelector(
                    `input[name="subRadiotree${subOption.id}"]:checked`
                  ) as HTMLInputElement) ||
                  (document.querySelector(
                    `input[name="subCheckboxtree${subOption.id}"]:checked`
                  ) as HTMLInputElement);
                if (selectedSubOption) {
                  subAtLeastOneSelected = true;
                  break;
                }
              }
              if (!subAtLeastOneSelected && subAttribute.isRequired) {
                return {
                  isValid: false,
                  attributeName: `${detail.name} - ${subAttribute.name}`,
                }; // If required but not selected for sub-attributes
              }
            }
          }
        } else {
          // Checkboxes
          let atLeastOneSelected = false;
          for (let option of detail.attributeOptions) {
            const checkbox = document.querySelector(
              `input[name="checkbox${option.id}"]:checked`
            ) as HTMLInputElement;
            if (checkbox) {
              atLeastOneSelected = true;
              break;
            }
          }
          if (!atLeastOneSelected) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
        }
      }
    }

    return { isValid: true }; // If all conditions are met
  }

  isTheTop() {
    let element = document.getElementById('shoppingBox78');
    if (element.scrollTop > 80) {
      console.log('rrr');
    } // rest of your code...
  }
  addNote() {
    this.isNote = !this.isNote;
  }
  fillOrders() {
    this.orders = this.settingService
      .getData('my_orders')
      .filter((o) => o.companyId == this.id);
  }
  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.fav = this.activatedRoute.snapshot.queryParamMap.get('fav') === 'true';
    this.getData();
    this.fillOrders();
    console.log(getUniqueId(10));
    const scroller = document.querySelector('#content');
    // window.onscroll = () => {
    //   // let element = document.getElementById('shoppingBox78');
    //   // console.log(element.offsetHeight);
    //   console.log(this.myFunc());
    // };
    // scroller.addEventListener('scroll', (event) => {

    //   // console.log(element.offsetTop)
    //   // if(element.scrollTop > 80){
    //   //   console.log("rrr")
    //   // }
    // });
    // console.log(document.getElementsByClassName('shoppingBox'));
    this.getDeliveryCost();
  }
  myFunc() {
    return document.documentElement.scrollTop;
  }
  day_ago = '';
  getRelativeDate(dateString: string): { display: string; daysAgo: number } {
    const date = new Date(dateString);
    const currentDate = new Date();
    const dateDiff = currentDate.getTime() - date.getTime();
    const daysAgo = Math.floor(dateDiff / (1000 * 60 * 60 * 24));

    if (daysAgo === 1) {
      return { display: 'day ago', daysAgo };
    } else if (daysAgo > 1) {
      return { display: `days ago`, daysAgo };
    } else {
      return { display: 'Today', daysAgo };
    }
  }
  location: number[] = [];
  currentSlideIndex: number = 0;

  selectSlide(index: number) {
    this.currentSlideIndex = index;
    // Logic to select the slide in the carousel (you may need to use ViewChild to control the NgbCarousel)
  }
  optionQuantities2: {
    [subAttributeId: string]: { [subOptionId: string]: number };
  } = {};

  // updateSubAttributeQuantities(subAttributeId: string, subOptionId: string, change: number): void {
  //   if (!this.subAttributeQuantities[subAttributeId]) {
  //     this.subAttributeQuantities[subAttributeId] = {};
  //   }
  //   if (!this.subAttributeQuantities[subAttributeId][subOptionId]) {
  //     this.subAttributeQuantities[subAttributeId][subOptionId] = 0;
  //   }
  //   this.subAttributeQuantities[subAttributeId][subOptionId] += change;
  //   if (this.subAttributeQuantities[subAttributeId][subOptionId] < 0) {
  //     this.subAttributeQuantities[subAttributeId][subOptionId] = 0;
  //   }
  //   console.log(this.subAttributeQuantities);
  // }
  // updateSubAttributeQuantities(subAttribute, subOptionId: string, change: number): void {
  //   // Initialize subAttributeQuantities if not present
  //   if (!this.subAttributeQuantities[subAttribute.id]) {
  //     this.subAttributeQuantities[subAttribute.id] = {};
  //   }

  //   // Initialize subOption quantity if not present
  //   if (!this.subAttributeQuantities[subAttribute.id][subOptionId]) {
  //     this.subAttributeQuantities[subAttribute.id][subOptionId] = 0;
  //   }

  //   // Calculate new quantity
  //   const newQuantity = this.subAttributeQuantities[subAttribute.id][subOptionId] + change;

  //   // Ensure quantity does not fall below 0 or exceed maxSelectedOptions
  //   if (newQuantity < 0) {
  //     return;
  //   }

  //   // Check total quantity to ensure it doesn't exceed maxSelectedOptions
  //   const totalQuantity = this.getTotalQuantity2(subAttribute.id) + change;
  //   if (totalQuantity > subAttribute.maxSelectedOptions) {
  //     return;
  //   }

  //   // Update the quantity
  //   this.subAttributeQuantities[subAttribute.id][subOptionId] = newQuantity;

  //   console.log(this.subAttributeQuantities);
  // }
  // getTotalQuantity2(detailId: number): number {
  //   return Object.values(this.subAttributeQuantities[detailId] || {}).reduce(
  //     (acc, quantity) => acc + quantity,
  //     0
  //   );
  // }

  // getOptionQuantity(subAttributeId: string, subOptionId: string): number {
  //   return this.subAttributeQuantities[subAttributeId]?.[subOptionId] || 0;
  // }
  updateSubAttributeQuantities(
    subAttribute: any,
    subOptionId: string,
    change: number
  ): void {
    // Initialize subAttributeQuantities if not present
    if (!this.subAttributeQuantities[subAttribute.id]) {
      this.subAttributeQuantities[subAttribute.id] = {};
    }

    // Initialize subOption quantity if not present
    // if (!this.subAttributeQuantities[subAttribute.id]) {
    //   this.subAttributeQuantities[subAttribute.id] = 0;
    // }
    if (!this.subAttributeQuantities[subAttribute.id][subOptionId]) {
      this.subAttributeQuantities[subAttribute.id][subOptionId] = 0;
    }
    // Calculate new quantity
    // const newQuantity = this.subAttributeQuantities[subAttribute.id] + change;
    const currentQuantity =
      this.subAttributeQuantities[subAttribute.id][subOptionId];
    const newQuantity = currentQuantity + change;
    // Ensure quantity does not fall below minSelectedOptions or exceed maxSelectedOptions
    if (newQuantity < subAttribute.minSelectedOptions) {
      return; // Prevent decreasing below minSelectedOptions
    }

    // Check total quantity to ensure it doesn't exceed maxSelectedOptions
    const val = +$(`#tarik${subAttribute.id}`).text();
    const totalQuantity = val + change;
    if (totalQuantity > subAttribute.maxSelectedOptions) {
      return; // Prevent exceeding maxSelectedOptions
    }

    // Update the quantity
    this.subAttributeQuantities[subAttribute.id][subOptionId] = newQuantity;
    let op = subAttribute.attributeOptions.find((a) => a.id == subOptionId);

    if (change == -1) {
      this.fullPrice -= op.price;
    } else this.fullPrice += op.price;
    console.log(this.subAttributeQuantities);
  }
  // getTotalQuantity2(detailId: number,subOptionId): number {
  //   return Object.values(this.subAttributeQuantities[detailId][subOptionId] || 0).reduce(
  //     (acc, quantity) => acc + quantity,
  //     0
  //   );
  // }
  getTotalQuantity2(subAttributeId: string, subOptionId): number {
    const subAttributeQuantities = this.subAttributeQuantities[subAttributeId];
    if (!subAttributeQuantities) return 0;

    let totalQuantity = 0;
    for (const key in subAttributeQuantities) {
      if (subAttributeQuantities.hasOwnProperty(key)) {
        totalQuantity += subAttributeQuantities[key];
      }
    }

    return totalQuantity;
  }
  // getOptionQuantity(subAttributeId: string, subOptionId: string): number {
  //   return this.subAttributeQuantities[subAttributeId] || 0;
  // }
  getOptionQuantity(subAttributeId: string, subOptionId: string): number {
    return this.subAttributeQuantities[subAttributeId]?.[subOptionId] || 0;
  }

  addonProducts = [];
  minOrderPrice = 0;
  getData() {
    this.catalogService.getCompanyFullDetails(this.id).subscribe((res) => {
      this.company = res.data;
      console.log(this.company)
      this.minOrderPrice = this.company.minOrderPrice;
      this.location = res.data.latLng.split(',').map(Number);
      this.companyReviews = res.data.reviews;
      this.companyProducts = res.data.categories.map((category) => ({
        id: category.id,
        name: category.name,
        image: category.image,
        products: category.products,
      }));
      res.data.categories.forEach((category) => {
        category.products.forEach((product) => {
          // Find the main image
          const mainImage = product.productImages.find((image) => image.isMain);
          console.log(mainImage);
          // Create a new object with the desired properties
          const extractedProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            image: mainImage ? mainImage.image : 'assets/img/shop-1.png',
          };

          // Push the new object to addonProducts
          this.addonProducts.push(extractedProduct);
        });
      });
      console.log(this.addonProducts);
      // this.addDynamicStyles();
    });
    // this.catalogService
    //   .getCompaniesCategoriesAndProducts(this.id)
    //   .subscribe((res) => {
    //     this.companyProducts = res.data;
    //   });
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
  }
  getarr(length: number) {
    return new Array(Math.round(length));
  }
  getCompanyProductId(ids) {
    // console.log(ids);
    let el = document.getElementById('shoppingBox' + ids[0]);
    el.scrollIntoView({ behavior: 'smooth' });
  }
  open(content: any, id) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'modal-max',
    });
  }

  calculateFullTotal(type = 1) {
    //1 minus
    let quantity = +$('.input-group-field').val();
    let fullPriceValue = +$('#fullPriceValue').val();
    // this.fullPrice = 0;
    let total = 0;
    // let extraDetails = '<div class="d-flex flex-column">';

    // // Calculate total for radio button groups (type 0)
    // this.attributeDetails.forEach((detail) => {
    //   if (detail.type === 0) {
    //     $(`#addItemToBasket input[name="radiofirst${detail.id}"]:checked`).each(
    //       function () {
    //         total += parseInt($(this).val(), 10);
    //         let idVal = $(this).attr('id');
    //         let labelText = $("label[for='" + idVal + "']")
    //           .text()
    //           .trim()
    //           .split(' (')[0];
    //         extraDetails += `<p class='text-style-details'>${detail.name}: ${labelText}</p>`;
    //       }
    //     );
    //   }
    // });

    // extraDetails += '\n';

    // // Calculate total for main options (radiotree)
    // $('input[type="radio"][id^="radiotree"]:checked').each(function () {
    //   total += parseInt($(this).val(), 10);
    //   let idVal = $(this).attr('id');
    // });

    // extraDetails += '\n';

    // // Calculate total for sub-options (subRadiotree)
    // $('input[type="radio"][id^="subRadiotree"]:checked').each(function () {
    //   total += parseInt($(this).val(), 10);
    //   let idVal = $(this).attr('id');
    // });

    // extraDetails += '\n';

    // // Calculate total for sub-checkbox options (subCheckboxtree)
    // $('input[type="checkbox"][id^="subCheckboxtree"]:checked').each(
    //   function () {
    //     total += parseInt($(this).val(), 10);
    //     let idVal = $(this).attr('id');
    //   }
    // );

    // extraDetails += '\n';

    // // Handle detail type 1 (quantity-based options)
    // this.attributeDetails.forEach((detail) => {
    //   if (detail.type === 1) {
    //     let detailText = `${detail.name}: `;
    //     let hasSelectedOptions = false;
    //     detail.attributeOptions.forEach((option) => {
    //       const optionQuantity =
    //         this.optionQuantities[detail.id]?.[option.id] || 0;
    //       if (optionQuantity > 0) {
    //         hasSelectedOptions = true;
    //         detailText += `${optionQuantity} * ${option.name}, `;
    //         total += optionQuantity * option.price;
    //       }
    //     });
    //     if (hasSelectedOptions) {
    //       extraDetails += detailText.trim().slice(0, -1) + '. ';
    //     }
    //   } else if (detail.type === 2) {
    //     $(`#addItemToBasket input[name="radiotree${detail.id}"]:checked`).each(
    //       function () {
    //         total += parseInt($(this).val(), 10);
    //         let idVal = $(this).attr('id');
    //         let mainOptionText = $("label[for='" + idVal + "']")
    //           .text()
    //           .trim()
    //           .split(' (')[0];

    //         let subDetails = '';
    //         const subOptions = $(this)
    //           .closest('.form-checkbox')
    //           .next()
    //           .find(
    //             'input[name^="subRadiotree"]:checked, input[name^="subCheckboxtree"]:checked'
    //           );
    //         subOptions.each(function () {
    //           let subIdVal = $(this).attr('id');
    //           subDetails +=
    //             $("label[for='" + subIdVal + "']")
    //               .text()
    //               .trim()
    //               .split(' (')[0] + ', ';
    //         });

    //         if (subDetails) {
    //           subDetails = `(${subDetails.slice(0, -2)})`; // Remove trailing comma and wrap in parentheses
    //         }
    //         extraDetails += `<p class='text-style-details'>${detail.name}: ${mainOptionText} ${subDetails}</p>`;
    //       }
    //     );
    //   }
    // });

    // extraDetails += '</div>';

    // // Calculate total for addons
    // let addOns = '';
    // for (const addonId in this.addonQuantities) {
    //   if (!addOns) addOns = 'Add-Ons: ';
    //   const addon = this.addonProducts.find((a) => a.id === +addonId);
    //   if (addon) {
    //     const addonQuantity = this.addonQuantities[addonId];
    //     total += addon.price * addonQuantity;
    //     addOns += `${addon.name} * ${addonQuantity}, `;
    //   }
    // }

    // this.fullPrice = (this.price + total) * quantity;

    // if (addOns) {
    //   // extraDetails += addOns;
    // }

    // // Clean up extraDetails by removing trailing comma
    // extraDetails = extraDetails.trim().replace(/,\s*$/, '');
    // this.extraDetails = extraDetails.replace(/,\s*$/, '');
    if (type == 1) {
      this.fullPrice = (this.fullPrice * quantity) / (quantity + 1);
    } else {
      this.fullPrice = (this.fullPrice * quantity) / (quantity - 1);
    }
    // this.fullPrice = fullPriceValue * quantity;
    // Log the results for debugging
    console.log('Full Price:', this.fullPrice);
  }

  removeBetweenParentheses(input: string): string {
    return input.replace(/\(([^)]*)\)/g, '()');
  }

  removeTrailingSpaces(input: string): string {
    return input.trim();
  }
  toggleFavorite() {
    this.favoriteService
      .addCompanyToFavorites(this.id, !this.fav)
      .subscribe((res) => {
        if (res.isSuccess) this.fav = !this.fav;
      });
  }
  getDeliveryCost() {
    let latlng =
      localStorage.getItem('lat') + ',' + localStorage.getItem('lng');
    if (localStorage.getItem('lat') && localStorage.getItem('lng'))
      this.authService.getDeliveryCost(this.id, latlng).subscribe((res) => {
        this.cost = res.data;
        console.log(this.cost);
        localStorage.setItem('deliveryCost', res.data);
      });
  }
  getUpdatedOrders(orders: any[]): void {
    this.orders = orders;
    console.log('Received updated orders:', this.orders);
    // Additional logic to update the company page if needed
  }
  plusMinusBtn(type, isBasket = false) {
    if (!isBasket) {
      let fieldName = $('.hollow').attr('data-field');
      const input =
        document.querySelector<HTMLInputElement>('.input-group-field');

      if (type == 1) {
        if (!isNaN(+input.value)) {
          console.log('t');
          console.log(+input.value - 1);
          // Decrease
          if (+input.value == 1) $('input[name=' + fieldName + ']').val(1);
          else $('input[name=' + fieldName + ']').val(+input.value - 1);
        } else {
          // Otherwise put a 1 there
          $('input[name=' + fieldName + ']').val(1);
        }
        this.calculateFullTotal();
      } else {
        if (!isNaN(+input.value)) {
          $('input[name=' + fieldName + ']').val(+input.value + 1);
        } else {
          // Otherwise put a 0 there
          $('input[name=' + fieldName + ']').val(1);
        }
        this.calculateFullTotal(2);
      }
    } else {
      let fieldName = $('.hollow-basket').attr('data-field');
      const input = document.querySelector<HTMLInputElement>(
        '.input-group-field-basket'
      );

      if (type == 1) {
        if (!isNaN(+input.value)) {
          // Decrease
          if (+input.value == 1) $('input[name=' + fieldName + ']').val(1);
          else $('input[name=' + fieldName + ']').val(+input.value - 1);
        } else {
          // Otherwise put a 1 there
          $('input[name=' + fieldName + ']').val(1);
        }
      } else {
        if (!isNaN(+input.value)) {
          // Increment
          $('input[name=' + fieldName + ']').val(+input.value + 1);
        } else {
          // Otherwise put a 0 there
          $('input[name=' + fieldName + ']').val(1);
        }
      }
    }
  }
  // getCheckedOptions
  makeOrderBtn() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 992) {
      let mo = document.querySelector('#makeOrder');
      document.querySelector('#content').classList.toggle('d-flex');
      document.querySelector('#content').classList.toggle('d-none');
      document.querySelector('#sideBar').classList.toggle('showSideBar');
    }
  }
  keyupFun(event, index) {
    var characterCount = event.target.value.length;
    const current = document.querySelector<HTMLElement>('#current' + index);
    const maximum = document.querySelector<HTMLElement>('#maximum' + index);
    const theCount = document.querySelector<HTMLElement>('#the-count' + index);

    current.innerText = characterCount;
    if (characterCount < 70) {
      current.style.color = '#666';
    }
    if (characterCount > 70 && characterCount < 90) {
      current.style.color = '#6d5555';
    }
    if (characterCount > 90 && characterCount < 100) {
      current.style.color = '#793535';
    }
    if (characterCount > 100 && characterCount < 120) {
      current.style.color = '#841c1c';
    }
    if (characterCount > 120 && characterCount < 139) {
      current.style.color = '#8f0001';
    }

    if (characterCount >= 140) {
      maximum.style.color = '#8f0001';
      current.style.color = '#8f0001';
      theCount.style.fontWeight = 'bold';
    } else {
      maximum.style.color = '#666';
      theCount.style.fontWeight = 'normal';
    }
  }
}
