import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseResult } from 'src/app/models-classes/common.model';
import { InterceptorSkipHeader } from 'src/app/shared/constants/global-variable.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  api_url = environment.api.baseUrl;

  headers = new HttpHeaders().set(InterceptorSkipHeader, '');

  constructor(private _http: HttpClient) {
  }
  myFavoriteCompanies(shopType: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + '/Favorites/FavoriteCompanies?shopType=' + shopType, { headers: this.headers });
  }
  myFavoriteProducts(shopType: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + '/Favorites/FavoriteProducts?shopType=' + shopType, { headers: this.headers });
  }
  addProductToFavorites(productId: number, isFavorite: boolean): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + `/Favorites/AddProductToFavorites?productId=${productId}&isFavorite=${isFavorite}`, { headers: this.headers });
  }
  addCompanyToFavorites(companyId: number, isFavorite: boolean): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + `/Favorites/AddCompanyToFavorites?companyId=${companyId}&isFavorite=${isFavorite}`, { headers: this.headers });
  }
}
