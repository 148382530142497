import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridViewComponent } from './grid-view.component';
import { GridModule, PagerModule,EditService, PageService, SortService,FilterService,GroupService,ToolbarService, ExcelExportService } from '@syncfusion/ej2-angular-grids';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [GridViewComponent],
  exports:[GridViewComponent],
  imports: [
    CommonModule,
    GridModule,
    PagerModule,
    TranslateModule
  ],
  providers:[
    EditService, PageService, SortService,FilterService,GroupService,ToolbarService,ExcelExportService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class GridViewModule { }
