<app-navbar (SetLatLng)="handleLatLng($event)" (GetCountryId)="getCountryId($event)"></app-navbar>
<app-categories [id]="id" (GetIds)="getGlobalCategoryIds($event)"></app-categories>
<div class="container most-popular section-padding my-2">
    <div class="row">
        <div class="col-lg-4">
            <div class="row">
                <div class="col-lg-12">
                    <h4 class="text-light-black fw-600 title-2">{{'Filters' | translate}}<small class="fs-12"><a (click)="clear()"
                        class="text-light-black fw-500">{{'Clear all' | translate}}</a></small></h4>
                </div>
            </div>
            <div class="filters">
                <div class="card">
                    <div class="card-header"> <a class="card-link text-light-black fw-700 fs-16" data-toggle="collapse"
                            href="#deliverycollapseOne">
                            {{'Feature' | translate}}
                        </a>
                    </div>
                    <div id="deliverycollapseOne" class="collapse show">
                        <div class="card-body">
                            <form>
                                <label class="custom-checkbox">
                                    <input [(ngModel)]="openNow" (change)="changeFeature($event,1)" type="checkbox"
                                        name="#"> <span class="checkmark"></span> {{'Open Now' | translate}}
                                </label>
                                <label class="custom-checkbox">
                                    <input [(ngModel)]="freeDelivery" (change)="changeFeature($event,2)" type="checkbox"
                                        name="#"> <span class="checkmark"></span> {{'Free Delivery' | translate}}
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header"> <a class="card-link text-light-black fw-700 fs-16" data-toggle="collapse"
                            href="#deliverycollapseTwo">
                            {{'Rating' | translate}}
                        </a>
                    </div>
                    <div id="deliverycollapseTwo" class="collapse show">
                        <div class="card-body">
                            <div class="rating">
                                <ngb-rating (rateChange)="onRatingChange($event)" [max]="5"
                                    [(rate)]="currentRate"></ngb-rating>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header"> <a class="card-link text-light-black fw-700 fs-16" data-toggle="collapse"
                            href="#deliverycollapseThree">
                            {{'Minimum amount' | translate}}
                        </a>
                    </div>
                    <div id="deliverycollapseThree" class="collapse show">
                        <div class="card-body">
                            <label class="rad-label">
                                <input checked value="1" (change)="getMinimumOrderAmmount(0)" type="radio"
                                    class="rad-input" name="rad">
                                <div class="rad-design"></div>
                                <div class="rad-text">{{'Show all' | translate}}</div>
                            </label>

                            <label class="rad-label">
                                <input value="2" type="radio" class="rad-input" name="rad"
                                    (change)="getMinimumOrderAmmount(10)">
                                <div class="rad-design"></div>
                                <div class="rad-text">{{'10,00 $ or less' | translate}}</div>
                            </label>

                            <label class="rad-label">
                                <input value="3" type="radio" class="rad-input" name="rad"
                                    (change)="getMinimumOrderAmmount(15)">
                                <div class="rad-design"></div>
                                <div class="rad-text">{{'15,00 $ or less' | translate}}</div>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-8">

        </div>
    </div>
</div>

<app-footer2 id="test"></app-footer2>