import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addrestaurant',
  templateUrl: './addrestaurant.component.html',
  styleUrls: ['./addrestaurant.component.css']
})
export class AddrestaurantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
