import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  show_email_feild = true;
  resetForm: UntypedFormGroup;
  resetConfirmForm: UntypedFormGroup;
  submitted = false;
  submitted1 = false;
  constructor(
    private fb: UntypedFormBuilder,
    private _toast: ToastrService,
    private _router: Router,
    private translateService: TranslateService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  setResetConfirmForm(email) {
    this.resetConfirmForm = this.fb.group({
      email: [email],
      code: ['', [Validators.required]],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[$@^!%*?&])'), {
            requiresSpecialChars: true,
          }),
        ]),
      ],
    });
  }
  get f() {
    return this.resetForm.controls;
  }
  get f1() {
    return this.resetConfirmForm.controls;
  }

  get passwordValid() {
    return this.resetConfirmForm.controls['password'].errors === null;
  }

  get requiredValid() {
    return !this.resetConfirmForm.controls['password'].hasError('required');
  }

  get minLengthValid() {
    return !this.resetConfirmForm.controls['password'].hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresDigit'
    );
  }

  get requiresUppercaseValid() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresUppercase'
    );
  }

  get requiresLowercaseValid() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresLowercase'
    );
  }

  get requiresSpecialCharsValid() {
    return !this.resetConfirmForm.controls['password'].hasError(
      'requiresSpecialChars'
    );
  }
  onSubmit(data, formDirective: FormGroupDirective) {
    this.submitted = true;
    if (!this.resetForm.valid) return;
    this._authService
      .sendResetPasswordConfirmation(data.email)
      .subscribe((res) => {
        if (res.isSuccess) {
          this.setResetConfirmForm(data.email);
          this._toast.success(
            this.translateService.instant(
              'The activation code has been sent again. Please check your email'
            )
          );
          this.show_email_feild = false;
        } else {
          const translatedErrors = res.errors.map((error) =>
            this.translateService.instant(error)
          );
          const errorMessage = translatedErrors.join('\n');
          this._toast.error(errorMessage);
        }
      });
  }
  confirmReset(data, formDirective: FormGroupDirective) {
    this.submitted1 = true;
    if (!this.resetConfirmForm.valid) return;
    this._authService
      .resetPassword(data.email, data.password, data.code)
      .subscribe((res) => {
        if (res.isSuccess) {
          this._toast.success(
            this.translateService.instant('Password changed successfully')
          );
          this._router.navigate(['/login']);
        } else
          this._toast.error(
            this.translateService.instant('Some thing went wrong')
          );
      });
  }
}
