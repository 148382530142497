
<app-navbar (GetAddress)="getAddress($event)" (GetCountry)="getCountry($event)" (GetCountryId)="getCountryId($event)"></app-navbar>

<section class="final-order pt-2 bg-light-theme">
    <div class="container-fluid">
        <div class="row parent">
            <div class="col-12 col-lg-9">
                <!-- <div class="main-box padding-20"> -->
                <div class="container">
                    <div class="row mb-xl-20">
                        <div class="col-md-12">
                            <div class="section-header-left">
                                <div class="d-flex justify-content-between align-items-baseline">
                                    <h3 class="text-light-black header-title fw-700">{{'Checkout' | translate}}</h3>
                                <div>
                                    <button id="showSideBarBtn" class="btn btn-warning" (click)="toggleSideBar()"><i class='bx bx-basket text-white'></i></button>
                                </div>
                                </div>
                                <p class="text-light-black font-weight-bold text-success">{{orders[0].companyName}}</p>
                            </div>
                            <div class="checkout-card">
                                <div *ngIf="!(loginStatus$ | async)" class="d-flex">
                                    <p [ngClass]="current_lang == 'en' ? 'mr-auto' : 'ml-auto'"
                                        class="text-light-black">{{'I already have an account' | translate}}</p>
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#authModal"
                                        class="btn-border">{{'Sign In' | translate}}</a>
                                </div>
                                <hr *ngIf="!(loginStatus$ | async)">
                                <!-- <div *ngIf="(loginStatus$ | async)" class="mb-4">
                                    <a (click)="getLastAddress()" href="javascript:void(0)" data-toggle="modal" data-target="#lastAddressModal"
                                        class="btn-border">{{'Get My Last Address' | translate}}</a>
                                </div> -->
                                <h3 class="text-light-black fw-600 fs-14">{{'Delivery address' | translate}}</h3>
                                <div class="general-sec">
                                    <form #placeOrder [formGroup]="placeOrderForm"
                                        (ngSubmit)="createOrder(placeOrderForm.value,placeOrder)">
                                        <div class="row">
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="addressName" class="text-light-black fw-700">{{'Address Name' | translate}}
                                                    </label>
                                                    <input id="addressName" type="text"
                                                        class="form-control form-control-submit"
                                                        formControlName="addressName">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.addressName.dirty) && p.addressName.errors || p.addressName.touched">
                                                        <div class="text-align-left"
                                                            *ngIf="p.addressName.errors?.required">
                                                            {{'Address Name' | translate}} {{'validations.isrequired' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="country" class="text-light-black fw-700">{{'Country' | translate}}
                                                    </label>
                                                    <input id="country" type="text"
                                                        class="form-control form-control-submit"
                                                        formControlName="country">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.country.dirty) && p.country.errors || p.country.touched">
                                                        <div class="text-align-left"
                                                            *ngIf="p.country.errors?.required">
                                                            {{'Country' | translate}} {{'validations.isrequired' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="city" class="text-light-black fw-700">{{'City' | translate}}
                                                    </label>
                                                    <input id="city" type="text"
                                                        class="form-control form-control-submit"
                                                        formControlName="city">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.city.dirty) && p.city.errors || p.city.touched">
                                                        <div class="text-align-left"
                                                            *ngIf="p.city.errors?.required">
                                                            {{'City' | translate}} {{'validations.isrequired' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="street" class="text-light-black fw-700">{{'Street name' | translate}}
                                                    </label>
                                                    <input id="street" type="text"
                                                        class="form-control form-control-submit"
                                                        formControlName="street">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.street.dirty) && p.street.errors || p.street.touched">
                                                        <div class="text-align-left"
                                                            *ngIf="p.street.errors?.required">
                                                            {{'Street Name' | translate}} {{'validations.isrequired' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="region" class="text-light-black fw-700">{{'Region' | translate}}
                                                        {{'Optional' | translate}}</label>
                                                    <input formControlName="region" id="region" type="text"
                                                        name="#" class="form-control form-control-submit">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="buildingNumber" class="text-light-black fw-700">{{'Building Number' | translate}}</label>
                                                    <input formControlName="buildingNumber" id="buildingNumber" type="text"
                                                        name="#" class="form-control form-control-submit">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.buildingNumber.touched) && p.buildingNumber.errors">
                                                        <div class="text-align-left"
                                                            *ngIf="p.buildingNumber.errors.required">
                                                            {{'Building Number' | translate}} {{'validations.isrequired' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="apartmentNumber" class="text-light-black fw-700">{{'Appartment Number'
                                                        | translate}}</label>
                                                    <input formControlName="apartmentNumber" id="apartmentNumber" type="text" name="#"
                                                        class="form-control form-control-submit">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.apartmentNumber.touched) && p.apartmentNumber.errors">
                                                        <div class="text-align-left" *ngIf="p.apartmentNumber.errors.required">
                                                            {{'Appartment Number' | translate}} {{'validations.isrequired' |
                                                            translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="city" class="text-light-black fw-700">{{'City' |
                                                        translate}}</label>
                                                    <select formControlName="city" id="city" class="form-control form-control-submit full-width">
                                                        <option value="0">{{'Select City' | translate}}</option>
                                                        <option *ngFor="let city of cities" [value]="city.id">{{city.name}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="floor" class="text-light-black fw-700">{{'Floor' |
                                                        translate}} {{'Optional' | translate}}</label>
                                                    <input formControlName="floor" id="floor" type="text" name="#"
                                                        class="form-control form-control-submit">
                                                </div>
                                            </div> -->
                                            
                                            
                                            <!-- <div class="col-12">
                                                <hr>
                                                <h3 class="text-light-black fw-600 fs-14">{{'Personal details' |
                                                    translate}}</h3>
                                            </div> -->
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="firstLastName" class="text-light-black fw-700">{{'First and last name' | translate}}
                                                    </label>
                                                    <input formControlName="firstLastName" id="firstLastName"
                                                        type="text" name="#" class="form-control form-control-submit">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.firstLastName.touched) && p.firstLastName.errors">
                                                        <div class="text-align-left"
                                                            *ngIf="p.firstLastName.errors.required">
                                                            {{'First Last Name' | translate}} {{'validations.isrequired'
                                                            |
                                                            translate}}</div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="mail" class="text-light-black fw-700">{{'Email' |
                                                        translate}}</label>
                                                    <input formControlName="mail" id="mail" type="email" name="#"
                                                        class="form-control form-control-submit">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.mail.touched) && p.mail.errors">
                                                        <div class="text-align-left" *ngIf="p.mail.errors.required">
                                                            {{'Email' | translate}} {{'validations.isrequired' |
                                                            translate}}</div>
                                                            <div class="text-align-left" *ngIf="p.mail.errors.email">
                                                                {{'validations.invalid_email' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="phoneNumber" class="text-light-black fw-700">{{'Phone' |
                                                        translate}}
                                                    </label>
                                                    <input formControlName="phoneNumber" id="phoneNumber" type="text"
                                                        name="#" class="form-control form-control-submit">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.phoneNumber.touched) && p.phoneNumber.errors">
                                                        <div class="text-align-left"
                                                            *ngIf="p.phoneNumber.errors.required">
                                                            {{'Phone' | translate}} {{'validations.isrequired' |
                                                            translate}}</div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-md-6">
                                                <label>{{'Phone' | translate}}</label>
                                                <div class="input-group">
                                                  <mat-form-field appearance="fill" class="w-100">
                                                    <ngx-mat-intl-tel-input formControlName="phoneNumber" [enablePlaceholder]="true"
                                                      [preferredCountries]="['kw', 'jo']" [enableSearch]="true"
                                                      searchPlaceholder="{{'Search country' | translate}}" [cssClass]="'custom form-control'"
                                                      name="phoneNumber"></ngx-mat-intl-tel-input>
                                                    <mat-error *ngIf="(submitted || p.phoneNumber.dirty) && p.phoneNumber.errors">
                                                      <ng-container *ngIf="p.phoneNumber.errors.required; else invalidPhone">
                                                        <div class="text-align-left">
                                                          {{'PhoneNumber' | translate}} {{'validations.isrequired' | translate}}
                                                        </div>
                                                      </ng-container>
                                                      <ng-template #invalidPhone>
                                                        <div class="text-align-left" *ngIf="p.phoneNumber.errors.invalidPhoneNumber">
                                                          {{'Phone' | translate}} {{'validations.notvalid' | translate}}
                                                        </div>
                                                      </ng-template>
                                                    </mat-error>
                                                  </mat-form-field>
                                                </div>
                                              </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="addressName" class="text-light-black fw-700">{{'Address Name' | translate}}
                                                    </label>
                                                    <input formControlName="addressName" id="addressName" type="text"
                                                        name="#" class="form-control form-control-submit">
                                                    <div class="invalid-feedback"
                                                        *ngIf="(submittedPlace || p.addressName.touched) && p.addressName.errors">
                                                        <div class="text-align-left"
                                                            *ngIf="p.addressName.errors.required">
                                                            {{'Address Name' | translate}} {{'validations.isrequired' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-4">
                                                <div class="form-group">
                                                    <label for="notes" class="text-light-black fw-700">{{'Add note' |
                                                        translate}}
                                                        {{'Optional' | translate}}</label>
                                                    <input formControlName="notes" id="notes" type="text" name="#"
                                                        class="form-control form-control-submit">
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="latLng" class="text-light-black fw-700">{{'latLng' |
                                                        translate}}
                                                        {{'Optional' | translate}}</label>
                                                    <input formControlName="latLng" id="latLng" type="text" name="#"
                                                        class="form-control form-control-submit">
                                                </div>
                                            </div> -->
                                                <div class="col-md-12 mb-2 d-none">
                                                    <div style="position: relative;">
                                                        <div #mapContainer style="height: 400px; width: 100%;"></div>
                                                        <input formControlName="searchAddress" (input)="searchLocation($event)"
                                                            class="search-location" type="text" placeholder="Search for location" />
                                                    </div>
                                                </div>
                                            <!-- <div class="col-12">
                                                <label class="custom-checkbox text-light-black fs-12">
                                                    <input formControlName="isSaveAddress" type="checkbox" name="#"> <span
                                                        class="checkmark"></span>{{'Save Remarks' | translate}}</label>
                                            </div>
                                            <div class="col-md-12">
                                                <p class="fs-12 font-color-place-order">{{'Receive Confirmation'|
                                                    translate}}</p>
                                            </div> -->
                                        </div>
                                        <!-- <div class="row">
                                            <div class="col-12">
                                                <div class="u-line mb-xl-20"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <h5 class="text-light-black fw-700">Location</h5>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Country
                                                    </label>
                                                    <select class="form-control form-control-submit custom-select-2 full-width">
                                                        <option value="">Select Country</option>
                                                        <option value="brazil" data-name="">Brazil</option>
                                                        <option value="canada" data-name="">Canada</option>
                                                        <option value="france" data-name="">France</option>
                                                        <option value="india" data-name="">India</option>
                                                        <option value="italy" data-name="">Italy</option>
                                                        <option value="spain" data-name="">Spain</option>
                                                        <option value="united-kingdom" data-name="">United Kingdom</option>
                                                        <option value="united-states" data-name="">United States</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">State</label>
                                                    <select class="form-control form-control-submit custom-select-2 full-width">
                                                        <option value="">Select State</option>
                                                        <option value="uttar-pradesh">Uttar Pradesh</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">City</label>
                                                    <select class="form-control form-control-submit custom-select-2 full-width">
                                                        <option value="">Select City</option>
                                                        <option value="uttar-pradesh">Uttar Pradesh</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Latitude</label>
                                                    <input type="text" name="#" class="form-control form-control-submit" value="40.4167754">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Longitude</label>
                                                    <input type="text" name="#" class="form-control form-control-submit" value="-3.7037901999999576">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Find On Map</label>
                                                    <input type="text" name="#" class="form-control form-control-submit" placeholder="Type Your Address">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <div class="form-group"></div>
                                                    <button type="submit" class="btn-second btn-submit">Search Location</button>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <iframe id="locmap" class="w-100" height="300" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="u-line mb-xl-20"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Delivery/Pickup</label>
                                                    <select class="form-control form-control-submit custom-select-2 full-width">
                                                        <option value="delivery">Delivery</option>
                                                        <option value="pickup">Pickup</option>
                                                        <option value="delivery_and_pickup">Delivery &amp; Pickup</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Cuisines <sub>*</sub>
                                                    </label>
                                                    <input type="text" class="form-control form-control-submit" placeholder="Cuisines">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="u-line mb-xl-20"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <h5 class="text-light-black fw-700">Account Information</h5>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Username</label>
                                                    <input type="text" class="form-control form-control-submit" placeholder="Username">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-700">Email</label>
                                                    <input type="email" class="form-control form-control-submit" placeholder="Email I'D">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="u-line mb-xl-20"></div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="custom-checkbox text-light-black fw-700">
                                                    <input type="checkbox" name="#"> <span class="checkmark"></span>By Registering You Confirm That You Accept The Terms & Conditions And Privacy Policy</label>
                                            </div>
                                        </div> -->
                                    </form>
                                </div>
                                <!-- <h6 class="text-light-black fw-700 fs-14">Review address, payments, and tip to complete
                                    your purchase</h6>
                                <h6 class="text-light-black fw-700 mb-2">Your order setting</h6>
                                <p class="text-light-green fw-600">Delivery, ASAP (60-70m)</p>
                                <p class="text-light-white title2 mb-1">Jhon Deo <span><a routerLink="/checkout">Change
                                            Details</a></span>
                                </p>
                                <p class="text-light-black fw-600 mb-1">Home</p>
                                <p class="text-light-white mb-1">314 79th st 70 Brooklyn, NY 11209
                                    <br>Cross Street, Rite Aid
                                </p>
                                <p class="text-light-white">(347) 1234567890</p> -->
                            </div>
                            <!-- <a href="javascript:void(0)">
                                <div class="mt-3 checkout-card2">
                                    <div class="d-flex">
                                        <div class="p-2">
                                            <span class="fa fa-clock"></span>
                                        </div>
                                        <div class="p-2 d-flex flex-column">
                                            <h5>{{'Delivery Time' | translate}}</h5>
                                            <span>{{'Estimated arrival' | translate}}: 15-40 min</span>
                                        </div>
                                        <div [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'" class="p-2">
                                            <span class="fa fa-check-circle"></span>
                                        </div>
                                    </div>
                                </div>
                            </a> -->
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#paymentMethodsModal">
                               <div class="mt-3 checkout-card2">
                                <div class="d-flex">
                                    <div class="p-2">
                                        <span class="fa fa-credit-card"></span>
                                    </div>
                                    <div class="p-2 d-flex flex-column">
                                        <h5>{{'Pay With' | translate}}</h5>
                                        <span>{{paymentMethodText | translate}}</span>
                                    </div>
                                    <div [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'" class="p-2">
                                        <span class="text-danger fa fa-exclamation-triangle"></span>
                                    </div>
                                </div>
                                </div> 
                            </a>
                            <!-- Alert Element -->
                            <div *ngIf="paymentMethodText != 'Select your payment method'" class="alert alert-info font-weight-bold text-style-right my-1">
                                {{paymentMethodText | translate}}
                            </div>

                            <!-- <div class="checkout-card2 mt-3">
                                <div>
                                    <a class="collapsed card-link fw-500 fs-14"
                                        data-toggle="collapse" data-parent="#collapsePromoCode">
                                        Add a promo code
                                    </a>
                                </div>
                                <div id="collapsePromoCode" class="collapse" data-parent="#accordion">
                                    <div class="card-body no-padding payment-option-tab">
                                        <div class="form-group">
                                            <div class="credit-card promocode p-relative input-group">
                                                <input type="text" name="#"
                                                    class="form-control-submit fs-16"
                                                    placeholder="AC2B76">
                                                <button type="submit"
                                                    class="btn-second btn-submit ml-1">Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-12 mt-3">
                                <div class="form-group">
                                    <label class="custom-checkbox">
                                        <input type="checkbox" name="#"> <span class="checkmark"></span> {{'Receive Discounts' | translate}}</label>
                                </div>
                            </div> -->
                            <div class="form-group my-2">
                                <label class="custom-checkbox">
                                    {{'Agree Pay' | translate}} <a routerLink="/terms">{{'Terms of use'
                                        |translate}}</a> <span class="ml-2"></span>
                                </label>
                            </div>
                            <div class="form-group">
                                <button type="button" (click)="placeYourOrder()"
                                    class="btn-first green-btn text-custom-white full-width fw-500">{{'place your order'
                                    | translate}}</button>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                                <div class="advertisement-img">
                                    <img src="assets/img/checkout.jpg" class="img-fluid full-width" alt="advertisement-img">
                                </div>
                            </div> -->
                    </div>
                    <!-- <div class="row">
                        <div class="col-12">
                            <div class="payment-sec">
                                <div class="section-header-left">
                                    <h3 class="text-light-black header-title">Delivery Instructions</h3>
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control form-control-submit" rows="4"
                                        placeholder="Delivery Details"></textarea>
                                </div>
                                <div class="form-group">
                                    <label class="custom-checkbox">
                                        <input type="checkbox" name="#"> <span class="checkmark"></span> Spare me the
                                        napkins and plasticware. I'm trying to save the earth.</label>
                                </div>
                                <div class="section-header-left">
                                    <h3 class="text-light-black header-title">Payment information</h3>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div id="accordion">
                                            <div class="payment-option-tab">
                                                <ul class="nav nav-tabs">
                                                    <li class="nav-item"> <a class="nav-link fw-600 active"
                                                            data-toggle="tab" href="#savecreditcard">Saved credit
                                                            card</a>
                                                    </li>
                                                    <li class="nav-item"> <a class="nav-link fw-600" data-toggle="tab"
                                                            href="#newcreditcard">New credit card</a>
                                                    </li>
                                                    <li class="nav-item"> <a class="nav-link fw-600" data-toggle="tab"
                                                            href="#cash">Cash</a>
                                                    </li>
                                                    <li class="nav-item"> <a class="nav-link fw-600" data-toggle="tab"
                                                            href="#paypal">PayPal<sup>TM</sup></a>
                                                    </li>
                                                    <li class="nav-item"> <a class="nav-link fw-600" data-toggle="tab"
                                                            href="#amexcheckout">Amex Express Checkout</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content">
                                                    <div class="tab-pane active" id="savecreditcard">
                                                        <div class="form-group">
                                                            <div class="credit-card p-relative">
                                                                <input type="text" name="#"
                                                                    class="form-control form-control-submit"
                                                                    value="VISA....1877,exp 12/21">
                                                            </div>
                                                        </div>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Add a tip for your
                                                                driver</h3>
                                                        </div>
                                                        <div class="driver-tip-sec mb-xl-20">
                                                            <ul class="nav nav-tabs">
                                                                <li class="nav-item"> <a class="nav-link fw-600 active"
                                                                        data-toggle="tab" href="#savetipcard">Tip with
                                                                        Credit Card</a>
                                                                </li>
                                                                <li class="nav-item"> <a
                                                                        class="nav-link fw-600 disabled"
                                                                        data-toggle="tab" href="#cashtip">Tip with
                                                                        Cash</a>
                                                                </li>
                                                            </ul>
                                                            <div class="tab-content">
                                                                <div class="tab-pane active" id="savetipcard">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="tip-percentage">
                                                                                <form>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage"
                                                                                            checked> <span>15%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>30%</span>
                                                                                    </label>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="custom-tip">
                                                                                <div class="input-group mb-3">
                                                                                    <div class="input-group-prepend">
                                                                                        <span
                                                                                            class="input-group-text text-light-green fw-500">Custom
                                                                                            tip</span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                        class="form-control form-control-submit"
                                                                                        placeholder="Custom tip">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Donate the change
                                                            </h3>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" name="#"> <span
                                                                    class="checkmark"></span>
                                                                Donate $0.77 to No kid Hungry. By checking this box you
                                                                agree to the Donate the Change <a
                                                                    routerLink="/checkout">Terms of use</a> <span
                                                                    class="ml-2"><a routerLink="/checkout">Learn
                                                                        More</a></span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <button type="submit"
                                                                class="btn-first green-btn text-custom-white full-width fw-500">Place
                                                                Your Order</button>
                                                        </div>
                                                        <p class="text-center text-light-black no-margin">By placing
                                                            your order, you agree to quickmunch's <a
                                                                routerLink="/checkout">terms of use</a> and <a
                                                                routerLink="/checkout">privacy agreement</a>
                                                        </p>
                                                    </div>
                                                    <div class="tab-pane fade" id="newcreditcard">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <div class="form-group">
                                                                    <label class="text-light-white fw-700">Card
                                                                        Number</label>
                                                                    <div class="credit-card card-front p-relative">
                                                                        <input type="text" name="#"
                                                                            class="form-control form-control-submit"
                                                                            placeholder="1234 5678 9101 1234">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <div class="form-group">
                                                                    <label class="text-light-white fw-700">Expires
                                                                        on</label>
                                                                    <input type="text" name="#"
                                                                        class="form-control form-control-submit"
                                                                        placeholder="12/21">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label class="text-light-white fw-700">Security
                                                                        Code</label>
                                                                    <div class="credit-card card-back p-relative">
                                                                        <input type="text" name="#"
                                                                            class="form-control form-control-submit"
                                                                            placeholder="123">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label class="text-light-white fw-700">ZIP
                                                                        Code</label>
                                                                    <input type="text" name="#"
                                                                        class="form-control form-control-submit"
                                                                        placeholder="123456">
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="custom-checkbox">
                                                                        <input type="checkbox" name="#"> <span
                                                                            class="checkmark"></span>
                                                                        Save Credit card</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Add a tip for your
                                                                driver</h3>
                                                        </div>
                                                        <div class="driver-tip-sec mb-xl-20">
                                                            <ul class="nav nav-tabs">
                                                                <li class="nav-item"> <a class="nav-link fw-600 active"
                                                                        data-toggle="tab" href="#tipnewcard">Tip with
                                                                        Credit Card</a>
                                                                </li>
                                                                <li class="nav-item"> <a
                                                                        class="nav-link fw-600 disabled"
                                                                        data-toggle="tab" href="#newcashtip">Tip with
                                                                        Cash</a>
                                                                </li>
                                                            </ul>
                                                            <div class="tab-content">
                                                                <div class="tab-pane active" id="tipnewcard">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="tip-percentage">
                                                                                <form>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage"
                                                                                            checked> <span>15%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>30%</span>
                                                                                    </label>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="custom-tip">
                                                                                <div class="input-group mb-3">
                                                                                    <div class="input-group-prepend">
                                                                                        <span
                                                                                            class="input-group-text text-light-green fw-500">Custom
                                                                                            tip</span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                        class="form-control form-control-submit"
                                                                                        placeholder="Custom tip">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Donate the change
                                                            </h3>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" name="#"> <span
                                                                    class="checkmark"></span>
                                                                Donate $0.77 to No kid Hungry. By checking this box you
                                                                agree to the Donate the Change <a
                                                                    routerLink="/checkout">Terms of use</a> <span
                                                                    class="ml-2"><a routerLink="/checkout">Learn
                                                                        More</a></span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <button type="submit"
                                                                class="btn-first green-btn text-custom-white full-width fw-500">Place
                                                                Your Order</button>
                                                        </div>
                                                        <p class="text-center text-light-black no-margin">By placing
                                                            your order, you agree to quickmunch's <a
                                                                routerLink="/checkout">terms of use</a> and <a
                                                                routerLink="/checkout">privacy agreement</a>
                                                        </p>
                                                    </div>
                                                    <div class="tab-pane fade" id="cash">
                                                        <p class="text-light-black">Have the cash ready when you receive
                                                            your order.</p>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Donate the change
                                                            </h3>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" name="#"> <span
                                                                    class="checkmark"></span>
                                                                Apologies, but you can't donate with the selected
                                                                payment type</label>
                                                        </div>
                                                        <div class="form-group">
                                                            <button type="submit"
                                                                class="btn-first green-btn text-custom-white full-width fw-500">Place
                                                                Your Order</button>
                                                        </div>
                                                        <p class="text-center text-light-black no-margin">By placing
                                                            your order, you agree to quickmunch's <a
                                                                routerLink="/checkout">terms of use</a> and <a
                                                                routerLink="/checkout">privacy agreement</a>
                                                        </p>
                                                    </div>
                                                    <div class="tab-pane fade" id="paypal">
                                                        <p class="text-light-black">Please review your order and make
                                                            any necessary changes before checking out with PayPal.</p>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Add a tip for your
                                                                driver</h3>
                                                        </div>
                                                        <div class="driver-tip-sec mb-xl-20">
                                                            <ul class="nav nav-tabs">
                                                                <li class="nav-item"> <a class="nav-link fw-600 active"
                                                                        data-toggle="tab" href="#paypaltipcard">Tip with
                                                                        Credit Card</a>
                                                                </li>
                                                                <li class="nav-item"> <a
                                                                        class="nav-link fw-600 disabled"
                                                                        data-toggle="tab" href="#paypalcashtip">Tip with
                                                                        Cash</a>
                                                                </li>
                                                            </ul>
                                                            <div class="tab-content">
                                                                <div class="tab-pane active" id="paypaltipcard">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="tip-percentage">
                                                                                <form>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage"
                                                                                            checked> <span>15%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>30%</span>
                                                                                    </label>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="custom-tip">
                                                                                <div class="input-group mb-3">
                                                                                    <div class="input-group-prepend">
                                                                                        <span
                                                                                            class="input-group-text text-light-green fw-500">Custom
                                                                                            tip</span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                        class="form-control form-control-submit"
                                                                                        placeholder="Custom tip">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Donate the change
                                                            </h3>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" name="#"> <span
                                                                    class="checkmark"></span>
                                                                Donate $0.77 to No kid Hungry. By checking this box you
                                                                agree to the Donate the Change <a
                                                                    routerLink="/checkout">Terms of use</a> <span
                                                                    class="ml-2"><a routerLink="/checkout">Learn
                                                                        More</a></span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <button type="submit"
                                                                class="btn-first paypal-btn text-custom-white full-width fw-500">Checkout
                                                                with
                                                                <img src="assets/img/pay-pal.png" alt="image">
                                                            </button>
                                                        </div>
                                                        <p class="text-center text-light-black no-margin">By placing
                                                            your order, you agree to quickmunch's <a
                                                                routerLink="/checkout">terms of use</a> and <a
                                                                routerLink="/checkout">privacy agreement</a>
                                                        </p>
                                                    </div>
                                                    <div class="tab-pane fade" id="amexcheckout">
                                                        <div class="card">
                                                            <div class="card-header"> <a class="card-link fw-500 fs-14"
                                                                    data-toggle="collapse" href="#saveamex">
                                                                    Saved Card
                                                                </a>
                                                            </div>
                                                            <div id="saveamex" class="collapse show"
                                                                data-parent="#accordion">
                                                                <div class="card-body no-padding payment-option-tab">
                                                                    <div class="form-group">
                                                                        <div
                                                                            class="credit-card amex-card-front p-relative">
                                                                            <input type="text" name="#"
                                                                                class="form-control form-control-submit"
                                                                                value="VISA....1877,exp 12/21">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header"> <a
                                                                    class="collapsed card-link fw-500 fs-14"
                                                                    data-toggle="collapse" href="#newcardamex">
                                                                    Add New Card
                                                                </a>
                                                            </div>
                                                            <div id="newcardamex" class="collapse"
                                                                data-parent="#accordion">
                                                                <div class="card-body no-padding payment-option-tab">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-xl-4 col-lg-6 col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="text-light-white fw-700">Card
                                                                                    Number</label>
                                                                                <div
                                                                                    class="credit-card amex-card-front p-relative">
                                                                                    <input type="text" name="#"
                                                                                        class="form-control form-control-submit"
                                                                                        placeholder="1234 5678 9101 1234">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-xl-2 col-lg-6 col-md-2 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="text-light-white fw-700">Expires
                                                                                    on</label>
                                                                                <input type="text" name="#"
                                                                                    class="form-control form-control-submit"
                                                                                    placeholder="12/21">
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="text-light-white fw-700">Security
                                                                                    Code</label>
                                                                                <div
                                                                                    class="credit-card amex-card-back p-relative">
                                                                                    <input type="text" name="#"
                                                                                        class="form-control form-control-submit"
                                                                                        placeholder="123">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="col-xl-3 col-lg-6 col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    class="text-light-white fw-700">ZIP
                                                                                    Code</label>
                                                                                <input type="text" name="#"
                                                                                    class="form-control form-control-submit"
                                                                                    placeholder="123456">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label class="custom-checkbox">
                                                                                    <input type="checkbox" name="#">
                                                                                    <span class="checkmark"></span>
                                                                                    Save Credit card</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Add a tip for your
                                                                driver</h3>
                                                        </div>
                                                        <div class="driver-tip-sec mb-xl-20">
                                                            <ul class="nav nav-tabs">
                                                                <li class="nav-item"> <a class="nav-link fw-600 active"
                                                                        data-toggle="tab" href="#tipcard">Tip with
                                                                        Credit Card</a>
                                                                </li>
                                                                <li class="nav-item"> <a
                                                                        class="nav-link fw-600 disabled"
                                                                        data-toggle="tab" href="#cashtip">Tip with
                                                                        Cash</a>
                                                                </li>
                                                            </ul>
                                                            <div class="tab-content">
                                                                <div class="tab-pane active" id="tipcard">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="tip-percentage">
                                                                                <form>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage"
                                                                                            checked> <span>15%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>25%</span>
                                                                                    </label>
                                                                                    <label
                                                                                        class="radio-inline text-light-green fw-600">
                                                                                        <input type="radio"
                                                                                            name="tip-percentage">
                                                                                        <span>30%</span>
                                                                                    </label>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="custom-tip">
                                                                                <div class="input-group mb-3">
                                                                                    <div class="input-group-prepend">
                                                                                        <span
                                                                                            class="input-group-text text-light-green fw-500">Custom
                                                                                            tip</span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                        class="form-control form-control-submit"
                                                                                        placeholder="Custom tip">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="section-header-left">
                                                            <h3 class="text-light-black header-title">Donate the change
                                                            </h3>
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" name="#"> <span
                                                                    class="checkmark"></span>
                                                                Donate $0.77 to No kid Hungry. By checking this box you
                                                                agree to the Donate the Change <a
                                                                    routerLink="/checkout">Terms of use</a> <span
                                                                    class="ml-2"><a routerLink="/checkout">Learn
                                                                        More</a></span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <button type="submit"
                                                                class="btn-first green-btn text-custom-white full-width fw-500">Place
                                                                Your Order</button>
                                                        </div>
                                                        <p class="text-center text-light-black no-margin">By placing
                                                            your order, you agree to quickmunch's <a
                                                                routerLink="/checkout">terms of use</a> and <a
                                                                routerLink="/checkout">privacy agreement</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div id="sideBar2" class="col-lg-3 p-3 vh-lg-100 sideBarCheckout">
                <div class="card-checkout" style="z-index: 1000;width: 100%;height: 100%;background: #fff;">
                    <div class="d-flex justify-content-between align-items-center mb-4">
                        <h3 class='text-center mb-0  mx-2'>{{'Basket' | translate}}</h3>
                        <button id="hideSideBarBtn" class="btn btn-danger mx-2" (click)="toggleSideBar()">
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    <div *ngFor="let order of orders">
                        <div class="d-flex" >
                            <div class="p-2">
                                <span>{{order.quantity}}</span>
                            </div>
                            <div class="d-flex p-2 flex-column text-right-style">
                                <h5 class="text-right-style">{{order.productName}}</h5>
                                <span>{{order.extraDetails}}</span>
                            </div>
                            <div class="p-2 ml-auto">
                                {{order.total | dynamicCurrency: settingService.getCurrencySymbol() : +settingService.getCurrencyPrecision()}}
                            </div>
                        </div>
                        <hr class="w-75">
                    </div>
                    <!-- <div class="d-flex">
                        <div class="p-2">
                            <span>1</span>
                        </div>
                        <div class="d-flex p-2 flex-column">
                            <h5>Cheeseburger Menü</h5>
                            <span>Coca-Cola 0,33l (EINWEG)</span>
                        </div>
                        <div class="p-2 ml-auto">
                            15,90 €
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex">
                        <div class="p-2">
                            <span>1</span>
                        </div>
                        <div class="d-flex p-2 flex-column">
                            <h5>Cheeseburger Menü</h5>
                            <span>Coca-Cola 0,33l (EINWEG)</span>
                        </div>
                        <div class="p-2 ml-auto">
                            15,90 €
                        </div>
                    </div> -->

                    <div class="d-flex mt-2">
                        <div class="d-flex p-2 flex-column">
                            <span>{{'SubTotal' | translate}}</span>
                            <span>{{'DeliveryCosts' | translate}}</span>
                            <h5 class="font-weight-bold text-style-right">{{'Total' | translate}}</h5>
                        </div>
                        <div class="d-flex flex-column p-2 ml-auto">
                            <span>{{subTotal() | dynamicCurrency: settingService.getCurrencySymbol() : +settingService.getCurrencyPrecision()}}</span>
                            <span>{{cost | dynamicCurrency: settingService.getCurrencySymbol() : +settingService.getCurrencyPrecision()}}</span>
                            <h5>{{orders[0].deliverFees + subTotal() + cost | dynamicCurrency: settingService.getCurrencySymbol() : +settingService.getCurrencyPrecision()}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer2></app-footer2>
<!-- <div class="modal fade" id="paymentLinkModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-left">{{'Payment methods' | translate}}</h4>
                <button type="button" class="close text-right" data-dismiss="modal"><span
                        aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            </div>
            <div class="modal-body">
                <div id="embed-target"> </div>
                <button (click)="closeModal()" type="button" class="btn done-btn pt-1 pb-2 w-100">
                    <span>{{'Done' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
<script>
    Checkout.configure({
				session: {
					id: 'SESSION0002234555183E21784648F7'
				}
			});
</script> -->
<div class="modal fade" id="paymentMethodsModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-left">{{'Payment methods' | translate}}</h4>
                <button type="button" class="close text-right" data-dismiss="modal"><span
                        aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            </div>
            <div class="modal-body">
                <!-- <ng-container *ngFor="let method of getPaymentMethodKeys(); let i = index">
                    <div *ngIf="paymentMethods[method] != 2" (click)="toggleModal(paymentMethods[method])"
                    class="d-flex mb-2 checkout-card2">
                    <div class="p-2">
                        <span class="fa fa-credit-card"></span>
                    </div>
                    <div class="p-2 d-flex flex-column">
                        <h5>{{ method | translate }}</h5>
                    </div>
                    <div *ngIf="paymentNumber === paymentMethods[method]" class="p-2 ml-auto">
                        <span class="text-danger fa fa-check"></span>
                    </div>
                </div>
                </ng-container> -->
                <ng-container *ngFor="let method of getPaymentMethodKeys(); let i = index">
                    <ng-container *ngIf="isApple">
                        <div (click)="toggleModal(paymentMethodIcons[method].numericValue,paymentMethodIcons[method].text)" class="d-flex mb-2 checkout-card2">
                            <div class="p-2">
                              <img class="payment-image" [src]="paymentMethodIcons[method].iconClass" alt="{{ method }} Image">
                            </div>
                            <div class="p-2 d-flex flex-column">
                              <h5>{{ method | translate }}</h5>
                            </div>
                            <div *ngIf="paymentNumber === paymentMethodIcons[method].numericValue" class="p-2 ml-auto">
                              <span class="text-danger fa fa-check"></span>
                            </div>
                          </div>
                    </ng-container>
                    <ng-container *ngIf="!isApple">
                        <div *ngIf="paymentMethodIcons[method].numericValue != 2" (click)="toggleModal(paymentMethodIcons[method].numericValue,paymentMethodIcons[method].text)" class="d-flex mb-2 checkout-card2">
                            <div class="p-2">
                              <img class="payment-image" width="70" [src]="paymentMethodIcons[method].iconClass" alt="{{ method }} Image">
                            </div>
                            <div class="p-2 d-flex flex-column">
                              <h5>{{ method | translate }}</h5>
                            </div>
                            <div *ngIf="paymentNumber === paymentMethodIcons[method].numericValue" class="p-2 ml-auto">
                              <span class="text-danger fa fa-check"></span>
                            </div>
                          </div>
                    </ng-container>
                  </ng-container>
                  
                
                <!-- <div (click)="toggleModal(0)" class="d-flex mb-2 checkout-card2">
                    <div class="p-2">
                        <span class="fa fa-credit-card"></span>
                    </div>
                    <div class="p-2 d-flex flex-column">
                        <h5>{{'Cache' | translate}}</h5>
                    </div>
                    <div *ngIf="paymentNumber == 0" class="p-2 ml-auto">
                        <span class="text-danger fa fa-check"></span>
                    </div>
                </div> -->
                <!-- <div (click)="toggleModal(1)" class="d-flex mb-2 checkout-card2">
                    <div class="p-2">
                        <span class="fa fa-credit-card"></span>
                    </div>
                    <div class="p-2">
                        <h5>Cache</h5>
                    </div>
                    <div *ngIf="paymentNumber == 1" class="p-2 ml-auto">
                        <span class="text-danger fa fa-check"></span>
                    </div>
                </div> -->
                <hr>
                <!-- <p class="fs-12">You will be redirected to the secure (SSL) pages of GiroPay. Here you will be able to
                    complete the GiroPay payment for your order.
                </p> -->
                <button (click)="closeModal()" type="button" class="btn done-btn pt-1 pb-2 w-100">
                    <span>{{'Done' | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="lastAddressModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-left">{{'Addresses'}}</h4>
                <button type="button" class="close text-right" data-dismiss="modal"><span
                        aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            </div>
            <div class="modal-body">
                <!-- <div (click)="toggleModal(1)" class="d-flex mb-2 checkout-card2">
                    <div class="p-2 w-25">
                        <img alt="..." src="/assets/img/placeholder.png" />
                    </div>
                    <div class="p-2 d-flex flex-column">
                        <h5>{{lastAddress?.addressName}}</h5>
                        <p>{{lastAddress?.region}}</p>
                        <p>{{lastAddress?.street}}</p>
                        <p>{{lastAddress?.phoneNumber}}</p>
                    </div>
                </div> -->
                <div class="item-address">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.25" width="24" height="24"><defs><style>.cls-637647fac3a86d32eae6f27d-1{fill:none;stroke:currentColor;stroke-miterlimit:10;}</style></defs><rect class="cls-637647fac3a86d32eae6f27d-1" x="7.23" y="11.05" width="5.73" height="6.68"></rect><polygon class="cls-637647fac3a86d32eae6f27d-1" points="12.96 14.86 15.82 17.73 16.77 17.73 16.77 11.04 15.82 11.04 12.96 13.91 12.96 14.86"></polygon><polygon class="cls-637647fac3a86d32eae6f27d-1" points="20.59 6.27 20.59 22.5 3.41 22.5 3.41 1.5 15.82 1.5 20.59 6.27"></polygon><polygon class="cls-637647fac3a86d32eae6f27d-1" points="20.59 6.27 20.59 7.23 14.86 7.23 14.86 1.5 15.82 1.5 20.59 6.27"></polygon></svg> -->
                    <img src="/assets/img/icons8-address-100.png" alt="...">
                    <div class="filename">
                      <p>{{lastAddress?.addressName}}</p>
                      <div class="filedata">
                        <span>{{lastAddress?.region}}</span>
                        <span>●</span>
                        <span>{{lastAddress?.street}}</span>
                        <span>●</span>
                        <span>{{lastAddress?.phoneNumber}}</span>
                      </div>
                    </div>
                    <button (click)="setAddressId(lastAddress.id)">{{'Select Address' | translate}}</button>
                  </div>
                <!-- <hr> -->
                <!-- <p class="fs-12">You will be redirected to the secure (SSL) pages of GiroPay. Here you will be able to
                    complete the GiroPay payment for your order.
                </p> -->
                <!-- <button (click)="closeAddressModal()" type="button" class="btn done-btn pt-1 pb-2 w-100">
                    <span>{{'Done' | translate}}</span>
                </button> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="authModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-left">{{authType}}</h4>
                <button type="button" class="close text-right" data-dismiss="modal"><span
                        aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            </div>
            <div class="modal-body">
                <div class="row text-center">
                    <div class="col-md-12 mb-2">
                        <div class="form-group">
                            <button (click)="changeType(1)" type="submit"
                                class="btn-second btn-facebook full-width w-50">
                                <img src="assets/img/facebook-logo.svg" alt="btn logo">{{'Continue with Facebook' |
                                translate}}</button>
                        </div>
                    </div>
                    <div class="col-md-12 mb-2">
                        <div class="form-group">
                            <button (click)="changeType(2)" type="submit" class="btn-second btn-google full-width w-50">
                                <img src="assets/img/google-logo.png" alt="btn logo">{{'Continue with Google' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
                <div class="hr-container">
                    <hr class="hr-left">
                    <span class="word-between">Or</span>
                    <hr class="hr-right">
                </div>
                <div *ngIf="authFormType == 0" class="sign-in">
                    <div class="login-sec">
                        <div class="login-box">
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value,formDirective)"
                                #formDirective="ngForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">{{'User Name Or Email' |
                                                translate}}</label>
                                            <input type="text" formControlName="userNameOrEmail"
                                                class="form-control form-control-submit" placeholder="Email I'd">
                                        </div>
                                        <div class="form-group p-relative">
                                            <label class="text-light-white fs-14">{{'Password' | translate}}</label>
                                            <input formControlName="password" type="password" id="password-field"
                                                name="#" class="form-control form-control-submit" value=""
                                                placeholder="Password">
                                            <div (click)="toggleEye(1)" data-name="#password-field"
                                                [ngClass]="{'fa-eye':visible1,'fa-eye-slash':!visible1}"
                                                class="fa fa-fw fa-eye field-icon toggle-password-signin toggle-icon">
                                            </div>
                                        </div>
                                        <div class="form-group checkbox-reset">
                                            <a (click)="changeForm(2)">{{'Forgot password' | translate}}</a>
                                        </div>
                                        <button type="submit" class="btn done-btn pt-1 pb-2 w-100">
                                            <span>{{authType}}</span>
                                        </button>
                                        <div (click)="changeForm(1)" class="form-group text-center mb-0"> <a
                                                href="javascript:void(0)" class="create-account-style">{{'Create your
                                                account' | translate}}</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div *ngIf="authFormType == 1" class="register">
                    <div class="login-sec">
                        <div class="login-box">
                            <form *ngIf="!confirmEmailForm" [formGroup]="registerForm"
                                (ngSubmit)="register(registerForm.value,formDirective)" #formDirective="ngForm">
                                <h4 class="text-light-black fw-600">Create your account</h4>
                                <div class="row">
                                    <div class="col-lg-6 col-md-12 col-sm-6">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">{{'User name' | translate}}</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': f.userName.errors && (f.userName.dirty || submitted), 'is-valid': f.userName.valid }"
                                                type="text" formControlName="userName"
                                                class="form-control form-control-submit" placeholder="User Name"
                                                required>
                                            <div *ngIf="f.userName.touched && f.userName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.userName.errors.required">{{'User name' | translate}}
                                                    {{'validations.isrequired' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-6">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">{{'Full Name' | translate}}</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': f.fullName.errors && (f.fullName.dirty || submitted), 'is-valid': f.fullName.valid }"
                                                type="text" formControlName="fullName"
                                                class="form-control form-control-submit" placeholder="Full Name"
                                                required>
                                            <div *ngIf="f.fullName.touched && f.fullName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.fullName.errors.required">{{'Full Name' | translate}}
                                                    {{'validations.isrequired' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">{{'Email' | translate}}</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': f.email.errors && (f.email.dirty || submitted), 'is-valid': f.email.valid }"
                                                type="text" formControlName="email"
                                                class="form-control form-control-submit" placeholder="Email I'd"
                                                required>
                                            <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">{{'Email' | translate}}
                                                    {{'validations.isrequired' | translate}}</div>
                                                <div *ngIf="f.email.errors.email">{{'Validations.invalid_email' |
                                                    translate}}</div>
                                            </div>
                                        </div>
                                        <div class="form-group p-relative">
                                            <label class="text-light-white fs-14">Password (8 character minimum)</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': f.password.errors && (f.password.dirty || submitted), 'is-valid': f.password.valid }"
                                                type="password" id="password-field12" formControlName="password"
                                                name="#" class="form-control form-control-submit pass" value="password"
                                                placeholder="Password" required>
                                            <div (click)="toggleEye(2)" data-name="#password-field12"
                                                [ngClass]="{'fa-eye':visible,'fa-eye-slash':!visible}"
                                                class="fa fa-fw field-icon toggle-password-reg toggle-icon"></div>
                                            <div *ngIf="f.password.touched && f.password.errors"
                                                class="invalid-feedback">
                                                <!-- required -->
                                                <span class="text-danger"
                                                    *ngIf="(f.password.dirty || submitted) && !requiredValid">Password
                                                    is
                                                    required</span>

                                                <!-- password validation rules -->
                                                <div id="validation-rules" *ngIf="requiredValid">
                                                    <!-- minlength -->
                                                    <div
                                                        [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                                                        <i
                                                            [attr.class]="minLengthValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.minlength' | translate : {number:8} }}
                                                    </div>

                                                    <!-- requires digit -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                                                        <i
                                                            [attr.class]="requiresDigitValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresDigit' | translate : {number:1} }}
                                                    </div>

                                                    <!-- requires uppercase -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                                                        <i
                                                            [attr.class]="requiresUppercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresUppercase' | translate:{number:1} }}
                                                    </div>

                                                    <!-- requires lowercase -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                                                        <i
                                                            [attr.class]="requiresLowercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresLowercase' | translate : {number:1} }}
                                                    </div>

                                                    <!-- requires special characters -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                                                        <i
                                                            [attr.class]="requiresSpecialCharsValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresSpecialChars' | translate : {number:1} }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="text-light-black fs-12 terms">By clicking on "Create account", you
                                            agree to our <a routerLink="/register"> Terms of Use </a> and <a
                                                routerLink="/register"> Privacy
                                                Policy.</a></span>
                                        <div class="form-group">
                                            <button (click)="changeType(0)" type="submit"
                                                class="btn-second btn-submit full-width">Create your
                                                account</button>
                                        </div>
                                        <div class="form-group text-center">
                                            <p class="text-light-black mb-0">Have an account? <a
                                                    (click)="changeForm(0)">{{'Sign In' | translate}}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form *ngIf="confirmEmailForm" [formGroup]="confirmEmailForm"
                                (ngSubmit)="confirmMail(confirmEmailForm.value,formDirective)" #formDirective="ngForm">
                                <!-- <h4 class="text-light-black fw-600">Activate Your Account</h4> -->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">{{'Code' | translate}}</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': fCEF.code.errors && (fCEF.code.dirty || submitted2), 'is-valid': fCEF.code.valid }"
                                                formControlName="code" type="text"
                                                class="form-control form-control-submit" value="" placeholder="Code">
                                            <div *ngIf="fCEF.code.touched && fCEF.code.errors" class="invalid-feedback">
                                                <div *ngIf="fCEF.code.errors.required">{{'Code' | translate}}
                                                    {{'validations.isrequired' | translate}}</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" class="btn-second btn-submit full-width">
                                                <img src="assets/img/M.png" alt="btn logo">{{'Confirm' |
                                                translate}}</button>
                                        </div>
                                        <div class="form-group text-center"> <span>or</span>
                                        </div>
                                        <div class="form-group text-center mb-0" style="cursor:pointer"> <a
                                                (click)="resendEmail()" style="color:#ff0018">{{'Resend Code' |
                                                translate}}</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div *ngIf="authFormType == 2" class="forgotPassword">
                    <div class="login-sec">
                        <div class="login-box">
                            <form *ngIf="show_email_feild" [formGroup]="resetForm"
                                (ngSubmit)="reset(resetForm.value,formDirective)" #formDirective="ngForm">
                                <h4 class="text-light-black fw-600">{{'Reset Your Password' | translate}}</h4>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">{{'Email' | translate}}</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': fR.email.errors && (fR.email.dirty || submitted), 'is-valid': fR.email.valid }"
                                                type="text" formControlName="email"
                                                class="form-control form-control-submit" placeholder="Email I'd">
                                            <div *ngIf="fR.email.touched && fR.email.errors" class="invalid-feedback">
                                                <div *ngIf="fR.email.errors.required">{{'Email'}}
                                                    {{'validations.isrequired' | translate}}</div>
                                                <div *ngIf="fR.email.errors.email">{{'Validation.invalid_email' |
                                                    translate}}</div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                      <label class="text-light-white fs-14">Password</label>
                      <input formControlName="password" type="password" id="password-field" name="#" class="form-control form-control-submit" value="" placeholder="Password">
                      <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                    </div> -->
                                        <div class="form-group">
                                            <button type="submit" class="btn-second btn-submit full-width">
                                                <img src="assets/img/M.png" alt="btn logo">{{'Reset Password' |
                                                translate}}</button>
                                        </div>
                                        <div class="form-group text-center">
                                            <p class="text-light-black mb-0">{{'I already have an account' | translate}}
                                                <a (click)="changeForm(0)">{{'Sign In' | translate}}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form *ngIf="!show_email_feild" [formGroup]="resetConfirmForm"
                                (ngSubmit)="confirmReset(resetConfirmForm.value,formDirective)" #formDirective="ngForm">
                                <h4 class="text-light-black fw-600">{{'Reset Your Password' | translate}}</h4>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">{{'Code' | translate}}</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': f1.code.errors && (f1.code.dirty || submitted), 'is-valid': f1.code.valid }"
                                                formControlName="code" type="text"
                                                class="form-control form-control-submit" value="" placeholder="Code">
                                            <div *ngIf="f1.code.touched && f1.code.errors" class="invalid-feedback">
                                                <div *ngIf="f1.code.errors.required">{{'Code' | translate}}
                                                    {{'validations.isrequired' | translate}}</div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">Password (8 character minimum)</label>
                                            <input
                                                [ngClass]="{ 'is-invalid': f1.password.errors && (f1.password.dirty || submitted), 'is-valid': f1.password.valid }"
                                                type="password" id="password-field" formControlName="password" name="#"
                                                class="form-control form-control-submit pass" value="password"
                                                placeholder="Password" required>
                                            <div data-name="#password-field"
                                                class="fa fa-fw fa-eye field-icon toggle-password"></div>
                                            <div *ngIf="f1.password.touched && f1.password.errors"
                                                class="invalid-feedback">
                                                <!-- required -->
                                                <span class="text-danger"
                                                    *ngIf="(f1.password.dirty || submitted) && !requiredValidC">{{'Password' | translate}}
                                                    {{'validations.isrequired' | translate}}</span>

                                                <!-- password validation rules -->
                                                <div id="validation-rules" *ngIf="requiredValid">
                                                    <!-- minlength -->
                                                    <div
                                                        [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                                                        <i
                                                            [attr.class]="minLengthValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.minlength' | translate : {number:8} }}
                                                    </div>

                                                    <!-- requires digit -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                                                        <i
                                                            [attr.class]="requiresDigitValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresDigit' | translate : {number:1} }}
                                                    </div>

                                                    <!-- requires uppercase -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                                                        <i
                                                            [attr.class]="requiresUppercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresUppercase' | translate:{number:1} }}
                                                    </div>

                                                    <!-- requires lowercase -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                                                        <i
                                                            [attr.class]="requiresLowercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresLowercase' | translate : {number:1} }}
                                                    </div>

                                                    <!-- requires special characters -->
                                                    <div
                                                        [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                                                        <i
                                                            [attr.class]="requiresSpecialCharsValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                                                        {{'validations.requiresSpecialChars' | translate : {number:1} }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                      <label class="text-light-white fs-14">Password</label>
                      <input formControlName="password" type="password" id="password-field" name="#" class="form-control form-control-submit" value="" placeholder="Password">
                      <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                    </div> -->
                                        <div class="form-group">
                                            <button type="submit" class="btn-second btn-submit full-width">
                                                <img src="assets/img/M.png" alt="btn logo">{{'Reset Password' |
                                                translate}}</button>
                                        </div>
                                        <div class="form-group text-center"> <span>{{'or' | translate}}</span>
                                        </div>
                                        <!-- <div *ngIf="email_confirm" class="form-group text-center mb-1"> <a (click)="open(modaladd)" style="color:#ff0018;font-weight: bold;">Resend Confirmation Code</a>
                    </div> -->
                                        <div class="form-group text-center mb-0"> <a routerLink="/login">{{'Login' |
                                                translate}}</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>