import { Component, OnInit } from '@angular/core';
import { InfoService } from 'src/app/services/info/info.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit  {
  comment = '';
  getPrivacy() {
    this.infoService.getPrivacy().subscribe((res) => {
      this.comment = res.data;
    });
  }
  constructor(private infoService: InfoService) {}

  ngOnInit(): void {
    this.getPrivacy()
  }
}
