import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'space'
})
export class SpacePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace('KWD', 'KWD ');
}
}
