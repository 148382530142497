// import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginModel } from 'src/app/models-classes/auth/login-model.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  user: SocialUser; //social login
  loggedIn: boolean; //social login
  langs = {
    en: 'English',
    ar: 'Arabic',
  };
  @ViewChild('modaladd', { static: true }) content: ElementRef;
  current_lang = localStorage.getItem('current_lang') || 'en';
  flagImage =
    localStorage.getItem('flagImage') ||
    'https://rahaldeliverystorage.blob.core.windows.net/static/jordan.png';
  loginStatus$: Observable<boolean>;
  email_confirm = false;
  constructor(
    private _authService: AuthService,
    private _toast: ToastrService,
    private _router: Router,
    private modalService: NgbModal,
    // private authService: SocialAuthService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    public settings: SettingsService
  ) {
    // this.authService.signOut();
    // this.authService.authState.subscribe((user: SocialUser) => {
    //   console.log(user);
    //   if (user != null) {
    //     let loginModel: LoginModel = new LoginModel();
    //     loginModel = {
    //       password: '',
    //       userNameOrEmail: '',
    //       rememberMe: true,
    //       googleToken: user.idToken,
    //     };
    //     this._authService.login(loginModel).subscribe((res) => {
    //       console.log(res);
    //       if (res.isSuccess) {
    //         console.log(res.data)
    //         localStorage.setItem('provider', 'provider');
    //         const returnUrl =
    //           this.route.snapshot.queryParamMap.get('returnUrl');
    //         console.log(returnUrl);
    //         if (returnUrl) this._router.navigateByUrl(returnUrl);
    //         else this._router.navigate(['/']);
    //       } else {
    //         let errorMessage = res.errors.join('\n'); // Using newline for better readability
    //         this._toast.error(errorMessage);
    //       }
    //     });
    //   }
    // });
  }
  changeLang(key) {
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    htmlTag.dir = key === 'ar' ? 'rtl' : 'ltr';
    htmlTag.lang = key;
    // console.log(event.target.value);
    this.translate.use(key);
    this.settings.setLanguage(key);
    this.settings.updateLang(key);
    localStorage.setItem('current_lang', key);
    if (key == 'ar') this.loadStyle('style-ar.css');
    else this.loadStyle('style.css');
    window.location.reload();
    this.settings.updateLang(key);
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `assets/css/${styleName}`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = `assets/css/${styleName}`;
      head.appendChild(style);
    }
  }
  loginForm: UntypedFormGroup;
  confirmEmailForm: UntypedFormGroup;
  type = 0;
  submitted1 = false;
  ngOnInit(): void {
    if (this._authService.checkUserIsLogedIn()) {
      this._router.navigate(['/']);
    }
    this.loginForm = new UntypedFormGroup({
      userNameOrEmail: new UntypedFormControl('', [
        Validators.required,
        // Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ]),
      password: new UntypedFormControl('', [Validators.required]),
      rememberMe: new UntypedFormControl(false),
    });
    // this.authService.authState.subscribe((user) => {
    //   this.user = user;
    //   this.loggedIn = user != null;
    //   console.log(this.user);
    // });
  }
  get f1() {
    return this.confirmEmailForm.controls;
  }
  open(content: any) {
    if (!this.loginForm.value.userNameOrEmail) {
      this._toast.error(
        this.translate.instant('Please enter valid email or user name')
      );
      return;
    }
    let status = this.resendEmail(this.loginForm.value.userNameOrEmail);
    if (status)
      this.modalService.open(content, {
        centered: true,
        windowClass: 'modal-max',
      });
  }
  changeType(type) {
    this.type = type;
  }
  userId = '';
  activationMethod: 'email' | 'phone' | '' = '';
  checkVertification = false;
  setActivationMethod(method: 'email' | 'phone') {
    this.setConfirmEmailForm();
    this.activationMethod = method;
    if (method == 'email') {
      this._authService
        .sendEmailVerification(this.userId, 0)
        .subscribe((res) => {
          if (res.isSuccess) {
            this.checkVertification = true;
          }else {
            const translatedErrors = res.errors.map((error) =>
              this.translate.instant(error)
            );
            const errorMessage = translatedErrors.join('\n');
            this._toast.error(errorMessage);
          }
        });
    } else {
      this._authService
        .sendPhoneVerification(this.userId, 0)
        .subscribe((res) => {
          if (res.isSuccess) {
            this.checkVertification = true;
          }else {
            this._toast.error(this.translate.instant('cannotActivateByPhone'));
          }
        });
    }
  }
  resendEmail(email: string): boolean {
    this._authService.resendOtp('', 0, true).subscribe((res) => {
      if (res.isSuccess) {
        this._toast.success(
          this.translate.instant(
            'The activation code has been sent again. Please check your email'
          )
        );
        return true;
      } else {
        this._toast.error(this.translate.instant('Some thing went wrong'));
        return false;
      }
    });
    return false;
  }
  resendOtp() {
    if (this.confirmEmailForm.value.email) {
      this._authService
        .resendOtp(
          this.userId,
          0,
          this.activationMethod == 'email' ? false : true
        )
        .subscribe((res) => {
          if (res.isSuccess) {
            this._toast.success(
              this.translate.instant(
                'The activation code has been sent again. Please check your ' +
                  this.activationMethod
              )
            );
          } else
            this._toast.error(this.translate.instant('Some thing went wrong'));
        });
    }
  }
  verifyOtp(data, formDirective: FormGroupDirective) {
    this.submitted1 = true;
    if (!this.confirmEmailForm.valid) {
      return;
    }
    this._authService
      .verifyOtp(
        this.userId,
        data.code,
        0
      )
      .subscribe((res) => {
        if (res.isSuccess) {
          this._toast.success(this.translate.instant('ConfirmAccount'));
          this.confirmEmailForm.reset();
          formDirective.resetForm();
          this.modalService.dismissAll();
          // this.confirm_email = false;
          // this._router.navigate(['login']);
          let loginModel = {
            password: this.loginForm.get('password')?.value,
            countryId: +localStorage.getItem('countryId') || 0,
            userNameOrEmail: this.loginForm.get('userNameOrEmail')?.value,
            rememberMe: this.loginForm.get('rememberMe')?.value,
          };
          this._authService.login(loginModel).subscribe((res) => {
            if (res.isSuccess) {
              const returnUrl =
                this.route.snapshot.queryParamMap.get('returnUrl');
              console.log(returnUrl);
              if (returnUrl) this._router.navigateByUrl(returnUrl);
              else this._router.navigate(['/']);
            } else {
              const translatedErrors = res.errors.map((error) =>
                this.translate.instant(error)
              );
              const errorMessage = translatedErrors.join('\n');
              this._toast.error(errorMessage);
            }
          });
        } else {
          const translatedErrors = res.errors.map((error) =>
            this.translate.instant(error)
          );
          const errorMessage = translatedErrors.join('\n');
          this._toast.error(errorMessage);
        }
        // this._toast.error(
        //   this.translate.instant('Some thing went wrong'),
        //   this.translate.instant('Email Confirmation'),
        //   {
        //     positionClass: 'toast-top-left',
        //     progressBar: true,
        //   }
        // );
      });
  }
  confirmMail(data, formDirective: FormGroupDirective) {
    this.submitted1 = true;
    if (!this.confirmEmailForm.valid) {
      return;
    }
    this._authService.confirmEmail(data.email, data.code).subscribe((res) => {
      if (res.isSuccess) {
        this._toast.success(
          this.translate.instant('Success'),
          this.translate.instant('Confirm Your email'),
          {
            positionClass: 'toast-top-left',
            progressBar: true,
          }
        );
        this.confirmEmailForm.reset();
        formDirective.resetForm();
        this.modalService.dismissAll();
        this.email_confirm = false;
      } else
        this._toast.error(
          this.translate.instant('Some thing went wrong'),
          this.translate.instant('Email Confirmation'),
          {
            positionClass: 'toast-top-left',
            progressBar: true,
          }
        );
    });
  }
  setConfirmEmailForm() {
    this.confirmEmailForm = new UntypedFormGroup({
      code: new UntypedFormControl('', [Validators.required]),
      // email: new UntypedFormControl('', [Validators.required]),
    });
  }
  onSubmit(data, formDirective: FormGroupDirective) {
    if (!this.loginForm.valid) {
      this._toast.error(this.translate.instant('Please check all fields'));
      return;
    }
    this.checkVertification = false;
    let loginModel: LoginModel = new LoginModel();
    if (this.type == 0) {
      loginModel = {
        password: data.password,
        countryId: +localStorage.getItem('countryId') || 0,
        userNameOrEmail: data.userNameOrEmail,
        rememberMe: data.rememberMe,
      };
    } else if (this.type == 1) {
      loginModel = {
        facebookToken: '1',
      };
    } else {
      loginModel = {
        googleToken: '2',
      };
    }
    localStorage.removeItem('provider');
    this._authService.login(loginModel).subscribe((res) => {
      // let result = JSON.parse(res.data);
      // console.log(res.data.emailConfirmed);
      if (res.isSuccess) {
        if (this.type == 0) {
          // console.log(res.data)
          if (res.data.isVerified) {
            const returnUrl =
              this.route.snapshot.queryParamMap.get('returnUrl');
            console.log(returnUrl);
            if (returnUrl) this._router.navigateByUrl(returnUrl);
            else this._router.navigate(['/']);
          } else {
            this.userId = res.data.id;
            this.modalService.open(this.content);
          }
        }
      } else {
        const translatedErrors = res.errors.map((error) =>
          this.translate.instant(error)
        );
        const errorMessage = translatedErrors.join('\n');
        this._toast.error(errorMessage);
      }
    });
  }
}
