<ejs-grid
          #grid
          id="grid"
          [loadingIndicator]='loadingIndicator'
          [enableRtl]='current_lang == "ar"'
          [query]="query"
          [editSettings]='model.editSettings'
          [toolbar]="model.toolbar"
          [selectionSettings]='model.selectionSettings'
          [filterSettings]="model.filterSettings"
          [pageSettings]="model.pageSettings"
          [allowFiltering]="model.allowFiltering"
          [allowExcelExport]="model.allowExcelExport"
          [allowFiltering]="model.allowFiltering"
          [allowGrouping]="model.allowGrouping"
          [allowPaging]="model.allowPaging"
          [allowResizing]="model.allowResizing"
          [allowRowDragAndDrop]="model.allowRowDragAndDrop"
          [allowSelection]="model.allowSelection"
          [allowSorting]="model.allowSorting"
          [allowTextWrap]="model.allowTextWrap"
          [allowPdfExport]="model.allowPdfExport"
          [dataSource]="model.data"
          [sortSettings]="model.sortSettings"
          [columns]="model.gridViewColumns"
          (toolbarClick) = "toolbarClick($event)"
          (databound) = "dataBound($event)"
          (queryCellInfo)="queryCellInfoEvent($event)"
          (cellSelected)="cellSelected($event)"
          (recordClick)="onRecordClick($event)"
          [allowExcelExport]='true'
          >
</ejs-grid>
<ng-template #modaldelete let-modal>
    <div class="modal-header">
      <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-alert-1 bg-primary text-white"></i>
        {{'Delete' | translate}}</h3>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
      <p>{{trans_item | translate}}</p>
    </div>
    <div class="modal-footer">
      <!-- <div class="modal-notice">
            <label class="ms-checkbox-wrap">
                <input type="checkbox" value="">
                <i class="ms-checkbox-check"></i>
            </label>
            <span> Don't Show me Again </span>
        </div> -->
      <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">{{'Cancel' | translate}}</button>
      <button type="button" class="btn btn-primary shadow-none" (click)="delete()">{{'Accept' | translate}}</button>
    </div>
  </ng-template>