import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import './../assets/smtp.js';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './services/settings.service';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit,AfterViewInit {
  location: any;
  routerSubscription: any;

  constructor(private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private settings: SettingsService
  ) {
    translate.setDefaultLang('en');
  }
  ngAfterViewInit(): void {
    // const images = document.getElementsByTagName('img');

    // // Check if there are images on the page
    // if (images.length > 0) {
    //   // Iterate through each image element
    //   for (let i = 0; i < images.length; i++) {
    //     // Your logic to handle each image
    //     if (images[i].src.includes('data:image/svg+xml;base64,')) {
    //       const parentDiv = images[i].parentNode;

    //       // Check if the parent div exists
    //       if (parentDiv) {
    //         // Remove the parent div from the DOM
    //         this.renderer.removeChild(parentDiv.parentNode, parentDiv);

    //         // Log that the parent div has been removed
    //         console.log('Parent div of image removed:', parentDiv);
    //       } else {
    //         console.log('Parent div of image not found.');
    //       }
    //     }
    //   }
    // } else {
    //   console.log('No images found on the page.');
    // }
  }
  current_lang = localStorage.getItem('current_lang') || 'en';
  existingLink = this.document.getElementById('langCss') as HTMLLinkElement;
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `assets/css/${styleName}`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = `assets/css/${styleName}`;
      head.appendChild(style);
    }
  }
  removeIfContainsLicenseMessage() {
    const divElement = this.document.querySelector('div');
    const spanElement = divElement.querySelector('span');

    // Check if the span contains the specific content
    const licenseMessage = "This application was built using a trial version of Syncfusion Essential Studio. To remove the license validation message permanently, a valid license key must be included.";

    if (spanElement && spanElement.textContent === licenseMessage) {
      divElement.remove()
    }
  }
  ngOnInit() {
    let htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    if (this.current_lang) {
      htmlTag.dir = this.current_lang === 'ar' ? 'rtl' : 'ltr';
      htmlTag.lang = this.current_lang;
      this.translate.setDefaultLang(this.current_lang)//set language of web site
      this.translate.use(this.current_lang);
      if (this.current_lang == 'ar')
        this.loadStyle('style-ar.css');
      else
        this.loadStyle('style.css');
      this.settings.updateLang(this.current_lang)
    }
    else {
      this.loadStyle('style.css');
      htmlTag.dir = 'ltr';
      htmlTag.lang = 'en';
      this.translate.setDefaultLang('en')//set language of web site
      this.translate.use('en');
      this.settings.updateLang('en')
    }
    this.recallJsFunctions();
    if (!localStorage.getItem('current_lang')) {
      localStorage.setItem('current_lang', 'en');
      localStorage.setItem('isRtl', 'false');
    }
    this.translate.setDefaultLang(this.current_lang); //set language of web site
    this.translate.use(this.current_lang);
    
  }
  // onActivate(event) {
  //   window.scroll(0, 0);
  // }
  recallJsFunctions() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          $('.loader-container').fadeIn('slow');
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('assets/js/quickmunch.js');
        $('.loader-container').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}


