export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const MAIN_SLIDER_HEIGHT = 1920;
export const MAIN_SLIDER_WIDTH = 1080;
export const LOGO_WIDTH = 512;
export const LOGO_HEIGHT = 512;
export const MAIN_COMPANY_HEIGHT = 1180;
export const MAIN_COMPANY_WIDTH = 500;
export class GetRequiredUserInfo {
  public static token = JSON.parse(localStorage.getItem('current_user'))?.token?.toString();
  public static userId = JSON.parse(localStorage.getItem('current_user'))?.id?.toString();
  public static fullName = JSON.parse(localStorage.getItem('current_user'))?.fullName?.toString();
  public static email = JSON.parse(localStorage.getItem('current_user'))?.email?.toString();
  public static image = JSON.parse(localStorage.getItem('current_user'))?.image?.toString();
  public static emailConfirmed = JSON.parse(localStorage.getItem('current_user'))?.emailConfirmed?.toString();
}
/**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
export function getUniqueId(parts: number): string {
  const stringArr = [];
  for(let i = 0; i< parts; i++){
    // tslint:disable-next-line:no-bitwise
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}

export function generateNumberArray(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}