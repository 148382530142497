import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';

@Injectable({
  providedIn: 'root',
})
export class OrderExistGuard implements CanActivate {
  constructor(
    private settingService: SettingsService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.settingService.getData('my_orders')?.length > 0) return true;
    this.router.navigate(['/'])
    return false;
  }
}
