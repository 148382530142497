<app-navbar></app-navbar>

<!-- Blog details -->
    <section class="our-articles bg-light-theme section-padding pt-0">
        <div class="blog-page-banner"></div>
        <div class="container-fluid">
            <div class="row">
                <aside class="col-lg-3">
                <app-blogleftsidebar></app-blogleftsidebar>
            </aside>
                <div class="col-lg-6 blog-inner clearfix">
                    <div class="main-box padding-20 full-width">
                        <div class="breadcrumb-wrpr">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/" class="text-light-black">Home</a>
                                </li>
                                <li class="breadcrumb-item"><a routerLink="/blog" class="text-light-black">Blog</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Blog Details</li>
                            </ul>
                        </div>
                        <div class="post-wrapper mb-xl-20">
                            <img src="assets/img/blog-details/822x337/image-1.jpg" class="img-fluid full-width" alt="#">
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="blog-meta mb-xl-20">
                                    <h2 class="blog-title text-light-black">Best chinese restaurants in Brooklyn.</h2>
                                    <h6 class="text-light-white fs-14">Posted on 25 January, 2022</h6>
                                    <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                        to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                                <div class="blog-meta mb-xl-20">
                                    <h5 class="blog-title text-light-black">Why do we choose it?</h5>
                                    <p class="text-light-white">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
                                        ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </div>
                                <div class="blog-meta mb-xl-20">
                                    <h5 class="blog-title text-light-black">Where does it come from?</h5>
                                    <p class="text-light-white mb-xl-20">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
                                        College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem
                                        Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during
                                        the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
                                    <p class="text-light-white">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original
                                        form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                                </div>
                                <div class="blog-meta mb-xl-20">
                                    <h5 class="blog-title text-light-black">Where can I get that food?</h5>
                                    <p class="text-light-white">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going
                                        to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,
                                        making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated
                                        Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                </div>
                                <div class="blog-meta mb-xl-20">
                                    <h5 class="blog-title text-light-black">Why do we order from there?</h5>
                                    <p class="text-light-white">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                        as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
                                        ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
                                </div>
                                <blockquote>
                                    <p>"Chinese is the popular dish, Lorem Ipsum is not simply random text. It has roots in a piece of classical chinese."</p>
                                </blockquote>
                                <div class="comment-box">
                                    <div class="section-header-left">
                                        <h3 class="text-light-black header-title">Comments</h3>
                                    </div>
                                    <div class="review-box">
                                        <div class="review-user">
                                            <div class="review-user-img">
                                                <img src="assets/img/blog-details/40x40/user-1.png" class="rounded-circle" alt="#">
                                                <div class="reviewer-name">
                                                    <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                                            </div>
                                        </div>
                                        <div class="ratings"> <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="ml-2 text-light-white">2 days ago</span>
                                        </div>
                                        <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p>
                                    </div>
                                    <div class="review-box comment-reply">
                                        <div class="review-user">
                                            <div class="review-user-img">
                                                <img src="assets/img/blog-details/40x40/user-2.png" class="rounded-circle" alt="#">
                                                <div class="reviewer-name">
                                                    <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                                            </div>
                                        </div>
                                        <div class="ratings"> <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="ml-2 text-light-white">2 days ago</span>
                                        </div>
                                        <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p>
                                    </div>
                                    <div class="review-box">
                                        <div class="review-user">
                                            <div class="review-user-img">
                                                <img src="assets/img/blog-details/40x40/user-3.png" class="rounded-circle" alt="#">
                                                <div class="reviewer-name">
                                                    <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                                            </div>
                                        </div>
                                        <div class="ratings"> <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="ml-2 text-light-white">2 days ago</span>
                                        </div>
                                        <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p>
                                    </div>
                                    <div class="review-box comment-reply">
                                        <div class="review-user">
                                            <div class="review-user-img">
                                                <img src="assets/img/blog-details/40x40/user-1.png" class="rounded-circle" alt="#">
                                                <div class="reviewer-name">
                                                    <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York, (NY)</small>
                                                    </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                                            </div>
                                        </div>
                                        <div class="ratings"> <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="text-yellow fs-16">
                        <i class="fas fa-star"></i>
                      </span>
                                            <span class="ml-2 text-light-white">2 days ago <i class="fas fa-heart"></i></span>
                                        </div>
                                        <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p>
                                    </div>
                                </div>
                                <div class="comment-form">
                                    <div class="section-header-left">
                                        <h3 class="text-light-black header-title">Leave a Reply</h3>
                                    </div>
                                    <form>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-600">Full Name</label>
                                                    <input type="text" name="#" class="form-control form-control-submit" placeholder="Full Name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-600">Email Address</label>
                                                    <input type="email" name="#" class="form-control form-control-submit" placeholder="Email Address">
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="text-light-black fw-600">Your Comment</label>
                                                    <textarea class="form-control form-control-submit" name="#" rows="6" placeholder="Your Comment"></textarea>
                                                </div>
                                                <button type="submit" class="btn-second btn-submit full-width">Send</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <aside class="col-lg-3">
                <app-blogrightsidebar></app-blogrightsidebar>
            </aside>
            </div>
        </div>
    </section>

    <app-footer2></app-footer2>