import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../shared/settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private currentLang: BehaviorSubject<string> = new BehaviorSubject<string>(
    'en'
  );
  currentLang$: Observable<string> = this.currentLang.asObservable();
  updateLang(lang) {
    this.currentLang.next(lang);
  }
  getOptions() {
    return this.options;
  }
  getCurrencySymbol(): string {
    let lang = localStorage.getItem('current_lang') || 'en';
    return lang == 'en' ? localStorage.getItem('currencySymbolEn') : localStorage.getItem('currencySymbolAr');
  }
  getCurrencyPrecision(): string {
    return localStorage.getItem('currencyPrecision') || '3';
  }
  getAddress(): string {
    return localStorage.getItem('address') || '';
  }
  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
  }

  private options = defaults;

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
  }
  getData(storageKey): any[] {
    const data = localStorage.getItem(storageKey);
    return data ? JSON.parse(data) : [];
  }
  getDataByUniqueId(storageKey, uniqueId) {
    if (!uniqueId) return [];
    const data = localStorage.getItem(storageKey);
    return data ? JSON.parse(data).filter((o) => o.uniqueId == uniqueId) : [];
  }
  getDataByCompanyId(storageKey, companyId) {
    if (!companyId) return [];
    const data = localStorage.getItem(storageKey);
    return data ? JSON.parse(data).filter((o) => o.companyId == companyId) : [];
  }
  setData(data: any[], storageKey): void {
    localStorage.setItem(storageKey, JSON.stringify(data));
  }
  updateItemInLocalStorage(updatedItem: any, storageKey) {
    const storedData = JSON.parse(localStorage.getItem(storageKey) || '[]');

    const indexToUpdate = storedData.findIndex(
      (item: any) => item.uniqueId === updatedItem.uniqueId
    );

    if (indexToUpdate !== -1) {
      storedData[indexToUpdate] = updatedItem;
      localStorage.setItem(storageKey, JSON.stringify(storedData));
    }
  }
  removeItemInLocalStorage(storageKey, uniqueId) {
    const storedData = JSON.parse(localStorage.getItem(storageKey) || '[]');
    const itemIndex = storedData.findIndex(
      (item) => item.uniqueId === uniqueId
    );
    if (itemIndex !== -1) {
      // Removing the item from the array
      storedData.splice(itemIndex, 1);
      localStorage.setItem(storageKey, JSON.stringify(storedData));
    }
  }
  deleteOrdersWithCompanyId(storageKey, id): void {
    let storedData = JSON.parse(localStorage.getItem(storageKey) || '[]');
    storedData = storedData.filter((obj) => obj.companyId !== id);
    localStorage.setItem(storageKey, JSON.stringify(storedData));
  }
  addItemToLocalstorage(newItem: any, storageKey) {
    const storedData = JSON.parse(localStorage.getItem(storageKey) || '[]');

    storedData.push(newItem);

    localStorage.setItem(storageKey, JSON.stringify(storedData));
  }

  //   updateUserOrder(data) {
  //     this.orders.next(data);
  //   }
  //   addData(dataObj) {
  //     const currentValue = this.orders.value;
  //     const updatedValue = [...currentValue, dataObj];
  //     this.orders.next(updatedValue);
  // }
}
