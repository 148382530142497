import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/settings.service';
declare var $: any;

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css'],
})
export class BasketComponent implements OnInit {
  // isNote = false;
  id = 0;
  isNote: boolean[] = [];
  // orders: any[] = [];
  @Input() orders: any = [];
  @Input() minOrder: number = 0;
  @Input() deliveryCost: number = 0;
  constructor(
    public settingService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private router: Router,
    private translateService:TranslateService
  ) {}

  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    // console.log(this.extractExtraDetails(this.orders[0]))
    // this.orders = this.settingService.getData('my_orders');
  }
  @Output() sendIsSideBarValue: EventEmitter<boolean> = new EventEmitter();

  isSideBarVisible: boolean = false;

  toggleSideBar() {
    this.isSideBarVisible = !this.isSideBarVisible;
    this.sendIsSideBarValue.emit(this.isSideBarVisible)
  }

  current_lang = localStorage.getItem('current_lang') || 'en';
  extractExtraDetails(order: any): string[] {
    // Initialize an empty array to store the extra details
    let allExtraDetails: string[] = [];
  
    // Check if order has orderDtls and it's an array
    if (order.orderDtls && Array.isArray(order.orderDtls)) {
      // Iterate through each item in orderDtls
      order.orderDtls.forEach((orderDetail) => {
        // Check if the orderDetail has extraDetails and it's an array
        if (orderDetail.extraDetails && Array.isArray(orderDetail.extraDetails)) {
          // Concatenate the extraDetails array with the allExtraDetails array
          allExtraDetails = allExtraDetails.concat(orderDetail.extraDetails);
        }
      });
    }
  
    // Return the combined array of extra details
    return allExtraDetails;
  }
  getExtraDetailClass(index: number): string {
    const colors = ['success', 'danger'];
    return colors[index % colors.length];
  }
  subTotal() {
    let total = 0;
    for (let data of this.orders) {
      total += data.total;
    }
    return total;
  }
  makeOrderBtn() {
    if (this.minOrder > this.orders[0].deliverFees + this.subTotal()) {
      // this.toast.error(this.translateService.instant('Please do not play with the buttons because I ban you'));
      this.toast.error(this.translateService.instant('MinOrder') + this.minOrder);
      return;
    }
    // if (!localStorage.getItem('address')) {
    //   this.toast.warning('Please select address for complete checkout');
    //   localStorage.setItem('comId', this.id.toString());
    //   this.router.navigate(['/']);
    // }
    this.router.navigate(['/checkout'], { queryParams: { id: this.id } });
    console.log(window.innerWidth);
    if (window.innerWidth <= 992) {
      let mo = document.querySelector('#makeOrder');
      document.querySelector('#content').classList.toggle('d-flex');
      document.querySelector('#content').classList.toggle('d-none');
      document.querySelector('#sideBar').classList.toggle('showSideBar');
    }
  }
  addNote(index) {
    // this.isNote = !this.isNote;
  }
  toggleNote(index: number) {
    console.log(this.isNote[index]);
    this.isNote[index] = !this.isNote[index];
  }
  plusMinusBtn(type, index, order) {
    let fieldName = $('.hollow-basket').attr('data-field');
    const input = document.querySelector<HTMLInputElement>(
      '.input-group-field-basket' + index
    );

    if (type == 1) {
      if (!isNaN(+input.value)) {
        // Decrease
        if (+input.value == 1) {
          //update order quantity and totals start
          this.settingService.removeItemInLocalStorage(
            'my_orders',
            order.uniqueId
          );
          this.orders = this.settingService.getData('my_orders');
          //update order quantity and totals end
          // $('input[name=' + fieldName + index + ']').val(1);
        } else {
          order.quantity = +input.value - 1;
          order.total = order.oldPrice * (+input.value - 1);
          this.settingService.updateItemInLocalStorage(order, 'my_orders');
          $('input[name=' + fieldName + index + ']').val(+input.value - 1);
        }
      } else {
        // Otherwise put a 1 there
        $('input[name=' + fieldName + index + ']').val(1);
      }
    } else {
      if (!isNaN(+input.value)) {
        //update order quantity and totals start
        order.quantity = +input.value + 1;
        order.total = order.oldPrice * (+input.value + 1);
        this.settingService.updateItemInLocalStorage(order, 'my_orders');
        //update order quantity and totals end
        // Increment
        $('input[name=' + fieldName + index + ']').val(+input.value + 1);
      } else {
        // Otherwise put a 0 there
        $('input[name=' + fieldName + index + ']').val(1);
      }
    }
  }
  keyupFun(event, index) {
    // console.log(this.orders[index].notes)
    // console.log(this.orders)
    this.settingService.setData(this.orders, 'my_orders');
    var characterCount = event.target.value.length;
    const current = document.querySelector<HTMLElement>('#current' + index);
    const maximum = document.querySelector<HTMLElement>('#maximum' + index);
    const theCount = document.querySelector<HTMLElement>('#the-count' + index);

    current.innerText = characterCount;
    if (characterCount < 70) {
      current.style.color = '#666';
    }
    if (characterCount > 70 && characterCount < 90) {
      current.style.color = '#6d5555';
    }
    if (characterCount > 90 && characterCount < 100) {
      current.style.color = '#793535';
    }
    if (characterCount > 100 && characterCount < 120) {
      current.style.color = '#841c1c';
    }
    if (characterCount > 120 && characterCount < 139) {
      current.style.color = '#8f0001';
    }

    if (characterCount >= 140) {
      maximum.style.color = '#8f0001';
      current.style.color = '#8f0001';
      theCount.style.fontWeight = 'bold';
    } else {
      maximum.style.color = '#666';
      theCount.style.fontWeight = 'normal';
    }
  }
}
