import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense, validateLicense } from '@syncfusion/ej2-base';

 registerLicense('ORg4AjUWIQA/Gnt2UVhiQlVPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSXxSfkVrW3tedHNSR2g=');
// registerLicense('ORg4AjUWIQA/Gnt2VlhiQlVPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSXxSdERgW39acXJdQ2g=');
// registerLicense('ORg4AjUWIQA/Gnt2VlhiQlZPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSX9RdUVmWH1bdHJUR2M=');
// registerLicense('@32332e302e30M7P/+wp0AF96LmqENwopoYyiBLObhVrxxipE8371IUc=');

// if (environment.production) {
//   enableProdMode();
// }
enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
