import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseResult } from 'src/app/models-classes/common.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private _http: HttpClient) {}
  api_url = environment.api.baseUrl;
  paymentChekout(obj): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + '/Payment/PaymentChekout',
      obj
    );
  }
  confirmPayment(obj): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + '/Payment/ConfirmPayment',
      obj
    );
  }
}
