import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private router: Router) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const loggedInUser = JSON.parse(localStorage.getItem('current_user'));
    // const user = this._authService.userValue;
    // const loggedIn = this._authService.checkIsLoggedIn;
    // const isLoggedIn = user && user.token;
    // const isLoggedIn = GetRequiredUserInfo.token;
    const isApiUrl = request.url.startsWith(environment.api.baseUrl);
    if (loggedInUser && loggedInUser.token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${loggedInUser.token}`,
          'langid': localStorage.getItem('current_lang') == 'en' ? '2' : '1',
          // 'Authorization': `Bearer ${GetRequiredUserInfo.token}`
        },
      });
      return next.handle(request).pipe(
        tap(
          (succ) => {},
          (err) => {
            if (err.status == 401) {
              localStorage.removeItem('current_user');
              this.router.navigate(['/login']);
            } else if (err.status == 403) {
              localStorage.removeItem('current_user');
              this.router.navigate(['/access-denied']);
            }
          }
        )
      );
    }
    return next.handle(request);
  }
}
